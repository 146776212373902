import { ApiClient, apiClient } from "core/apiClient";
import { ReactNode, createContext, useContext, useMemo } from "react";

const ApiContext = createContext<any>(null);

type ApiProviderProps = {
  children: ReactNode;
};

export function ApiProvider({ children }: ApiProviderProps) {
  const client: any = useMemo(() => {
    return apiClient;
  }, []);

  return <ApiContext.Provider value={client}>{children}</ApiContext.Provider>;
}

/**
 * @returns { ApiClient }
 */
export function useApi() {
  return useContext(ApiContext) as ApiClient;
}
