import { ApiResource } from "./apiResource";
import { ApiDataResponse } from "../apiClient";

export class SmsApi extends ApiResource {
  async getSmsNotifications() {
    return this.client.get<ApiDataResponse<any>>("/sms_rules", {
      params: {
        limit: 1000,
        situations: "reserved",
        visible: true,
      },
    });
  }
}
