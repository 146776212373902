import { ReactNode } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const buttonStyle = {
  minWidth: "86px",
  height: "36px",
  fontSize: "16px",
  width: "100%",
};

interface AlertModalProps {
  title?: string;
  body: ReactNode;
  confirmText?: string;
  onClose: () => void;
  open?: boolean;
}

export function AlertModal({
  title = "",
  body,
  confirmText,
  onClose,
  open = true,
}: AlertModalProps) {
  return (
    <Dialog open={open} PaperProps={{ sx: { minWidth: "380px" } }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ fontSize: "14px" }}>{body}</DialogContent>
      <DialogActions sx={{ padding: "20px" }}>
        <Button
          variant="contained"
          onClick={onClose}
          autoFocus
          sx={buttonStyle}
        >
          {confirmText ?? "확인"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
