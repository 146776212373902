import { SnackbarOrigin } from "@mui/material/Snackbar";
import Icon from "~/components/Icon";
import { IconButton } from "~/components/IconButton/IconButton";
import { ReactNode } from "react";
import {
  RightSection,
  SnackbarComponent,
  LeftSection,
  Mark,
  Message,
} from "./Snackbar.styled";

type SnackbarColorType = "unoblue" | "alert";

interface SnackBarProps {
  show?: boolean;
  color?: SnackbarColorType;
  message: ReactNode;
  onClosed: () => void;
  actionItems?: ReactNode;
  leadingItems?: ReactNode;
  autoHideDuration?: number;
  anchorOrigin?: SnackbarOrigin;
}

export const SnackBar = ({
  show = false,
  color = "unoblue",
  message,
  onClosed,
  actionItems,
  leadingItems,
  autoHideDuration = 5000,
  anchorOrigin = { vertical: "top", horizontal: "center" },
}: SnackBarProps) => {
  const action = (
    <RightSection>
      {actionItems}
      <IconButton variant="transparent" onClick={onClosed}>
        <Icon variant="close" />
      </IconButton>
    </RightSection>
  );

  return show ? (
    <SnackbarComponent
      color={color}
      open={show}
      autoHideDuration={autoHideDuration}
      onClose={onClosed}
      message={
        <LeftSection>
          {leadingItems ?? (
            <>
              {color === "unoblue" && <Icon variant="checkMark" />}
              {color === "alert" && <Mark color="alert">!</Mark>}
            </>
          )}
          <Message>{message}</Message>
        </LeftSection>
      }
      action={action}
      anchorOrigin={anchorOrigin}
    />
  ) : null;
};
