import { useState, useCallback } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import FilterTab from "./FilterTab";
import { InfiniteScroll } from "components/InfiniteScroll";
import { AppointmentInfoModal } from "modals/AppointmentInfoModal";
import { CustomerInfoModal } from "modals/CustomerInfoModal";
import Icon from "components/Icon";
import { SortColumnWrapper, EmptyView } from "./List.styled";
import { useUser } from "providers/UserProvider";
import AppointmentRow from "./AppointmentRow";
import {
  useAppointmentContext,
  AppointmentContextType,
} from "pages/AppointmentPage/AppointmentPage";

export function List() {
  const { hasPermission } = useUser();
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [openAppointmentModal, setOpenAppointmentModal] = useState(false);
  const [appointment, setAppointment] = useState(null);
  const [customer, setCustomer] = useState(null);
  const {
    appointments,
    date,
    updateOrder,
    updatePage,
    departmentId,
    reload,
    onChangeStatus,
  } = useAppointmentContext() as AppointmentContextType;

  const openCustomerInfoModal = (customer: any) => {
    setOpenCustomerModal(true);
    setCustomer(customer);
  };

  const openAppointmentInfoModal = (data: any) => {
    setOpenAppointmentModal(true);
    setCustomer(data.customer);
    setAppointment(data);
  };

  const closeCustomerInfoModal = () => {
    setOpenCustomerModal(false);
    setCustomer(null);
  };

  const closeAppointmentInfoModal = () => {
    setOpenAppointmentModal(false);
    setCustomer(null);
    setAppointment(null);
    reload();
  };

  const onCustomerDelete = () => {
    setOpenCustomerModal(false);
    setCustomer(null);
    reload();
  };

  const onClickRow = useCallback(
    (appointment: any) => {
      openAppointmentInfoModal(appointment);
    },
    [appointment]
  );

  const onOpenCustomerModal = useCallback(
    (customer: any) => {
      openCustomerInfoModal(customer);
    },
    [customer]
  );

  return (
    <>
      <FilterTab />
      <TableContainer>
        <InfiniteScroll action={updatePage} key={`${date}-${departmentId}`}>
          <Table
            stickyHeader
            sx={{
              maxHeight: 220,
              background: "#fff",
            }}
          >
            {!appointments?.length && (
              <caption>
                <EmptyView text="금일 예약내역이 없습니다." />
              </caption>
            )}
            <TableHead>
              <TableRow
                sx={{
                  "& th": {
                    padding: "13px 0",
                    color: "#282828",
                    fontWeight: 700,
                    borderBottom: "1px solid #F9FBFF",
                  },
                }}
              >
                <TableCell align="center" width="15%">
                  상태
                </TableCell>
                <TableCell align="center" width="15%">
                  고객정보
                </TableCell>
                <TableCell align="center" width="15%">
                  <SortColumnWrapper onClick={() => updateOrder("startHour")}>
                    예약시간
                    <Icon variant="sort" />
                  </SortColumnWrapper>
                </TableCell>
                <TableCell align="center" width="10%">
                  부서
                </TableCell>
                <TableCell align="center" width="35%">
                  메모
                </TableCell>
                {hasPermission("PENCHART_CUSTOMER") && (
                  <TableCell align="center" width="10%">
                    펜차트
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            {Boolean(appointments?.length) && (
              <TableBody>
                {appointments.map((row: any) => (
                  <AppointmentRow
                    key={row.id}
                    appointment={row}
                    onClickRow={onClickRow}
                    onOpenCustomerModal={onOpenCustomerModal}
                    onChangeStatus={onChangeStatus}
                  />
                ))}
              </TableBody>
            )}
          </Table>
        </InfiniteScroll>
      </TableContainer>
      {openAppointmentModal && (
        <AppointmentInfoModal
          open={true}
          customerData={customer}
          appointmentData={appointment}
          onClose={closeAppointmentInfoModal}
          reload={reload}
        />
      )}
      {openCustomerModal && (
        <CustomerInfoModal
          open={true}
          customerData={customer}
          onClose={closeCustomerInfoModal}
          onCustomerDelete={onCustomerDelete}
          reload={reload}
        />
      )}
    </>
  );
}
