import { ApiResource } from "./apiResource";
import { ApiClient, ApiDataResponse } from "../apiClient";

interface ConfigData {
  key: string;
  value: string;
}

export class UserApi extends ApiResource {
  async signin(email: string, password: string) {
    return this.client.post(`/users/sign_in`, { email, password });
  }

  async confirmCertification(email: string, confirmNumber: string) {
    return this.client.post(`/users/otp/confirm`, {
      email,
      otpCode: confirmNumber,
    });
  }

  async sendCertificationEmail(email: string) {
    return this.client.post(`/admin/users/otp`, { email });
  }

  async changePassword(password: string) {
    return this.client.put(`/users/change_password`, { password });
  }

  async me() {
    return this.client.get(`/users/me`);
  }

  async getDuty(type: string) {
    return this.client.get(`/users/duty`, {
      params: {
        limit: 300,
        userStatus: "active",
        duty: type,
      },
    });
  }

  async getUserDuty(params: any) {
    return this.client.get(`/users/duty`, {
      params,
    });
  }

  async refreshAccessToken() {
    const response = await this.client.post(`/users/refresh`, undefined, {
      headers: this.buildHeaders({ refresh: true }),
    });

    const { accessToken, refreshToken } = response.data;

    this.dispatchApiEvent(ApiClient.Event.REFRESH_TOKEN, {
      accessToken,
      refreshToken,
    });

    return response.data;
  }

  async getConfig(key: string) {
    return this.client.get<ApiDataResponse<ConfigData>>("/users/config", {
      params: { key },
    });
  }

  async updateConfig(key: string, value: string) {
    return this.client.put<ApiDataResponse<ConfigData>>("/users/config", {
      key,
      value: String(value),
    });
  }
}
