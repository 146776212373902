import { useState } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { FileData } from "core/resources/filesApi";
import Icon from "components/Icon";
import { Box } from "./ThumbnailItem.styled";

interface ThumbnailItemProps {
  item: FileData;
  onClickItem: (item: FileData) => void;
}

interface ThumbnailProps {
  thumbnailUrl?: string;
  broken: boolean;
  creating: boolean;
}

export const Thumbnail = ({
  thumbnailUrl,
  broken,
  creating,
}: ThumbnailProps) => {
  const [isImageError, setIsImageError] = useState(false);

  const handleErrorImage = () => {
    setIsImageError(true);
  };

  if (broken || isImageError)
    return (
      <Box className="thumbnail-icon-wrapper">
        <Icon variant="errorFile" />
      </Box>
    );
  if (creating)
    return (
      <Box className="thumbnail-icon-wrapper">
        <CircularProgress />
      </Box>
    );

  return <img src={thumbnailUrl} onError={handleErrorImage} />;
};

const ThumbnailItem = ({ item, onClickItem }: ThumbnailItemProps) => {
  const { id } = useParams();
  const image = item.image;
  const broken = image.thumbnailStatus === "FAILED";
  const creating = image.thumbnailStatus === "CREATING" || !image.thumbnailUrl;

  return (
    <Box
      className="thumbnail-item"
      key={item.id}
      onClick={() => onClickItem(item)}
    >
      <Box className="thumbnail">
        <Thumbnail
          thumbnailUrl={image.thumbnailUrl}
          broken={broken}
          creating={creating}
        />
      </Box>
      <Box
        className={`title ${Number(id) === item.id ? "selected" : ""}`}
        title={item.name}
      >
        {item.name}
      </Box>
    </Box>
  );
};
export default ThumbnailItem;
