import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DriveView, DriveViewProvider } from "components/DriveView/DriveView";
import { ApiException } from "core/apiClient";
import { keyed } from "hoc/keyed";
import { useQuery } from "hooks/useQuery";
import { useApi } from "providers/ApiProvider";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { InnerPage } from "./InnerPage";
import { CustomerHeaderLabel } from "components/CustomerHeaderLabel";

export const DirectoryPage = keyed(() => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const penchartDrive = searchParams.get("penchartDrive") === "true";
  const { id } = useParams();
  const query = useQuery();
  const pageName = useRef(query.get("name"));
  const [directory, setDirectory] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [customer, setCustomer] = useState<any>();
  const [profileImage, setProfileImage] = useState<any>();
  const nav = useNavigate();
  const { filesApi, customersApi } = useApi();

  const loadProfileImage = useCallback(async (profileImageId: number) => {
    if (!profileImageId) return;

    try {
      const res = await filesApi.getImage(profileImageId);
      const payload = await res.data;

      setProfileImage(payload.data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const loadCustomer = useCallback(async () => {
    const params = new URLSearchParams(location.search);
    const id = params.get("customerId");
    if (!id) return;

    try {
      const res = await customersApi.getCustomer(id);
      const payload = await res.data;

      setCustomer(payload.data);
      if (payload.data.profileImageId) {
        loadProfileImage(payload.data.profileImageId);
      }
    } catch (e) {
      console.log(e);
    }
  }, [customersApi]);

  useEffect(() => {
    loadCustomer();
  }, [loadCustomer]);

  const loadDirectory = useCallback(async () => {
    try {
      const res = await filesApi.getDirectory(id!);
      const payload = await res.data;

      setDirectory(payload.data);
    } catch (e: any) {
      if (e instanceof ApiException && e.code === 404) {
        setError("존재하지 않거나 삭제된 디렉토리입니다.");
      } else {
        setError("디렉토리를 불러오는데 실패했습니다.");
        throw e;
      }
    }
  }, [filesApi, id]);

  useEffect(() => {
    loadDirectory();
  }, [loadDirectory]);

  const onEnterDirectory = (directoryId: number) => {
    nav({
      pathname: `/directories/${directoryId}`,
      search: `penchartDrive=${penchartDrive}${
        customer ? `&customerId=${customer.id}` : ""
      }`,
    });
  };

  const onEnterFile = ({
    fileId,
    orderBy,
    searchKeyword,
  }: {
    fileId: number;
    orderBy: string;
    searchKeyword: string;
  }) => {
    nav(
      `/files/${fileId}/edit?${
        customer ? `&customerId=${customer.id}&` : ""
      }orderBy=${orderBy}&searchKeyword=${searchKeyword}`
    );
  };

  return (
    <InnerPage
      title={
        pageName.current ? (
          customer?.id && (
            <CustomerHeaderLabel
              label={" 님의 펜차트"}
              profileImage={profileImage}
              customer={customer}
            />
          )
        ) : (
          <Typography
            component="div"
            sx={{
              flexGrow: 1,
              maxWidth: "510px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "center",
              whiteSpace: "nowrap",
              fontWeight: "bold",
            }}
          >
            {directory?.name ?? ""}
          </Typography>
        )
      }
    >
      {directory && (
        <DriveViewProvider directoryId={id} penchartDrive={penchartDrive}>
          <DriveView
            onEnterDirectory={onEnterDirectory}
            onEnterFile={onEnterFile}
          />
        </DriveViewProvider>
      )}
      {error && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Typography>{error}</Typography>
        </Box>
      )}
    </InnerPage>
  );
}, ["id"]);
