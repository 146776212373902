import { useState } from "react";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import { AutoComplete } from "~/components/AutoComplete";
import {
  FindCustomerAutoCompleteRow,
  returnSex,
} from "./FindCustomerAutoCompleteRow";
import { phoneNumberFormatHyphen } from "~/utils/filters";
import { ClearButton } from "~/components/ClearButton";
import { Fade, InputAdornment, Popper } from "@mui/material";
import { useApi } from "providers/ApiProvider";
import Icon from "components/Icon";

const IconWrapper = styled.div`
  display: flex;
`;

const returnText = (customer: any) => {
  let { birthday, phoneNumber } = customer;
  const { name } = customer;
  if (birthday) birthday = birthday.replace(/-/g, ".");
  if (phoneNumber) phoneNumber = phoneNumberFormatHyphen(phoneNumber);

  return `${name ?? "-"}/${birthday ?? "-"}/${returnSex(customer.sex) ?? "-"}/${
    phoneNumber ?? "-"
  }`;
};

type FindFamilyInputProps = {
  index: number;
  obj: any;
  setObj: (value: any) => void;
  items: any[];
  setItems: (value: any) => void;
  disabled?: boolean;
};

export const FindFamilyInput = observer(
  ({
    index,
    obj,
    setObj,
    items,
    setItems,
    disabled,
    ...props
  }: FindFamilyInputProps) => {
    const [focused, setFocused] = useState(false);
    const [timer, setTimer]: any = useState(0); // 디바운싱 타이머
    const [customers, setCustomers] = useState([]);
    const { customersApi } = useApi();

    const findCustomer = async (param: any) => {
      const res = await customersApi.searchCustomer(param);
      const payload = await res.data;
      const { data } = payload;
      setCustomers(
        data.map((v: any) => {
          return {
            ...v,
            searchValue: `${v.name ?? "-"}/${v.birthday ?? "-"}/${
              returnSex(v.sex) ?? "-"
            }/${v.phoneNumber ?? "-"}`,
            searchOption: `${v.name}/${v.phoneNumberLast}`,
          };
        })
      );
    };

    const onChangeItem = (e: any, option: any) => {
      if (e.key === "Enter" && typeof option === "string") {
        return;
      }
      if (option === null) {
        const update = [...items];
        update[index] = { id: "", name: "" };
        setItems(update);
        setObj(update);
      } else {
        const update = [...items];
        update[index] = {
          id: option.id,
          name: returnText(option),
        };
        setItems(update);
        setObj(update);
      }
    };

    const onChangeInput = (e: any) => {
      const { target } = e;
      const { value } = target;
      const update = [...items];
      update[index] = { id: null, name: value };
      setItems(update);

      if (value.length >= 2) {
        if (timer) {
          clearTimeout(timer);
        }
        const newTimer = setTimeout(() => {
          const param: any = {};
          if (!isNaN(value) && value.length === 4) {
            param.phoneNumberLast = value;
          } else {
            param.name = value;
          }
          findCustomer(param);
        }, 500);
        setTimer(newTimer);
      }
    };

    const PopperComponent = function (props: any) {
      return (
        <Popper
          {...props}
          placement="bottom-end"
          style={{ width: "360px", zIndex: 1300 }}
        />
      );
    };

    return (
      <AutoComplete
        {...props}
        options={customers}
        onChange={(e: any, option: any) => onChangeItem(e, option)}
        inputValue={items[index].name}
        ListboxProps={{ style: { maxHeight: "334px", padding: 0 } }}
        PopperComponent={PopperComponent}
        renderOption={(props: any, option: any) => {
          return (
            <FindCustomerAutoCompleteRow
              key={props.key}
              props={props}
              option={option}
            />
          );
        }}
        onChangeInput={onChangeInput}
        placeholder="가족관계를 등록하세요"
        inputProps={{
          endAdornment: (
            <>
              <Fade in={focused && items[index].name ? true : false}>
                <InputAdornment position="end">
                  <ClearButton
                    onClick={() => {
                      const update = [...items];
                      update[index] = { id: null, name: "" };
                      setItems(update);
                      setObj({ ...obj, familyRelationships: update });
                      setCustomers([]);
                    }}
                  />
                </InputAdornment>
              </Fade>
              <IconWrapper>
                <Icon variant="search" />
              </IconWrapper>
            </>
          ),
        }}
        disabled={disabled}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
    );
  }
);
