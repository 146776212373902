import { ApiResource } from "./apiResource";
import { ApiEmptyResponse, ApiDataResponse } from "../apiClient";

export class RegistrationApi extends ApiResource {
  async getRegistration(registrationId: string | number) {
    return this.client.get<ApiDataResponse<any>>(
      `/registrations/${registrationId}`
    );
  }

  async createRegistration(data: any) {
    return this.client.post<ApiDataResponse<any>>(`/registrations`, data);
  }

  async createRegistrationSimple(data: {
    appointmentId: number;
    sessionStatusConfigId: string;
    status: string;
  }) {
    return this.client.post<ApiDataResponse<any>>(
      `/registrations/simple`,
      data
    );
  }

  async editRegistration(registrationId: string, data: any) {
    return this.client.put<ApiDataResponse<any>>(
      `/registrations/${registrationId}`,
      data
    );
  }

  async deleteRegistration(registrationId: string) {
    return this.client.delete<ApiEmptyResponse>(
      `/registrations/${registrationId}`
    );
  }

  async revertRegistration(registrationId: string, params?: any) {
    return this.client.post<ApiDataResponse<any>>(
      `/registrations/${registrationId}/revert`,
      params
    );
  }

  async createRegistrationRequests(data: any) {
    return this.client.post<ApiDataResponse<any>>(
      `/registrations/requests`,
      data
    );
  }
}
