import { styled as muiStyled, css, Box as MuiBox } from "@mui/material";
import styled from "@emotion/styled";
import { InnerBoxStyle } from "./Label.styled";
import "assets/styles/datepicker.css";

export const Box = muiStyled(MuiBox)(
  () => `
  &.date-picker-wrapper {
   width: 310px;
   height: 349px;
    line-height: 0;
    .react-datepicker__tab-loop {
      position: relative !important;
    }
    .react-datepicker-popper {
      position: unset !important;
      transform: unset !important;
    }
  }
`
);

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  justify-content: center;
  height: 29px;
  margin-bottom: 10px;
`;

export const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
  }

  input {
    ${InnerBoxStyle}
    height: 29px;
    &::placeholder {
      color: #a1b1ca !important;
    }
    &:disabled {
      background-color: #f3f8ff;
      color: #4a4e70;
    }

    ${({ error }: any) =>
      error &&
      css`
        border-color: #f7685b !important;
      `}
  }
`;

export const DateText = styled.div`
  font-size: 15px;
  font-weight: 700;
  line-hieght: 22px;
`;
