import { createContext, useContext, useMemo, ReactNode } from "react";
import { makeAutoObservable, runInAction } from "mobx";
import {
  format,
  startOfMonth,
  startOfWeek,
  endOfMonth,
  endOfWeek,
} from "date-fns";
import { useApi } from "providers/ApiProvider";
import { ApiClient } from "~/core/apiClient";

export const DayType = {
  holiday: "holiday",
  saturday: "saturday",
  none: "none",
};

class Holiday {
  loadedMonths: string[] = [];
  holidays = [];
  api;

  constructor(api: ApiClient) {
    this.api = api;
    makeAutoObservable(this);
  }

  loadBetween = async (startDay: string | Date, endDay: string | Date) => {
    const calendarStartDay = format(
      new Date(
        startOfWeek(startOfMonth(new Date(startDay)), {
          weekStartsOn: 1,
        })
      ),
      "yyyy-MM-dd"
    );
    const calendarEndDay = format(
      new Date(
        endOfWeek(endOfMonth(new Date(endDay)), {
          weekStartsOn: 1,
        })
      ),
      "yyyy-MM-dd"
    );

    const resp = await this.api.clinicsApi.getHolidays({
      locdateStartAt: calendarStartDay,
      locdateEndAt: calendarEndDay,
      orderBy: "locdate asc",
    });
    const res = await resp.data;
    return res.data;
  };

  load = async (startDate: string | Date) => {
    const startMonth = format(new Date(startDate), "yyyy-MM");

    if (this.loadedMonths.indexOf(startMonth) === -1) {
      this.loadedMonths = this.loadedMonths.concat(startMonth);
      try {
        const list = await this.loadBetween(startDate, startDate);
        runInAction(() => {
          this.holidays = this.holidays.concat(
            list.filter(
              (v: any) =>
                !this.holidays.find((f: any) => f.locdate === v.locdate)
            )
          );
        });
      } catch (e) {
        runInAction(() => {
          this.loadedMonths = this.loadedMonths.filter((f) => f !== startMonth);
        });
      }
    }
  };

  get holidayValues() {
    return this.holidays;
  }

  isHoliday(isoDate: Date | string) {
    if (
      this.loadedMonths.indexOf(format(new Date(isoDate), "yyyy-MM")) === -1
    ) {
      this.load(isoDate);
    }
    return this.holidays.find((d: any) => d.locdate === isoDate);
  }

  getDayType(date: Date) {
    if (
      this.isHoliday(format(new Date(date), "yyyy-MM-dd")) ||
      date.getDay() === 0
    )
      return DayType.holiday;

    if (date.getDay() === 6) return DayType.saturday;

    return DayType.none;
  }
}

const HolidayContext = createContext<Holiday | null>(null);

export const useHoliday = () => {
  return useContext(HolidayContext);
};

export const HolidayProvider = ({ children }: { children: ReactNode }) => {
  const api = useApi();
  const value = useMemo(() => new Holiday(api), [api]);

  return (
    <HolidayContext.Provider value={value}>{children}</HolidayContext.Provider>
  );
};
