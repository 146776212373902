import { ApiResource } from "./apiResource";
import { ApiDataResponse, PaginationResponse } from "../apiClient";

export class CustomersApi extends ApiResource {
  async getAcquisitionChannels(params?: any) {
    return this.client.get<ApiDataResponse<any>>(
      "/customers/acquisition_channels",
      {
        params: {
          limit: 300,
          visible: true,
          ...params,
        },
      }
    );
  }

  async getComplaints() {
    return this.client.get<ApiDataResponse<any>>("/customers/complaints", {
      params: {
        limit: 300,
        visible: true,
      },
    });
  }

  async getRegions() {
    return this.client.get<ApiDataResponse<any>>("/customers/regions", {
      params: {
        limit: 300,
        visible: true,
      },
    });
  }

  async getLevels() {
    return this.client.get<ApiDataResponse<any>>("/customers/levels", {
      params: {
        limit: 300,
        visible: true,
      },
    });
  }

  async getJobs() {
    return this.client.get<ApiDataResponse<any>>("/customers/jobs", {
      params: {
        limit: 300,
        visible: true,
      },
    });
  }

  async getRegistrationCheck(phoneNumber: string) {
    return this.client.get<ApiDataResponse<any>>(
      "/customers/registration/check",
      {
        params: {
          phoneNumber,
        },
      }
    );
  }

  async searchCustomer(params: any, limit = 40) {
    return this.client.get<PaginationResponse<any>>("/customers", {
      params: {
        orderBy: "lastVisitAt desc, id desc",
        limit,
        ...params,
      },
    });
  }

  async createCustomer(data: any) {
    return this.client.post<ApiDataResponse<any>>("/customers", data);
  }

  async editCustomer(customerId: string | number, data: any) {
    return this.client.put<ApiDataResponse<any>>(
      `/customers/${customerId}`,
      data
    );
  }

  async getCustomer(customerId: string | number) {
    return this.client.get<ApiDataResponse<any>>(`/customers/${customerId}`);
  }

  async deleteCustomer(customerId: string | number) {
    return this.client.delete<ApiDataResponse<any>>(`/customers/${customerId}`);
  }

  async getCustomerDrive(customerId: string | number) {
    return this.client.get<ApiDataResponse<any>>(
      `/drives/customers/${customerId}`
    );
  }
}
