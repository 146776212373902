import { Select, FormControl, styled as muiStyled } from "@mui/material";
import { InnerBoxStyle } from "./Label.styled";
import styled from "@emotion/styled";

interface SelectBoxProps {
  shouldInvalidate?: boolean;
  optioncolor?: string;
}

export const Wrapper = styled(FormControl)`
  width: 100%;
  background: #ffffff;
  & select {
    padding: 0 8px;
  }
  .MuiNativeSelect-select {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const SelectBox = muiStyled(Select, {
  shouldForwardProp: (prop) =>
    !["shouldInvalidate", "optioncolor"].includes(prop as string),
})<SelectBoxProps>`
  ${InnerBoxStyle}
  width: 100%;
  height: 29px;
  border: 1px solid #dee2ec;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 6px 8px;
  font-size: 12px;
  transition: border-color 0.1s;
  &:focus {
    border-color: #2c62f6;
  }  

  border: 0;
  color: ${({ shouldInvalidate }) =>
    shouldInvalidate ? "#a1b1ca" : "inherit"};
  & option {
    color: ${({ optioncolor }) => (!optioncolor ? "#2D2D2D !important" : "")};
  }

  &.MuiInputBase-formControl.MuiInputBase-root {
    .MuiNativeSelect-select.MuiNativeSelect-outlined {
      padding-right: 20px;
    }
  }

  & select {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg, & path {
    fill: ${({ theme }) => theme.palette.grey[700]};
  }
`;
