import { useState, useCallback, useEffect, useContext } from "react";
import { SimpleRegistrationsContext } from "pages/SimpleRegistrationsPage";
import { useApi } from "providers/ApiProvider";
import { Box, Button, styled as muiStyled, css } from "@mui/material";
import styled from "@emotion/styled";

const Span = styled.span`
  font-weight: 800;
`;

const CustomButton = muiStyled(Button)<{ selected?: boolean }>`
  &.Mui-disabled {
    color: #bbbbbb;
    background: #f1f1f1;
    border: solid 1px #dee2ec;
  }

  ${({ selected }) =>
    selected &&
    css`
      background: #f3f8ff !important;
      color: #2c62f6;
      border: solid 1px #2c62f6 !important;
    `}
`;

interface DialButtonProps {
  text: string;
  onClick: () => void;
  selected?: boolean;
  disabled?: boolean;
  sx?: Record<string, any>;
}

const DialButton = ({
  text,
  onClick,
  selected,
  disabled,
  sx,
}: DialButtonProps) => {
  return (
    <CustomButton
      onClick={onClick}
      variant="outlined"
      disabled={disabled}
      selected={selected}
      sx={{
        borderColor: "#F1F1F1 !important",
        color: "black",
        fontSize: "3vw",
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1) !important",
        padding: "2%",
        width: "46%",
        ...sx,
      }}
    >
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontSize: text.length > 6 ? "30px" : "40px",
        }}
      >
        {text}
      </Box>
    </CustomButton>
  );
};

export const PortraitAcquisitionChannelsSelect = () => {
  const { acquisitionChannelsIds, setAcquisitionChannelsIds } = useContext(
    SimpleRegistrationsContext
  );

  const { customersApi } = useApi();
  const [acquisitionChannels, setAcquisitionChannels] = useState([]);

  const loadAcquisitionChannels = useCallback(async () => {
    const res = await customersApi.getAcquisitionChannels({
      limit: 12,
      orderBy: "order desc",
      visible: true,
      registrationView: true,
    });
    const payload = await res.data;
    payload.data && setAcquisitionChannels(payload.data.slice(0, 12));
  }, [customersApi]);

  useEffect(() => {
    loadAcquisitionChannels();
  }, []);

  const onClickSelect = (id: number) => {
    if (acquisitionChannelsIds.indexOf(id) > -1) {
      const update = [...acquisitionChannelsIds];
      update.splice(acquisitionChannelsIds.indexOf(id), 1);
      setAcquisitionChannelsIds(update);
    } else {
      const update = [...acquisitionChannelsIds];
      update.push(id);
      setAcquisitionChannelsIds(update);
    }
  };

  const AcquisitionChannelsButtons = () => {
    const buttons = acquisitionChannels.map(
      (v: { id: number; name: string }) => (
        <DialButton
          key={v.id}
          text={v.name}
          onClick={() => onClickSelect(v.id)}
          selected={acquisitionChannelsIds.indexOf(v.id) > -1}
          disabled={
            acquisitionChannelsIds.indexOf(v.id) === -1 &&
            acquisitionChannelsIds.length === 3
          }
        />
      )
    );
    const divList = [];
    for (let i = 0; i < buttons.length; i += 2) {
      divList.push(
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
          key={i}
        >
          {buttons.slice(i, i + 2)}
        </Box>
      );
    }
    return <>{divList}</>;
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "3%",
        }}
      >
        <Box
          sx={{
            fontSize: "4vw",
            fontWeight: "bold",
            marginBottom: "4%",
          }}
        >
          <Span>내원경로</Span>를 선택하세요 (최대 3개)
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5vw",
          }}
        >
          <AcquisitionChannelsButtons />
        </Box>
      </Box>
    </Box>
  );
};
