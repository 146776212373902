import { ApiResource } from "./apiResource";
import { format } from "date-fns";
import {
  ApiEmptyResponse,
  ApiDataResponse,
  PaginationResponse,
} from "../apiClient";

export class SessionApi extends ApiResource {
  async getStatusSettings() {
    return this.client.get<ApiDataResponse<any>>(
      `/sessions/session_status_config`
    );
  }

  async getSessionsSimple(params: any) {
    return this.client.get<PaginationResponse<any>>(`/sessions/simple`, {
      params,
    });
  }

  async getSessions(params: any) {
    return this.client.get<ApiDataResponse<any>>(`/sessions`, { params });
  }

  async getSessionsHistories(params: any) {
    return this.client.get<PaginationResponse<any>>(`/sessions/histories`, {
      params,
    });
  }

  async cancelSessions(id: string | number) {
    return this.client.post<ApiEmptyResponse>(`/sessions/${id}/cancel`);
  }

  async getAppointmentsCount(params: {
    startAt: string;
    endAt: string;
    departmentIds?: string;
    departmentCategoryId?: string;
  }) {
    return this.client.get<ApiDataResponse<any>>(`/sessions/stats`, { params });
  }

  async getAppointmentsTime(date = new Date(), departmentId: string) {
    return this.client.get<ApiDataResponse<any>>(`/sessions/occupations`, {
      params: {
        date: format(new Date(date), "yyyy-MM-dd"),
        departmentId,
      },
    });
  }
}
