import { styled, Box as MuiBox } from "@mui/material";

export const Box = styled(MuiBox)(
  ({ theme }) => `
  &.thumbnail-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 195px;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 30px;
    }

    .thumbnail {
      width: 120px;
      height: 171px;
      border: solid 1px ${theme.palette.layout.line};
      border-radius: 4px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: contain;
      }
      .thumbnail-icon-wrapper {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
    .title {
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${theme.palette.grey["700"]};
      font-size: 12px;
      white-space: nowrap;
      &.selected {
        background: ${theme.palette.grey["700"]};
        color: white;
      }
    }
  }
`
);
