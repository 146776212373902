import styled from "@emotion/styled";
import { IconButton } from "~/components/IconButton/IconButton";
import { styled as muiStyled, css } from "@mui/material";
import Icon, { IconProps } from "./Icon";

interface EditIconProps extends Omit<IconProps, "variant"> {
  disabled?: boolean;
}

export const Button = muiStyled(IconButton)(
  ({ theme }) => `
  width: 16px;
  height: 16px;
  padding: 2px;
  margin: 0 auto;
  color: #a1b1ca;
  svg {
    width: 100%;
    height: 100%;
  }

  &.selected {
    svg,path {
      fill: ${theme.palette.grey["700"]};
    }
  }
`
);

export const EditIcon = styled((props: EditIconProps) => (
  <Icon variant="edit" {...props} />
))<EditIconProps>`
  ${({ disabled }) =>
    disabled &&
    css`
      path {
        fill: currentColor;
        color: #d7e3f1;
      }
    `}
`;
