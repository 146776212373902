import { useEffect } from "react";
import {
  Route,
  Navigate,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import TagManager from "react-gtm-module";
// eslint-disable-next-line import/no-duplicates
import { setDefaultOptions } from "date-fns";
// eslint-disable-next-line import/no-duplicates
import ko from "date-fns/locale/ko";
import { AppointmentPage } from "pages/AppointmentPage/AppointmentPage";
import { DirectoryPage } from "pages/DirectoryPage";
import { FileEditPage } from "pages/FileEditPage";
import { Layout } from "pages/Layout/Layout";
import { LoginPage } from "pages/LoginPage/LoginPage";
import { PenchartPage } from "pages/PenchartPage";
import { SimpleRegistrationsPage } from "pages/SimpleRegistrationsPage";
import { ApiProvider } from "providers/ApiProvider";
import { UserProvider } from "providers/UserProvider";
import { ClinicProvider } from "providers/ClinicProvider";
import { StatusSettingsProvider } from "providers/StatusSettingsProvider";
import { SnackbarProvider } from "./SnackbarProvider_v2";
import { HolidayProvider } from "providers/HolidayProvider";
import { AuthProvider } from "providers/AuthProvider";
import { HomePage } from "pages/HomePage";
import { Privacy } from "pages/Privacy";
import { AuthedLayout } from "pages/Layout/AuthedLayout";
import { ImperativeModalProvider } from "ImperativeModalProvider";
import { ThemeProvider } from "styles/theme";

setDefaultOptions({
  locale: ko,
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <ImperativeModalProvider>
          <Layout />
        </ImperativeModalProvider>
      }
    >
      <Route path="/login" element={<LoginPage />} />
      <Route element={<AuthedLayout />}>
        <Route path="/appointments" element={<AppointmentPage />} />
        <Route path="/pencharts" element={<PenchartPage />} />
        <Route
          path="/simple-registrations"
          element={<SimpleRegistrationsPage />}
        />
        <Route path="/directories/:id" element={<DirectoryPage />} />
        <Route path="/files/:id/edit" element={<FileEditPage />} />
        <Route path="/*" element={<Navigate to="/appointments" />} />
      </Route>
      <Route index element={<HomePage />} />
      <Route path="/privacy" element={<Privacy />} />
    </Route>
  )
);

export default function App() {
  useEffect(() => {
    initializeGTM();
  }, []);

  const initializeGTM = () => {
    if (process.env.REACT_APP_GTA_TRACKING_ID != undefined) {
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTA_TRACKING_ID,
      };
      TagManager.initialize(tagManagerArgs);
    }
  };

  return (
    <ThemeProvider>
      <SnackbarProvider>
        <ApiProvider>
          <AuthProvider>
            <UserProvider>
              <ClinicProvider>
                <StatusSettingsProvider>
                  <HolidayProvider>
                    <RouterProvider router={router} />
                  </HolidayProvider>
                </StatusSettingsProvider>
              </ClinicProvider>
            </UserProvider>
          </AuthProvider>
        </ApiProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
