import { styled, Box as MuiBox } from "@mui/material";

export const Box = styled(MuiBox)(
  ({ theme }) => `
  &.thumbnail-container {
    position: fixed;
    z-index: 100;;
    height: calc(100% - 64px);
    &.top {
      height: calc(100% - 162px);
      top: 162px;
    }
    &.bottom {
      height: calc(100% - 162px);
      bottom: 98px;
    }

    .thumbnail-wrapper {
      height: 100%;

      .thumbnail-list-wrapper {
        position: relative;
        height: 100%;
        background: ${theme.palette.bluegrey["100"]};
        width: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;

        .thumbnail-items {
          height: 100%;
          overflow: auto;
          scrollbar-width: none;
          flex: 1;
        }
      }
    }
  }
`
);
