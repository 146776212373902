import { useState, useMemo, useCallback, useEffect } from "react";
import { SlideModal, Wrapper, Header, Body } from "components/Modal/SlideModal";
import { Form } from "pages/Customer/form/Form";
import { getCustomerName } from "utils/getCustomerName";
import { CustomerName, Avatar, IconButton } from "./CreateCustomerModal.styled";
import { useApi } from "providers/ApiProvider";
import { useImperativeModal } from "ImperativeModalProvider";
import { ProfileImageModal } from "./ProfileImageModal";
import { debounce } from "lodash";
import Icon from "components/Icon";

const iconButtonStyle = { width: "50px", height: "50px", margin: "5px 10px" };

type CreateCustomerModalProps = {
  open: boolean;
  onClose: () => void;
  onSaveCallback?: () => void;
  customer?: any;
};

export function CreateCustomerModal({
  open,
  onClose,
  onSaveCallback,
  customer,
}: CreateCustomerModalProps) {
  const { filesApi } = useApi();
  const imperativeModal = useImperativeModal();
  const profileImageId = useMemo(() => {
    return customer?.profileImageId || null;
  }, [customer?.profileImageId]);
  const [localBlobUrl, setLocalBlobUrl] = useState(null);
  const [profileImage, setProfileImage]: any = useState(null);

  const blobUrl = useMemo(() => {
    if (!profileImageId && !profileImage) return null;
    return localBlobUrl || (profileImage && profileImage.thumbnailUrl);
  }, [profileImage]);

  const loadProfileImage = useCallback(
    debounce(async () => {
      if (profileImageId) {
        const res = await filesApi.getImage(profileImageId);
        const payload = await res.data;
        setProfileImage(payload.data);
      }
    }, 500),
    [profileImageId, filesApi]
  );

  const handleImageModal = async () => {
    return await imperativeModal.open((close) => (
      <ProfileImageModal
        onClose={close}
        profileImage={profileImage}
        setProfileImage={setProfileImage}
        localBlobUrl={localBlobUrl}
        setLocalBlobUrl={setLocalBlobUrl}
      />
    ));
  };

  useEffect(() => {
    if (profileImageId && profileImage === null) {
      loadProfileImage();
    }
  }, [profileImageId]);

  return (
    <SlideModal open={open}>
      <Wrapper>
        <Header>
          <IconButton sx={iconButtonStyle} onClick={onClose}>
            <Icon variant="close" />
          </IconButton>
          <span style={{ position: "relative" }}>
            <Avatar
              src={blobUrl}
              onClick={handleImageModal}
              className={`${customer?.sex || ""}`}
            />
            <IconButton className="image-edit-btn" onClick={handleImageModal}>
              <Icon variant="pencil" />
            </IconButton>
          </span>
          <CustomerName>
            {customer ? (
              <>
                <b>{getCustomerName(customer)}</b>님의 고객정보 수정
              </>
            ) : (
              "고객등록"
            )}
          </CustomerName>
        </Header>
        <Body>
          <Form
            customer={customer}
            profileImage={profileImage}
            onSaveCallback={() => {
              onSaveCallback && onSaveCallback();
              onClose();
            }}
          />
        </Body>
      </Wrapper>
    </SlideModal>
  );
}
