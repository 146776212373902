import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { IconButton } from "~/components/IconButton/IconButton";

export const Button = styled(IconButton)<{ $collapsed?: boolean }>`
  &&& {
    margin: 0px auto;
    padding: 5px 4px;
  }
  width: 16px;
  height: 16px;
  border-radius: 50% !important;
  padding: 0;
  border: 0;
  color: #202020;
  background: transparent;
  ${({ $collapsed }) =>
    $collapsed &&
    css`
      transform: rotate(180deg);
    `}
`;
