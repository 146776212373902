import {
  Box as MuiBox,
  styled,
  ListItem as MuiListItem,
  Link as MuiLink,
  Button as MuiButton,
} from "@mui/material";

export const Link = styled(MuiLink)`
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  color: unset;
  line-height: 1.5;
  display: block;
  width: 100%;
  height: 100%;
`;

export const Box = styled(MuiBox)``;

export const ListItem = styled(MuiListItem)`
  cursor: pointer;
  display: flex;
  column-gap: 18px;
  padding: 10px 20px;
  min-height: 55px;
`;

export const Button = styled(MuiButton)(
  ({ theme }) => `
  color: ${theme.palette.grey["700"]};
  text-transform: none;
  &:hover {
    background: none;
  }
`
);
