import axios, { AxiosResponse, AxiosInstance } from "axios";
import * as Sentry from "@sentry/browser";
import { v4 as uuidv4 } from "uuid";
import { ApiResource } from "./apiResource";
import { ApiClient } from "../apiClient";

export class ImageApi extends ApiResource {
  private s3Client: AxiosInstance;

  constructor(protected apiClient: ApiClient) {
    super(apiClient);
    this.s3Client = axios.create({
      timeout: 300000,
    });

    this.s3Client.interceptors.request.use((config) => {
      const requestId = uuidv4();

      Sentry.addBreadcrumb({
        type: "HTTP Request",
        category: "fetch.start",
        message: `[${requestId}] ${config.url}`,
        level: Sentry.Severity.Info,
      });

      config.metadata = { requestId };
      return config;
    });

    this.s3Client.interceptors.response.use(
      (response) => {
        const requestId = response.config.metadata?.requestId;

        Sentry.addBreadcrumb({
          type: "HTTP Request",
          category: "fetch.success",
          message: `[${requestId}] ${response.config.url} ${response.status}`,
          level: Sentry.Severity.Info,
        });

        return response;
      },
      (error) => {
        const requestId = error.config?.metadata?.requestId;

        Sentry.addBreadcrumb({
          type: "HTTP Request",
          category: "fetch.failure",
          message: `[${requestId}] ${error.config?.url} ${error.message}`,
          level: Sentry.Severity.Info,
        });

        throw error;
      }
    );
  }

  async uploadImage(
    url: string,
    file: File
  ): Promise<{ progress: number; response?: AxiosResponse; success: boolean }> {
    const MAX_RETRIES = 3;
    const RETRY_DELAY = 2000;
    let retries = 0;
    let lastProgress = 0;

    const upload = async (): Promise<{
      progress: number;
      response?: AxiosResponse;
      success: boolean;
    }> => {
      try {
        const response = await this.s3Client.put(url, file, {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              if (percentCompleted > lastProgress) {
                console.log(`Upload progress: ${percentCompleted}%`);
                lastProgress = percentCompleted;
              }
            }
          },
        });
        console.log(`Upload completed: ${lastProgress}%`);
        return { progress: lastProgress, response, success: true };
      } catch (error: any) {
        if (
          error.code === "ECONNABORTED" ||
          error.code === "ERR_NETWORK" ||
          !error.response
        ) {
          // 네트워크 오류 또는 연결 중단
          if (retries < MAX_RETRIES) {
            retries++;
            console.log(
              `Upload interrupted at ${lastProgress}%. Retrying (${retries}/${MAX_RETRIES})...`
            );
            await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
            return upload(); // 재귀적으로 다시 시도
          } else {
            console.error(
              `Upload failed after ${MAX_RETRIES} retries. Last progress: ${lastProgress}%`
            );
            return {
              progress: lastProgress,
              success: false,
            };
          }
        } else {
          console.error(`Upload failed at ${lastProgress}%: ${error.message}`);
          return {
            progress: lastProgress,
            success: false,
          };
        }
      }
    };

    return upload();
  }

  async uploadBookmarkImageFile(url: string, payload: any) {
    const response = await this.client.put(url, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (!response.status || response.status >= 400) {
      throw new Error(
        `Presigned url post failed. ${response.status} ${response.statusText}`
      );
    }

    return response;
  }
}
