import * as Sentry from "@sentry/browser";
import { v4 as uuidv4 } from "uuid";
import { SurveyApi } from "./resources/surveyApi";
import { UserApi } from "./resources/userApi";
import { AppointmentApi } from "./resources/appointmentApi";
import { SessionApi } from "./resources/sessionApi";
import { RegistrationApi } from "./resources/registrationApi";
import { FilesApi } from "./resources/filesApi";
import { ImageApi } from "./resources/imageApi";
import { CustomersApi } from "./resources/customersApi";
import { DepartmentsApi } from "./resources/departmentsApi";
import { TicketsApi } from "./resources/ticketsApi";
import { TreatmentsApi } from "./resources/treatmentsApi";
import { ClinicsApi } from "./resources/clinicsApi";
import { SmsApi } from "./resources/smsApi";
import { PopupsApi } from "./resources/popupsApi";

export class ApiException extends Error {
  public code;
  constructor(code: number, message: string, description: string) {
    super(message);
    this.code = code;
    this.name = message;
    this.message = description;
  }
}

export interface ApiDataResponse<T> {
  data: T;
  pagination?: {
    limit: number;
    pages: number;
    perPage?: number;
    total: number;
  };
}

export interface PaginationResponse<T> {
  data: T;
  pagination: {
    limit: number;
    pages: number;
    perPage?: number;
    total: number;
  };
}

export type ApiEmptyResponse = null;

const Event = {
  ACCESS_DENIED: "ACCESS_DENIED",
  ACCESS_RESTRICTED: "ACCESS_RESTRICTED",
  OWNER_ACCESS_DENIED: "OWNER_ACCESS_DENIED",
  UNAUTHORIZED: "UNAUTHORIZED",
  REFRESH_TOKEN: "REFRESH_TOKEN",
};

export class ApiClient extends EventTarget {
  public endpoint;
  static Event = Event;
  private accessToken: any;
  private refreshToken: any;
  public surveyApi: SurveyApi;
  public userApi: UserApi;
  public appointmentApi: AppointmentApi;
  public sessionApi: SessionApi;
  public registrationApi: RegistrationApi;
  public filesApi: FilesApi;
  public imageApi: ImageApi;
  public customersApi: CustomersApi;
  public departmentApi: DepartmentsApi;
  public ticketsApi: TicketsApi;
  public treatmentsApi: TreatmentsApi;
  public clinicsApi: ClinicsApi;
  public smsApi: SmsApi;
  public popupsApi: PopupsApi;

  constructor({ endpoint }: { endpoint: string | undefined }) {
    super();
    this.endpoint = endpoint;
    this.surveyApi = new SurveyApi(this);
    this.userApi = new UserApi(this);
    this.appointmentApi = new AppointmentApi(this);
    this.sessionApi = new SessionApi(this);
    this.registrationApi = new RegistrationApi(this);
    this.filesApi = new FilesApi(this);
    this.imageApi = new ImageApi(this);
    this.customersApi = new CustomersApi(this);
    this.departmentApi = new DepartmentsApi(this);
    this.ticketsApi = new TicketsApi(this);
    this.treatmentsApi = new TreatmentsApi(this);
    this.clinicsApi = new ClinicsApi(this);
    this.smsApi = new SmsApi(this);
    this.popupsApi = new PopupsApi(this);
  }

  setTokens(accessToken: string, refreshToken: string) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
  }

  buildHeaders(userOptions = {}) {
    const defaultArgs = {
      contentType: "application/json",
      refresh: false,
      skipAuthorization: false,
    };

    const options = Object.assign({}, defaultArgs, userOptions);
    const headers: any = {};

    if (options.contentType) {
      headers["Content-Type"] = options.contentType;
    }

    if (options.refresh) {
      headers["Authorization"] = `Bearer ${this.refreshToken}`;
    } else if (this.accessToken) {
      headers["Authorization"] = `Bearer ${this.accessToken}`;
    }

    return headers;
  }

  on(type: string, listener: any) {
    const handler = async (e: any) => listener(e.detail);
    this.addEventListener(type, handler);
    return () => {
      this.removeEventListener(type, handler);
    };
  }

  _dispatch(eventName: string, payload?: any) {
    this.dispatchEvent(new CustomEvent(eventName, { detail: payload }));
  }

  async request(
    request_func: any,
    options?: any,
    retries?: number
  ): Promise<any> {
    const requestOptions = Object.assign(
      {
        retry: true,
      },
      options
    );

    const res = await request_func();

    if (res.ok) return res;

    let errorObject = "";
    await res.text().then((text: string) => (errorObject = text));
    const message = JSON.parse(errorObject)?.description;
    const name = JSON.parse(errorObject)?.name;

    if (!res.ok) {
      if (res.status === 401 && requestOptions.retry && (retries || 0) === 0) {
        try {
          await this.userApi.refreshAccessToken();
          return await this.request(request_func, options, (retries || 0) + 1);
        } catch (e) {
          this._dispatch(ApiClient.Event.UNAUTHORIZED);
          throw e;
        }
      }

      if (res.status === 403 && name === "ACCESS_DENIED") {
        this._dispatch(ApiClient.Event.ACCESS_DENIED);
      }

      if (res.status === 403 && name === "OWNER_ACCESS_DENIED") {
        this._dispatch(ApiClient.Event.OWNER_ACCESS_DENIED);
      }

      if (res.status === 403 && name === "ACCESS_RESTRICTED") {
        this._dispatch(ApiClient.Event.ACCESS_RESTRICTED);
      }

      throw new ApiException(res.status, name || message, message);
    }
  }

  async fetch(endpoint: string, option?: any) {
    const requestId = uuidv4();

    Sentry.addBreadcrumb({
      type: "HTTP Request",
      category: "fetch.start",
      message: `[${requestId}] ${endpoint}`,
      level: Sentry.Severity.Info,
    });

    try {
      const result = await fetch(endpoint, option);
      Sentry.addBreadcrumb({
        type: "HTTP Request",
        category: "fetch.success",
        message: `[${requestId}] ${endpoint} ${result.status}`,
        level: Sentry.Severity.Info,
      });

      return result;
    } catch (e: any) {
      Sentry.addBreadcrumb({
        type: "HTTP Request",
        category: "fetch.failure",
        message: `[${requestId}] ${endpoint} ${e.message}`,
        level: Sentry.Severity.Info,
      });
      throw e;
    }
  }

  async get(path: string, params?: any) {
    return this.request(() =>
      this.fetch(`${this.endpoint}${path}?` + new URLSearchParams(params), {
        method: "GET",
        headers: this.buildHeaders(),
      })
    );
  }

  async post(path: string, payload?: any, options?: any) {
    return this.request(
      () =>
        this.fetch(`${this.endpoint}${path}`, {
          method: "POST",
          headers: this.buildHeaders(options?.headers),
          body: payload ? JSON.stringify(payload) : payload,
        }),
      options
    );
  }

  async put(path: string, payload: any, options?: any) {
    return this.request(
      () =>
        this.fetch(`${this.endpoint}${path}`, {
          method: "PUT",
          headers: this.buildHeaders(),
          body: JSON.stringify(payload),
        }),
      options
    );
  }

  async delete(path: string, payload?: any, options?: any) {
    return this.request(
      () =>
        this.fetch(`${this.endpoint}${path}`, {
          method: "DELETE",
          headers: this.buildHeaders(),
          body: JSON.stringify(payload),
        }),
      options
    );
  }

  async multipart(path: string, payload: any, options?: any) {
    return this.request(async () => {
      const formData = new FormData();

      for (const name in payload) {
        formData.append(name, payload[name]);
      }

      return await this.fetch(`${this.endpoint}${path}`, {
        method: "POST",
        headers: this.buildHeaders({
          contentType: null,
        }),
        body: formData,
      });
    }, options);
  }
}

export const apiClient = new ApiClient({
  endpoint: process.env.REACT_APP_API_ENDPOINT,
});
