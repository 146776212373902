import { useCallback, useEffect, useRef, useState } from "react";
import {
  useBlocker,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { ImageEditor } from "image-editor";
import { useApi } from "providers/ApiProvider";
import { useSnackbarContext } from "~/SnackbarProvider_v2";
import { useImageEditorBoilerplate } from "~/hooks/useImageEditorBoilerplate";
import { keyed } from "hoc/keyed";
import { ApiException } from "core/apiClient";
import { FileData } from "core/resources/filesApi";
import { ActionPage } from "./ActionPage";
import FileEditPageTitle from "./FileEditPageTitle";
import ThumbnailContainer from "components/image-editor/thumbnail/ThumbnailContainer";
import { validateImageFile } from "utils/fileUtil";
import { Box, Stack, Typography, Button } from "./FileEditPage.styled";

type BlockerType =
  | {
      state: "blocked" | "proceeding" | "unblocked";
      reset: () => void;
      proceed: () => void;
    }
  | undefined;

export type ToolbarPositionType = "top" | "bottom" | "none";

export const FileEditPage = keyed(() => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const editorRef: any = useRef();
  const childrenRef = useRef();
  const nav = useNavigate();
  const snackbar = useSnackbarContext();
  const { customersApi, filesApi, imageApi } = useApi();
  const { boilerplate } = useImageEditorBoilerplate();
  const [saveFlag, setSaveFlag] = useState(false);
  const [saving, setSaving] = useState(false);
  const [customer, setCustomer] = useState<any>();
  const [file, setFile] = useState<any>(null);
  const [error, setError] = useState<null | string>(null);
  const [allowNavigation, setAllowNavigation] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(false);
  const [files, setFiles] = useState<FileData[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [toolbarPosition, setToolbarPosition] = useState<ToolbarPositionType>(
    (localStorage.getItem("verticalPosition") as "top" | "bottom" | null) ??
      "bottom"
  );
  const [totalCount, setTotalCount] = useState(0);

  const shouldBlock = () => {
    if (allowNavigation) return false;
    const prevHistoryBtnDisabled = document.querySelector<HTMLButtonElement>(
      '.toolbar-btn-wrapper[aria-label="실행취소"]>button'
    )?.disabled;
    return !saveFlag && !prevHistoryBtnDisabled && editorRef.current?.isDirty();
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (shouldBlock()) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const blocker = useBlocker(shouldBlock) as BlockerType;

  useEffect(() => {
    if (pendingNavigation && allowNavigation && blocker) {
      blocker.proceed();
      setPendingNavigation(false);
    }
  }, [allowNavigation, pendingNavigation, blocker]);

  useEffect(() => {
    const handleBlock = async () => {
      if (blocker?.state === "blocked") {
        const confirmed = window.confirm(
          "변경 사항이 있습니다. 저장하시겠습니까?"
        );
        if (confirmed) {
          if (editorRef.current && editorRef.current.isDirty()) {
            try {
              setSaving(true);
              await save();
              setSaveFlag(true);
              snackbar.success("이미지를 성공적으로 업데이트했습니다.");
              blocker.proceed();
            } catch (e) {
              snackbar.alert("이미지 저장에 실패했습니다.");
              blocker.reset();
            } finally {
              setSaving(false);
            }
          } else {
            blocker.proceed();
          }
        } else {
          setSaveFlag(true);
          setAllowNavigation(true);
          setPendingNavigation(true);
        }
      }
    };
    handleBlock();
  }, [blocker?.state, editorRef.current]);

  useEffect(() => {
    loadCustomer();
  }, []);

  const loadCustomer = useCallback(async () => {
    const customerId = searchParams.get("customerId");
    if (!customerId) return;

    try {
      const res = await customersApi.getCustomer(customerId);
      const payload = await res.data;
      setCustomer(payload.data);
    } catch (e) {
      console.log(e);
    }
  }, [searchParams]);

  const loadFile = useCallback(async () => {
    try {
      const res = await filesApi.getFile(id!);
      const payload = await res.data;
      setFile(payload.data);
      if (payload.data.image.thumbnailStatus === "FAILED") {
        setError("지원하지 않는 이미지 파일입니다.");
      }
      if (payload && payload.data.parent.id) {
        loadDriveEntities(payload.data.parent.id);
      }
    } catch (e) {
      if (e instanceof ApiException && e.code === 404) {
        setError("존재하지 않거나 삭제된 파일입니다.");
      } else {
        setError("파일을 불러오는데 실패했습니다.");
        throw e;
      }
    }
  }, [id]);

  const loadDriveEntities = async (parentId: number) => {
    try {
      const orderBy = searchParams.get("orderBy") ?? "createdAt desc";
      const searchKeyword = searchParams.get("searchKeyword") ?? "";
      const response = await filesApi.getFiles({
        parentId,
        orderBy,
        name: searchKeyword ? searchKeyword : undefined,
        limit: 9999,
      });
      const data = await response.data;
      setFiles(data.data);
      setTotalCount(data.pagination.total);
    } catch (e) {
      console.log("error", e);
      snackbar.alert("썸네일 미리보기 생성 중 에러가 발생했습니다.");
    }
  };

  const getPresignedData = async (fileExtension: string) => {
    const response = await filesApi.createImage(fileExtension);
    return await response.data;
  };

  const validateImageBeforeSave = (blob: Blob, originalUrl: string) => {
    return new Promise((resolve, reject) => {
      if (!blob.type.startsWith("image/")) {
        reject(new Error("올바른 이미지 파일이 아닙니다."));
      }
      const img = new Image();
      img.onload = () => {
        if (img.width === 0 || img.height === 0) {
          reject(
            new Error("유효하지 않은 이미지입니다: 너비 또는 높이가 0입니다.")
          );
        } else {
          fetch(originalUrl)
            .then((response) => response.blob())
            .then((originalBlob) => {
              const sizeRatio = blob.size / originalBlob.size;
              if (sizeRatio < 0.6) {
                reject(
                  new Error(
                    `이미지 크기가 변경되었습니다. 원본 대비 ${(
                      sizeRatio * 100
                    ).toFixed(2)}%입니다.`
                  )
                );
              } else {
                resolve({ width: img.width, height: img.height, sizeRatio });
              }
            })
            .catch((error) => {
              console.error("원본 이미지 비교 중 오류 발생:", error);
              resolve({ width: img.width, height: img.height, sizeRatio: 1 });
            });
          resolve({ width: img.width, height: img.height });
        }
      };
      img.onerror = () => reject(new Error("손상된 이미지 파일입니다."));
      img.src = URL.createObjectURL(blob);
    });
  };

  const save = async () => {
    try {
      const blob = await editorRef.current.toBlob();
      const {
        data: { presignedUrl, token },
      } = await getPresignedData("png");
      await validateImageBeforeSave(blob, file.image.originalUrl);
      const _file = new File([blob], file.name, { type: blob.type });
      const { progress, response, success } = await imageApi.uploadImage(
        presignedUrl,
        _file
      );
      if (success && response) {
        const resp = await filesApi.uploadImages(token);
        const result = await resp.data;
        await filesApi.updateFile(file.id, { imageId: result.data.id });
      } else {
        throw new Error(`Upload incomplete: ${progress}% uploaded`);
      }
    } catch (error) {
      console.error("Image upload failed:", error);
      throw error;
    }
  };

  const handleClickSave = async () => {
    if (saving || !file || error) {
      return;
    }
    if (!editorRef.current || editorRef.current.isBusy()) {
      return snackbar.alert("아직 에디터를 불러오는 중입니다.");
    }
    try {
      setSaveFlag(true);
      setSaving(true);
      await save();
      snackbar.success("이미지를 성공적으로 업데이트했습니다.");
      nav(-1);
    } catch (e) {
      snackbar.alert("이미지 저장에 실패했습니다.");
      throw e;
    } finally {
      setSaving(false);
    }
  };

  const onCancel = async () => {
    nav(-1);
  };

  useEffect(() => {
    loadFile();
  }, [loadFile]);

  useEffect(() => {
    if (error || !file) return;
    if (file.image.originalUrl) return;
    const interval = setInterval(async () => {
      loadFile();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [error, file, loadFile]);

  const handleChangeExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const getNavigateUrl = (fileId: number) => {
    const params = new URLSearchParams();
    const customerId = searchParams.get("customerId");
    const orderBy = searchParams.get("orderBy");
    const searchKeyword = searchParams.get("searchKeyword");
    if (customerId) {
      params.set("customerId", customerId);
    }
    if (orderBy) {
      params.set("orderBy", orderBy);
    }
    if (searchKeyword) {
      params.set("searchKeyword", searchKeyword);
    }
    const queryString = params.toString();
    return `/files/${fileId}/edit${queryString ? `?${queryString}` : ""}`;
  };

  const handleNavigate = async (file: any) => {
    try {
      await validateImageFile(file);
      nav(getNavigateUrl(file.id), { replace: true });
    } catch (e: any) {
      return snackbar.alert(e.message);
    }
  };

  const handleClickThumbnail = (file: any) => {
    handleNavigate(file);
  };

  useEffect(() => {
    const imageEditor = document.querySelector(".image-editor>div");
    if (!imageEditor) return;

    const observer = new MutationObserver(() => {
      const controlWrapper = imageEditor.querySelector(
        ".toolbar-wrapper.control-wrapper-2"
      );
      if (controlWrapper !== null) {
        setToolbarPosition("none");
      } else {
        const toolbarWrapper = imageEditor.querySelector(".toolbar-wrapper");
        const contentBox =
          toolbarWrapper?.nextElementSibling ||
          toolbarWrapper?.previousElementSibling;
        if (!toolbarWrapper || !contentBox) return;

        const toolbarRect = toolbarWrapper.getBoundingClientRect();
        const contentRect = contentBox.getBoundingClientRect();
        const newPosition =
          toolbarRect.top < contentRect.top ? "top" : "bottom";
        setToolbarPosition(newPosition);
      }
    });

    const config = {
      childList: true,
    };
    observer.observe(imageEditor, config);

    return () => {
      observer.disconnect();
    };
  }, [file]);

  return (
    <ActionPage
      title={
        <FileEditPageTitle
          files={files}
          file={file}
          totalCount={totalCount}
          onNavigate={handleNavigate}
          onChangeExpanded={handleChangeExpanded}
          customer={customer}
        />
      }
      customer={customer}
      onCancel={onCancel}
      trailingButton={
        <Stack flexDirection="row" alignItems="center" gap="14px">
          <Button
            className="save-button"
            styled="text"
            onClick={handleClickSave}
            disabled={(saving || !file || error) as boolean}
          >
            {saving ? <CircularProgress size={24} /> : <span>저장</span>}
          </Button>
        </Stack>
      }
    >
      {!error && file?.image.originalUrl && (
        <Box className="image-editor-wrapper" ref={childrenRef}>
          {saving && <Box className="coating"></Box>}
          {isExpanded && (
            <ThumbnailContainer
              toolbarPosition={toolbarPosition}
              files={files}
              onClickThumbnail={handleClickThumbnail}
            />
          )}
          <Box className="image-editor">
            <ImageEditor
              ref={editorRef}
              imageUrl={file.image.originalUrl}
              boilerplate={boilerplate as any}
            />
          </Box>
        </Box>
      )}
      {!error && !file?.image.originalUrl && (
        <Box className="circular-progress-wrapper">
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Typography>{error}</Typography>
        </Box>
      )}
    </ActionPage>
  );
}, ["id"]);
