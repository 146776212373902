import { useState, useRef, useEffect } from "react";
import { TableCell, TableRow } from "@mui/material";
import { StatusInfoBadge } from "components/StatusInfoBadge";
import { getCustomerInfo } from "utils/getCustomerName";
import { useNavigate } from "react-router-dom";
import { useApi } from "providers/ApiProvider";
import { useClinic } from "providers/ClinicProvider";
import { maskName } from "utils/maskString";
import { Button } from "components/Button";
import {
  Memos,
  Memo,
  MoreMemoButton,
  Label,
  Contents,
  Divider,
  CustomerName,
} from "./AppointmentRow.styled";
import { ageText } from "utils/index";
import { format } from "date-fns";
import { useUser } from "providers/UserProvider";

const AppointmentRow = ({
  appointment,
  onClickRow,
  onOpenCustomerModal,
  onChangeStatus,
}: {
  appointment?: any;
  onClickRow?: any;
  onOpenCustomerModal?: any;
  onChangeStatus?: any;
}) => {
  const nav = useNavigate();
  const { customersApi } = useApi();
  const { isMasking } = useClinic();
  const { hasPermission } = useUser();
  const memoRef = useRef<HTMLDivElement>(null);
  const registratioinMemoRef = useRef<HTMLDivElement>(null);
  const [showMoreMemoText, setShowMoreMemoText] = useState(false);
  const [showMoreRegistrationMemoText, setShowMoreRegistrationMemoText] =
    useState(false);

  useEffect(() => {
    if (memoRef.current) {
      const element: any = memoRef.current;
      if (element.scrollHeight > element.offsetHeight + 1) {
        setShowMoreMemoText(true);
      } else {
        setShowMoreMemoText(false);
      }
    }
  }, [memoRef.current]);

  useEffect(() => {
    const element: any = registratioinMemoRef.current;
    if (element?.scrollHeight > element?.offsetHeight + 1) {
      setShowMoreRegistrationMemoText(true);
    } else {
      setShowMoreRegistrationMemoText(false);
    }
  }, [registratioinMemoRef.current]);

  const onClickPenchart = async (e: any) => {
    e.stopPropagation();

    const customer = appointment.customer;
    const res = await customersApi.getCustomerDrive(customer.id);
    const payload = await res.data;

    const directoryId = payload.data.root.id;

    nav(
      `/directories/${directoryId}?customerId=${customer.id}&name=${
        customer.name
      }(${customer.sex === "male" ? "M" : "F"}/${ageText(customer)}/${
        customer.birthday ? format(new Date(customer.birthday), "yyMMdd") : "-"
      }) 님의 펜차트&customerId=${customer.id}`
    );
  };

  return (
    <TableRow
      sx={{
        "& td": {
          padding: "10px 0",
          color: "#282828",
          borderBottom: "1px solid #F9FBFF",
        },
      }}
    >
      <TableCell align="center">
        <StatusInfoBadge
          appointment={appointment}
          onClickStatus={(v: any) => {
            onChangeStatus(appointment, v.status);
          }}
        />
      </TableCell>
      <TableCell
        align="center"
        sx={{
          color: "#0060ff !important",
          textDecoration: "underline",
        }}
        onClick={() => {
          onOpenCustomerModal(appointment.customer);
        }}
      >
        <CustomerName>
          {isMasking
            ? maskName(appointment.customer.name)
            : appointment.customer.name}
        </CustomerName>
        ({getCustomerInfo(appointment.customer)})
      </TableCell>
      <TableCell
        align="center"
        onClick={() => {
          onClickRow(appointment);
        }}
      >
        {appointment.startHour} - {appointment.endHour}
      </TableCell>
      <TableCell
        align="center"
        onClick={() => {
          onClickRow(appointment);
        }}
      >
        {appointment.department.name}
      </TableCell>
      <TableCell
        onClick={() => {
          onClickRow(appointment);
        }}
      >
        <Memos>
          {(appointment.type === "APPOINTMENT" ||
            (appointment.appointment &&
              !appointment.appointment.isDeleted)) && (
            <Memo>
              <Label>예약메모</Label>
              <div>
                <Contents
                  ref={memoRef}
                  dangerouslySetInnerHTML={{
                    __html:
                      appointment.type === "APPOINTMENT"
                        ? appointment.memo || "-"
                        : appointment.appointment?.isDeleted
                        ? "-"
                        : appointment.appointment?.memo || "-",
                  }}
                />
                {showMoreMemoText && <MoreMemoButton>[더 보기]</MoreMemoButton>}
              </div>
            </Memo>
          )}
          {appointment.type === "REGISTRATION" && (
            <>
              {appointment.appointment &&
                !appointment.appointment.isDeleted && <Divider />}
              <Memo>
                <Label>접수메모</Label>
                <div>
                  <Contents
                    ref={registratioinMemoRef}
                    dangerouslySetInnerHTML={{
                      __html: appointment.memo || "-",
                    }}
                  />
                  {showMoreRegistrationMemoText && (
                    <MoreMemoButton>[더 보기]</MoreMemoButton>
                  )}
                </div>
              </Memo>
            </>
          )}
        </Memos>
      </TableCell>
      {hasPermission("PENCHART_CUSTOMER") && (
        <TableCell align="center">
          <Button styled="outline" size="s" onClick={onClickPenchart}>
            차트보기
          </Button>
        </TableCell>
      )}
    </TableRow>
  );
};

export default AppointmentRow;
