import { useState, useMemo, useCallback, useEffect } from "react";
import {
  TextField as Input,
  Li,
  IconWrapper,
  SelectLabel,
  Total,
  AutoCompleteSelect,
  Popper,
} from "./ComboBox.styled";
import { createFilterOptions } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Chip } from "~/components/Chip";
import { NoOptionsText } from "~/components/NoOptionsText";
import { Checkbox } from "~/components/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import { debounce } from "lodash";

const SelectLabelText = "직접 검색 또는 선택해주세요.";

export type Option = {
  id: string | number;
  label: string;
  value: string | number;
};

type ComboBoxProps = {
  variant?: "default" | "search";
  options: Option[];
  onChange: (selected: Option[]) => void;
  value: Option[];
  placeholder?: string;
  size?: "medium" | "large";
  noOptionsText?: string;
  style?: React.CSSProperties;
  startAdornment?: React.ReactNode;
  multiple?: boolean;
  unlimit?: boolean;
  limit?: number;
  selectAll?: boolean;
  disabled?: boolean;
  disableCloseOnSelect?: boolean;
  disableClearable?: boolean;
  getOptionLabel?: (option: Option | string) => string;
  isOptionEqualToValue?: (option: Option, value: Option) => boolean;
};

export const ComboBox = ({
  variant = "default",
  options = [],
  onChange,
  value = [],
  placeholder = "",
  size = "large",
  noOptionsText = "",
  style,
  startAdornment,
  multiple = false,
  unlimit = false,
  selectAll = false,
  disabled = false,
  disableCloseOnSelect = true,
  disableClearable = false,
  getOptionLabel,
  ...props
}: ComboBoxProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const debouncedOnChange = useMemo(() => debounce(onChange, 100), [onChange]);

  useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  const allSelected = useMemo(
    () => options.length === value.length,
    [options.length, value.length]
  );
  const filter = useMemo(() => createFilterOptions(), []);

  const chipSize = useMemo(() => {
    switch (size) {
      case "medium":
        return isOpen ? "52px" : "38px";
      case "large":
      default:
        return isOpen ? "104px" : "76px";
    }
  }, [size, isOpen]);

  const handleSelectAll = useCallback(() => {
    const newValue = !allSelected ? options : [];
    debouncedOnChange(newValue);
  }, [allSelected, options, debouncedOnChange]);

  const handleChipClick = useCallback(() => {
    if (!disabled) {
      setIsOpen(true);
    }
  }, [disabled]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const filterOptions = useCallback(
    (options: Option[], params: any): Option[] | any => {
      const filtered = filter(options, params);
      if (selectAll && options.length > 0) {
        return [
          { label: "전체선택", value: "select-all" } as Option,
          ...filtered,
        ];
      }
      return filtered;
    },
    [filter, selectAll]
  );

  const renderTags = useCallback(
    (tags: Option[]) => {
      if (unlimit) {
        const items = [tags[0]];
        return (
          <div
            style={
              startAdornment
                ? { width: "calc(100% - 64px)" }
                : { width: "100%" }
            }
            onClick={handleChipClick}
          >
            {items.map((option, index) => (
              <Chip
                key={index}
                style={{ maxWidth: chipSize }}
                onClick={handleChipClick}
                showClosedButton={false}
                value={option.label}
              />
            ))}
            {tags.length > 1 && <Total>+{tags.length - 1}</Total>}
          </div>
        );
      }

      return tags.map((option, index) => (
        <Chip
          key={index}
          style={{ maxWidth: chipSize }}
          onClick={handleChipClick}
          showClosedButton={isOpen}
          value={option.label}
          onDelete={() => {
            debouncedOnChange(value.filter((f) => f.id !== option.id));
          }}
        />
      ));
    },
    [
      unlimit,
      startAdornment,
      chipSize,
      handleChipClick,
      isOpen,
      debouncedOnChange,
      value,
    ]
  );

  const renderInput = useCallback(
    (params: any) => {
      const inputProps = {
        ...params.inputProps,
        readOnly: disabled,
      };

      return (
        <Input
          style={{ background: "white", ...style }}
          startadornment={startAdornment && value.length}
          $isopen={isOpen}
          {...params}
          placeholder={value.length === 0 ? placeholder : ""}
          inputProps={inputProps}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {startAdornment && startAdornment}
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      );
    },
    [disabled, isOpen, placeholder, value]
  );

  return (
    <AutoCompleteSelect
      {...props}
      multiple={multiple}
      variant={variant}
      isActive={isOpen}
      open={isOpen}
      options={options}
      onChange={(
        _event: React.SyntheticEvent,
        newValue: Option[],
        reason: string
      ) => {
        if (!multiple) {
          setIsOpen(false);
          debouncedOnChange(newValue);
          return;
        }

        if (selectAll && reason === "selectOption") {
          const isSelectAllOption = newValue.some(
            (option) => option.value === "select-all"
          );
          if (isSelectAllOption) {
            handleSelectAll();
            return;
          }
        }

        if (reason === "clear") {
          debouncedOnChange([]);
          return;
        }

        debouncedOnChange(newValue);
      }}
      value={value}
      disableCloseOnSelect={disableCloseOnSelect}
      selectOnFocus
      disabled={disabled}
      noOptionsText={
        noOptionsText ? (
          <NoOptionsText value={noOptionsText} />
        ) : (
          <>
            <SelectLabel>{SelectLabelText}</SelectLabel>
            <NoOptionsText />
          </>
        )
      }
      PopperComponent={Popper}
      onOpen={handleOpen}
      onClose={(e: any, reason: string) => {
        if (reason === "escape" || reason === "blur") {
          handleClose();
        }
      }}
      renderOption={(props: any, option: Option) => (
        <div key={props.id}>
          {props["data-option-index"] === 0 && (
            <div style={{ marginLeft: "10px" }}>
              <SelectLabel>{SelectLabelText}</SelectLabel>
            </div>
          )}
          <Li {...props}>
            {multiple && (
              <Checkbox
                checked={
                  option.value === "select-all"
                    ? allSelected
                    : value.some((v) => v.id === option.id)
                }
              />
            )}
            {option.label}
          </Li>
        </div>
      )}
      filterOptions={filterOptions}
      renderTags={renderTags}
      renderInput={renderInput}
      disableClearable={
        disableClearable ?? (unlimit && multiple ? !isOpen : value.length === 0)
      }
      clearIcon={<CloseIcon sx={{ fontSize: 11 }} />}
      popupIcon={
        <IconWrapper>
          <KeyboardArrowDownRoundedIcon />
        </IconWrapper>
      }
      getOptionLabel={getOptionLabel}
    />
  );
};
