import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { ListItem, Link, Button } from "./NavigationDrawer.styled";
import ListItemText from "@mui/material/ListItemText";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUser } from "providers/UserProvider";
import { LogoutButton } from "pages/Nav/button/LogoutButton";
import { CertificationButton } from "pages/Nav/button/CertificationButton";
import { extractSemanticVersions } from "utils/versionUtil";
import { useSnackbarContext } from "~/SnackbarProvider_v2";
import Icon from "components/Icon";

interface VersionProps {
  version: string;
}

interface NavigationDrawerProps {
  open: boolean;
  onClose: () => void;
}

interface MenuItem {
  label: string;
  path: string;
}

const Version = ({ version }: VersionProps) => {
  const semanticVersion = extractSemanticVersions(version);
  const snackbar = useSnackbarContext();

  const handleClickVersion = () => {
    navigator.clipboard.writeText(`${version}, ${navigator.userAgent}`);
    snackbar.success("버전 정보가 클립보드에 저장되었습니다.");
  };

  return (
    <Box
      sx={{
        padding: "20px",
        fontSize: "14px",
        fontWeight: 700,
        color: "grey.700",
      }}
    >
      <Button variant={"text"} onClick={handleClickVersion}>
        {semanticVersion
          ? `Ver. ${semanticVersion.major}.${semanticVersion.minor}.${semanticVersion.patch}`
          : version}
      </Button>
    </Box>
  );
};

interface FolderIconProps {
  menu: string;
}

const FolderIcon = ({ menu }: FolderIconProps) => {
  switch (menu) {
    case "예약조회":
      return <Icon variant="appointment2" />;
    case "차트샘플함":
      return <Icon variant="folder" />;
    case "FAQ":
      return <Icon variant="faq" />;
    default:
      return null;
  }
};

export function NavigationDrawer({ open, onClose }: NavigationDrawerProps) {
  const nav = useNavigate();
  const { user } = useUser();

  const menus: MenuItem[] = [
    {
      label: "예약조회",
      path: "/appointments",
    },
    {
      label: "차트샘플함",
      path: "/pencharts",
    },
    {
      label: "FAQ",
      path: "/faq",
    },
  ];

  const navigateTo = (path: string) => {
    if (path === "/faq") {
      return;
    }
    nav(path);
  };

  return (
    <div>
      <Drawer anchor="left" open={open} onClose={onClose}>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: 300,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                padding: "68px 20px 30px",
                "& p": {
                  margin: 0,
                  textAlign: "center",
                },
              }}
            >
              <IconButton
                onClick={onClose}
                sx={{ position: "absolute", top: "26px", right: "20px" }}
              >
                <Icon variant="close" />
              </IconButton>
              <p style={{ fontSize: "17px", fontWeight: "bold" }}>
                {user.clinic.name}
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "2px",
                  marginTop: "8px",
                  marginBottom: "16px",
                }}
              >
                <p style={{ fontSize: "15px", fontWeight: 700 }}>{user.name}</p>
                <p style={{ fontSize: "13px", color: "#adaebc" }}>
                  {user.email}
                </p>
                <CertificationButton
                  certification={user.certification?.status}
                />
              </div>
              <LogoutButton />
            </Box>
            <List sx={{ padding: 0 }}>
              {menus.map((menu) => (
                <ListItem
                  key={menu.label}
                  onClick={() => navigateTo(menu.path)}
                >
                  <FolderIcon menu={menu.label} />
                  {menu.label === "FAQ" ? (
                    <Link
                      href="https://carecrm.notion.site/2cd393bc9cda49e0835ffa66d9b4030d?v=21627f764b8947a1ba11db1312bd5877"
                      target="_blank"
                    >
                      {menu.label}
                    </Link>
                  ) : (
                    <ListItemText
                      primary={menu.label}
                      sx={{
                        margin: 0,
                        "> .MuiTypography-root": {
                          fontSize: "15px",
                          fontWeight: 600,
                        },
                      }}
                    />
                  )}
                </ListItem>
              ))}
            </List>
          </Box>
          <Version version={process.env.REACT_APP_VERSION ?? ""} />
        </Box>
      </Drawer>
    </div>
  );
}
