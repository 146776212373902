import { useState, useCallback, useEffect, useMemo } from "react";
import { returnSex } from "./FindCustomerAutoCompleteRow";
import { phoneNumberFormatHyphen } from "~/utils/filters";
import { LabelWrapper } from "components/Form/Label";
import _ from "lodash";
import {
  FlexWrapper,
  InputWrapper,
  FindFamilyInput,
  AddButton,
  RemoveButton,
  Label,
} from "./FamilyInfo.styled";

const returnText = (customer: Customer) => {
  let { birthday, phoneNumber } = customer;
  const { name } = customer;

  if (birthday) birthday = birthday.replace(/-/g, ".");
  if (phoneNumber) phoneNumber = phoneNumberFormatHyphen(phoneNumber);

  return `${name ?? "-"}/${birthday ?? "-"}/${returnSex(customer.sex) ?? "-"}/${
    phoneNumber ?? "-"
  }`;
};

interface Customer {
  birthday?: string;
  phoneNumber?: string;
  name?: string;
  sex: "male" | "female";
}

interface FamilyRelationship {
  name: string;
  id: string;
  sex?: "male" | "female";
  [key: string]: any;
}

interface FamilyInfoProps {
  familyRelationships: FamilyRelationship[];
  setFamilyRelationships: (relationships: FamilyRelationship[]) => void;
  disabled?: boolean;
}

interface FamilyInputProps {
  index: number;
  items: FamilyRelationship[];
  setItems: (items: FamilyRelationship[]) => void;
  obj: FamilyRelationship[];
  setObj: (relationships: FamilyRelationship[]) => void;
  disabled?: boolean;
  isLast: boolean;
  onAdd: () => void;
  onRemove: (index: number) => void;
}

const FamilyInput = ({
  index,
  items,
  setItems,
  obj,
  setObj,
  disabled,
  isLast,
  onAdd,
  onRemove,
}: FamilyInputProps) => {
  return (
    <FlexWrapper>
      <InputWrapper>
        <FindFamilyInput
          index={index}
          items={items}
          setItems={setItems}
          obj={obj}
          setObj={setObj}
          disabled={disabled}
        />
      </InputWrapper>
      {!disabled &&
        (isLast ? (
          <AddButton onClick={onAdd}>+</AddButton>
        ) : (
          <RemoveButton onClick={() => onRemove(index)}>-</RemoveButton>
        ))}
    </FlexWrapper>
  );
};

const FamilyInfo = ({
  familyRelationships,
  setFamilyRelationships,
  disabled,
}: FamilyInfoProps) => {
  const initialItems = useMemo(
    () =>
      familyRelationships?.length > 0
        ? familyRelationships.map((v) => ({
            ...v,
            name: returnText(v as Customer),
          }))
        : [{ name: "", id: "" }],
    [familyRelationships]
  );

  const [items, setItems] = useState<FamilyRelationship[]>(initialItems);

  const handleAdd = useCallback(() => {
    setItems((prev) => [...prev, { name: "", id: "" }]);
  }, []);

  const handleRemove = useCallback((index: number) => {
    setItems((prev) => {
      if (prev.length <= 1) return prev;
      return prev.filter((_, i) => i !== index);
    });
  }, []);

  const debouncedSetFamilyRelationships = useMemo(
    () =>
      _.debounce((newItems: FamilyRelationship[]) => {
        const filteredItems = newItems.filter((item) => item.id || item.name);
        if (!_.isEqual(filteredItems, familyRelationships)) {
          setFamilyRelationships(filteredItems);
        }
      }, 300),
    [setFamilyRelationships, familyRelationships]
  );

  useEffect(() => {
    if (!_.isEqual(items, initialItems)) {
      debouncedSetFamilyRelationships(items);
    }
    return () => debouncedSetFamilyRelationships.cancel();
  }, [items, debouncedSetFamilyRelationships, initialItems]);

  return (
    <LabelWrapper column={3}>
      <Label text="가족관계" style={{ gridColumn: "1 / 3" }}>
        {items.map((item, index) => (
          <FamilyInput
            key={`family-input-${index}`}
            index={index}
            items={items}
            setItems={setItems}
            obj={familyRelationships}
            setObj={setFamilyRelationships}
            disabled={disabled}
            isLast={index === items.length - 1}
            onAdd={handleAdd}
            onRemove={handleRemove}
          />
        ))}
      </Label>
    </LabelWrapper>
  );
};

export default FamilyInfo;
