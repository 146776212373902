import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { useApi } from "providers/ApiProvider";
import { format } from "date-fns";
import {
  onChangeStatusCheck,
  onCheckAppointmentToRegistration,
} from "utils/appointmentCheck";
import {
  AppointmentStatus,
  RegistrationStatusGroup,
} from "type/appointmentStatus";
import { useSnackbarContext } from "~/SnackbarProvider_v2";
import { useImperativeModal } from "ImperativeModalProvider";
import { ImmediateSmsConfirmModal } from "modals/ImmediateSmsConfirmModal";
import { ConfirmModal } from "components/Modal/ConfirmModal";
import storeAppointments from "store/appointments";
import { APIError } from "~/type/common";
import { workTimeCheckByDate } from "utils/workTimeCheck";
import { WorkTimeConfirmModal } from "modals/WorkTimeConfirmModal";
import {
  externalUpdateConflictErrorMessage,
  validateCanceledNaverAppointment,
} from "utils/linkedChannelUtil";
import {
  useStatusSettings,
  StatusSettings,
} from "~/providers/StatusSettingsProvider";

const visitTypes = ["NEW", "ESTABLISHED"];
const regTypes = ["APPOINTMENT", "TODAY"];
const canceledTypes = ["CANCELED", "REGISTRATION_CANCELED"];

interface RequestTimeouts {
  loadAppointments: NodeJS.Timeout | null;
  loadCount: NodeJS.Timeout | null;
}

export const useAppointment = ({
  date,
  customerId,
  initOrder,
  departmentId: propDepartmentId,
  departmentIds: propDepartmentIds,
  clinic,
  status,
  countFilter,
  skipInitialLoad = false,
}: {
  date?: any;
  customerId?: any;
  initOrder?: any;
  departmentId: null | string;
  departmentIds?: number[];
  clinic?: { workEnd: string };
  status: number[];
  countFilter: string;
  skipInitialLoad?: boolean;
}) => {
  const { departmentApi, sessionApi, registrationApi, appointmentApi, smsApi } =
    useApi();
  const snackbar = useSnackbarContext();
  const imperativeModal = useImperativeModal();
  const { setDepartments: setStoreDepartments } = storeAppointments;
  const [departmentId, setDepartmentId] = useState<null | number>(
    propDepartmentId !== null ? JSON.parse(propDepartmentId) : propDepartmentId
  );
  const [departmentIds, setDepartmentIds] = useState<number[] | undefined>(
    propDepartmentIds
  );
  const [departments, setDepartments] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [count, setCount] = useState({
    total: 0,
    established: 0,
    new: 0,
    canceled: 0,
    appointment: 0,
    today: 0,
  });
  const [order, setOrder] = useState(initOrder.order ?? "asc");
  const [orderTarget, setOrderTarget] = useState(
    initOrder.target ?? "startHour"
  );
  const [subOrder, setSubOrder] = useState(initOrder.sub ?? "");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const limit = 25;
  const orderBy = useMemo(
    () =>
      subOrder
        ? `${orderTarget} ${order} ${`, ${subOrder}`}`
        : `${orderTarget} ${order}`,
    [order, orderTarget, subOrder]
  );
  const [requestingStatus, setRequestingStatus] = useState({
    loadAppointments: false,
    loadCount: false,
  });
  const requestTimeoutRef = useRef<RequestTimeouts>({
    loadAppointments: null,
    loadCount: null,
  });
  const { settings } = useStatusSettings() as StatusSettings;

  const canceledIds = useMemo(() => {
    return canceledTypes
      .map(
        (item) => settings.find((settingItem) => settingItem.code === item)?.id
      )
      .filter(Boolean);
  }, [settings]);

  useEffect(() => {
    if (propDepartmentIds) {
      setDepartmentIds(propDepartmentIds);
    }
  }, [propDepartmentIds]);

  useEffect(() => {
    if (propDepartmentId !== departmentId) {
      const parsedId =
        propDepartmentId !== null
          ? JSON.parse(propDepartmentId)
          : propDepartmentId;
      setDepartmentId(parsedId);
    }
  }, [propDepartmentId]);

  const loadDepartments = useCallback(async () => {
    const res = await departmentApi.getDepartments();
    const payload = await res.data;

    if (payload.data) {
      const departments = payload.data.flatMap((category: any) => {
        return category.departments.map((department: any) => ({
          ...department,
          categoryId: category.id,
        }));
      });

      setDepartments(departments);
      setStoreDepartments(departments);
    }
  }, []);

  const loadCustomerAppointments = useCallback(
    async ({ orderBy, page }: { orderBy?: string; page: number }) => {
      const params = {
        customerId,
        orderBy,
        limit: page * limit,
        progresses: "OPEN",
      };

      const response = await sessionApi.getSessionsHistories(params);
      const payload = await response.data;
      if (payload.data) {
        setAppointments(payload.data);
        setHasMore(payload.pagination.total >= page * limit);
      }
    },
    [customerId, limit]
  );

  const loadAppointments = useCallback(
    async ({
      date,
      orderBy,
      page,
    }: {
      date: string;
      orderBy?: string;
      page: number;
    }) => {
      if (!departmentIds || requestingStatus.loadAppointments) {
        return;
      }
      // 이전 타임아웃이 있으면 제거
      if (requestTimeoutRef.current.loadAppointments) {
        clearTimeout(requestTimeoutRef.current.loadAppointments);
      }

      // 짧은 타임아웃으로 연속 호출 방지
      requestTimeoutRef.current.loadAppointments = setTimeout(async () => {
        setRequestingStatus((prev) => ({ ...prev, loadAppointments: true }));

        const params: any = {
          dateStart: format(new Date(date), "yyyy-MM-dd"),
          dateEnd: format(new Date(date), "yyyy-MM-dd"),
          limit: page * limit,
          progresses: "OPEN",
        };

        if (departmentId) {
          params.departmentId = departmentId;
        } else if (departmentIds && departmentIds.length > 0) {
          params.departmentIds = departmentIds.join(",");
        }

        if (regTypes.includes(countFilter)) {
          params.registrationType = countFilter;
        }

        if (orderBy) params.orderBy = orderBy;

        if (countFilter === "CANCELED") {
          if (status.length > 0) {
            const filteredIds = status.filter((id) => canceledIds.includes(id));
            if (filteredIds.length > 0) {
              // 선택된 취소 상태가 있으면 해당 상태만 포함
              params.sessionStatusConfigIds = filteredIds.join(",");
            } else {
              // 선택된 취소 상태가 없는데, 취소만 보여줘야하므로 모든걸 제외함 (취소마저도)
              const allStatusIds = settings.map((item) => item.id);
              params.excludeStatusConfigIds = allStatusIds.join(",");
            }
          } else {
            // 모든 취소 상태 포함
            params.sessionStatusConfigIds = canceledIds.join(",");
          }
        } else if (visitTypes.includes(countFilter)) {
          params.visitType = countFilter;
          if (status.length > 0) {
            // 선택된 상태 중 취소되지 않은 상태만 포함
            if (status.filter((id) => !canceledIds.includes(id)).length > 0) {
              params.sessionStatusConfigIds = status
                .filter((id) => !canceledIds.includes(id))
                .join(",");
            } else {
              // 선택된 상태중인데 취소되지 않은 상태가 아무것도 없다는것은 모든걸 제외하는 상태
              const allStatusIds = settings.map((item) => item.id);
              params.excludeStatusConfigIds = allStatusIds.join(",");
            }
          } else {
            // 모든 비취소 상태 포함
            params.excludeStatusConfigIds = canceledIds.join(",");
          }
        } else {
          if (status.length > 0) {
            params.sessionStatusConfigIds = status.join(",");
          }
        }

        try {
          // console.log("ACTUAL Appointment API CALL", new Date().getTime());
          const res = await sessionApi.getSessionsSimple(params);
          const payload = await res.data;
          if (payload.data) {
            setAppointments(payload.data);
            setHasMore(payload.pagination.total >= page * limit);
          }
        } catch (error) {
          console.error("Failed to load appointments:", error);
        } finally {
          setRequestingStatus((prev) => ({ ...prev, loadAppointments: false }));
          requestTimeoutRef.current.loadAppointments = null;
        }
      }, 100);
    },
    [
      departmentId,
      departmentIds,
      countFilter,
      status,
      canceledIds,
      settings,
      limit,
      requestingStatus.loadAppointments,
    ]
  );

  const loadCount = useCallback(
    async (
      date: string,
      departmentId: number | null,
      departmentIds: number[] | undefined
    ) => {
      if (!date || !departmentIds || requestingStatus.loadCount) {
        return;
      }

      // 이전 타임아웃이 있으면 제거
      if (requestTimeoutRef.current.loadCount) {
        clearTimeout(requestTimeoutRef.current.loadCount);
      }

      // 짧은 타임아웃으로 연속 호출 방지
      requestTimeoutRef.current.loadCount = setTimeout(async () => {
        setRequestingStatus((prev) => ({ ...prev, loadCount: true }));

        const d = format(new Date(date), "yyyy-MM-dd");
        const params: any = { startAt: d, endAt: d };

        if (departmentId) {
          params.departmentId = departmentId;
        } else if (departmentIds.length > 0) {
          params.departmentIds = departmentIds.join(",");
        }

        if (countFilter === "CANCELED") {
          const filteredIds = status.filter((id) => canceledIds.includes(id));
          params.sessionStatusConfigIds =
            filteredIds.length > 0 ? filteredIds.join(",") : null;
        } else {
          params.sessionStatusConfigIds =
            status.length > 0 ? status.join(",") : null;
        }

        if (visitTypes.includes(countFilter)) {
          params.visitType = countFilter;
        }

        if (regTypes.includes(countFilter)) {
          params.registrationType = countFilter;
        }

        try {
          // console.log("ACTUAL Count API CALL", new Date().getTime());
          const res = await sessionApi.getAppointmentsCount(params);
          const payload = await res.data;
          if (payload.data) {
            setCount(payload.data);
          }
        } catch (error) {
          console.error("Failed to load count:", error);
        } finally {
          setRequestingStatus((prev) => ({ ...prev, loadCount: false }));
          requestTimeoutRef.current.loadCount = null;
        }
      }, 100);
    },
    [status, countFilter, canceledIds, requestingStatus.loadCount]
  );

  const reload = useCallback(async () => {
    setPage(1);
    if (customerId) {
      loadCustomerAppointments({ orderBy, page });
    } else {
      loadCount(date, departmentId, departmentIds);
      loadAppointments({ date, orderBy, page });
    }
  }, [customerId, orderBy, date, departmentId, departmentIds, page, status]);

  const updateOrder = useCallback(
    async (column: any, options: any) => {
      if (orderTarget === column) {
        setOrder(order === "asc" ? "desc" : "asc");
      } else {
        setOrderTarget(column);
        setOrder("desc");
      }
      setSubOrder(options);
    },
    [order, setOrder, orderTarget, setOrderTarget]
  );

  const updateDepartmentId = (id: number) => {
    setDepartmentId(id);
    setDepartmentIds([]);
    setPage(1);
  };

  const updateDepartmentIds = (ids: number[]) => {
    setDepartmentIds(ids);
    setDepartmentId(null);
    setPage(1);
  };

  const updatePage = () => {
    if (!hasMore) return;
    setPage((p) => (p += 1));
  };

  const updateAppointment = (id: any, updateData: any) => {
    const appointmentsData: any = [...appointments];
    const targetIndex = appointments.findIndex((v: any) => v.id === id);
    appointmentsData[targetIndex] = {
      ...appointmentsData[targetIndex],
      ...updateData,
    };
    setAppointments(appointmentsData);
  };

  useEffect(() => {
    if (skipInitialLoad) return;
    loadCount(date, departmentId, departmentIds);
  }, [date, departmentId, departmentIds, skipInitialLoad, status]);

  useEffect(() => {
    if (skipInitialLoad) return;
    if (customerId) {
      loadCustomerAppointments({ orderBy, page });
    } else {
      loadAppointments({ date, orderBy, page });
    }
  }, [
    date,
    departmentId,
    departmentIds,
    orderBy,
    customerId,
    page,
    skipInitialLoad,
    countFilter,
    status,
  ]);

  useEffect(() => {
    loadDepartments();
  }, []);

  const onChangeRestoreAppointment = async (id: number, sendSms: any) => {
    const params = {
      sendSms: sendSms,
      status: "SCHEDULED",
    };
    try {
      await appointmentApi.resotreAppointment(id, params);
      snackbar.success("예약으로 변경되었습니다.");
      reload();
    } catch (error) {
      const e = error as APIError;
      if (e.code === 500 && e.message === "Out of working hours") {
        return snackbar.alert("진료시간을 초과했습니다.");
      }
      if (e.code === 409 && e.message === "EXTERNAL UPDATE CONFLICT") {
        return snackbar.alert(externalUpdateConflictErrorMessage);
      }
      return snackbar.alert(e.message);
    }
  };

  const checkImmediateSms = async (smsRules: any, appointment: any) => {
    const confirmed = await imperativeModal.open((close) => (
      <ImmediateSmsConfirmModal
        smsRules={smsRules}
        onConfirm={() => close(true)}
        onCancel={() => close(false)}
      />
    ));
    if (confirmed) {
      onChangeRestoreAppointment(
        appointment.id,
        smsRules.map((v: any) => v.id)
      );
    } else {
      onChangeRestoreAppointment(
        appointment.id,
        smsRules
          .filter((f: any) => f.smsScheduleType !== "immediate")
          .map((v: any) => v.id)
      );
    }
  };

  const onChangeStatus = async (appointment: any, changeStatus: any) => {
    const { status, type, externalLinkedChannel, externalRequest } =
      appointment;
    try {
      validateCanceledNaverAppointment(
        status,
        externalRequest?.status,
        externalLinkedChannel
      );
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : String(error);
      return snackbar.alert(errorMessage);
    }

    let updated = null;
    if (type === "APPOINTMENT") {
      const targetStatus =
        changeStatus.type === AppointmentStatus.additionalStatus
          ? AppointmentStatus.additionalStatus
          : changeStatus.code;
      if (RegistrationStatusGroup.indexOf(targetStatus) > -1) {
        // 2. 예약 -> 접수로 변경하는 경우 POST /registrations
        const msg = onCheckAppointmentToRegistration(status, targetStatus);
        if (msg !== undefined) {
          snackbar.alert(msg);
          return;
        }

        const handleChangeStatus = async () => {
          const payload = {
            appointmentId: appointment.id,
            status: targetStatus,
            sessionStatusConfigId:
              targetStatus === AppointmentStatus.additionalStatus
                ? changeStatus.id
                : undefined,
          };
          const resp = await registrationApi.createRegistrationSimple(payload);
          updated = await resp.data;
        };

        if (
          clinic &&
          workTimeCheckByDate(
            appointment.endAt,
            appointment.startAt,
            clinic.workEnd
          )
        ) {
          const resp = await imperativeModal.open((close) => (
            <WorkTimeConfirmModal close={close} />
          ));
          if (resp) {
            await handleChangeStatus();
          } else {
            return;
          }
        } else {
          await handleChangeStatus();
        }
      } else {
        // 3. 예약 -> 예약으로 변경하는 경우
        if (changeStatus.code === AppointmentStatus.canceled) {
          //예약 -> 예약취소
          await sessionApi.cancelSessions(appointment.id);
        } else {
          if (changeStatus.code === AppointmentStatus.scheduled) {
            const res = await smsApi.getSmsNotifications();
            const smsRulesJson = await res.data;

            const smsRules = smsRulesJson.data.filter(
              (f: any) => f.smsScheduleType === "immediate"
            );

            if (
              smsRules.length > 0 &&
              new Date(appointment.startAt) > new Date()
            ) {
              checkImmediateSms(smsRules, appointment);
              return;
            }
          }
          try {
            await appointmentApi.editAppointment(appointment.id, {
              status: changeStatus.code,
            });
          } catch (e: any) {
            if (e.code === 409 && e.message === "EXTERNAL UPDATE CONFLICT") {
              return snackbar.alert(externalUpdateConflictErrorMessage);
            }
            return snackbar.alert("예약 변경에 실패했습니다.");
          }
        }
      }
    } else {
      // 4. 접수 -> 접수로 변경하는 경우 PUT /registrations
      const payload = {
        status:
          changeStatus.code === AppointmentStatus.complete
            ? `${appointment.category}_DONE`
            : changeStatus.code,
      };
      if (changeStatus.type === AppointmentStatus.additionalStatus) {
        await registrationApi.editRegistration(appointment.id, {
          status: AppointmentStatus.additionalStatus,
          sessionStatusConfigId: changeStatus.id,
        });
      } else {
        if (changeStatus.code === AppointmentStatus.scheduled) {
          const res = await registrationApi.getRegistration(appointment.id);
          const registration = await res.data;
          if (registration.data.appointment === null) {
            snackbar.alert("당일 접수는 예약으로 변경할 수 없습니다.");
            return;
          } else if (registration.data.charts.length > 0) {
            snackbar.alert(
              "연결된 등록차트가 있어 취소가 불가능합니다. (연결해제 후 가능)"
            );
            return;
          } else {
            if (
              registration.data.appointment.department.id !==
                appointment.department.id ||
              registration.data.appointment.startAt !== appointment.startAt
            ) {
              snackbar.alert("이미 접수된 건입니다. 상태를 확인하세요.");
              return;
            }
          }
          const resp = await registrationApi.revertRegistration(appointment.id);
          updated = await resp.data;
        } else if (
          changeStatus.code === AppointmentStatus.registrationCanceled
        ) {
          //접수 취소
          // 접수와 연동된 건인지 확인
          const res = await registrationApi.getRegistration(appointment.id);
          const registration = await res.data;
          if (registration.data.charts.length > 0) {
            snackbar.alert(
              "연결된 등록차트가 있어 취소가 불가능합니다. (연결해제 후 가능)"
            );
            return;
          }
          if (
            appointment.status === AppointmentStatus.leave ||
            appointment.status.indexOf("DONE") > -1
          ) {
            snackbar.alert("이미 완료된 건입니다. 상태를 확인하세요.");
            return;
          }

          const confirmed = await imperativeModal.open((close) => (
            <ConfirmModal
              body={
                <>
                  접수가 취소됩니다.
                  <br />
                  예약 상태로 변경하려면 상세화면에서 [예약전환] 처리하세요.
                </>
              }
              confirmText="확인"
              onClose={() => close(true)}
              onConfirm={async () => {
                await sessionApi.cancelSessions(appointment.id);
                snackbar.alert("접수가 취소되었습니다.");
                close();
              }}
              open={true}
            />
          ));
          if (confirmed) {
            return;
          }
        } else {
          const msg = onChangeStatusCheck(status, changeStatus.code);
          if (msg !== undefined) {
            snackbar.alert(msg);
            return;
          }
          await registrationApi.editRegistration(appointment.id, payload);
        }
      }
    }
    loadCount(date, departmentId, departmentIds);
    loadAppointments({ date, orderBy, page });
  };

  return {
    date,
    departmentId,
    departmentIds,
    departments,
    appointments,
    count,
    page,
    reload,
    order,
    updateDepartmentId,
    updateDepartmentIds,
    updateOrder,
    updatePage,
    updateAppointment,
    onChangeStatus,
    setDepartmentId,
    setPage,
  };
};
