import { useState } from "react";
import { useSnackbarContext } from "~/SnackbarProvider_v2";
import { List } from "components/List/List";
import { ConfirmModal } from "components/Modal/ConfirmModal";
import { CreateAppointmentModal } from "modals/CreateAppointmentModal";
import { AppointmentStatus, AppointmentCategory } from "type/appointmentStatus";
import { useApi } from "providers/ApiProvider";
import { useUser } from "providers/UserProvider";
import { Button, Box } from "./Detail.styled";
import { format } from "date-fns";
import { estimatedServiceMinutesToString } from "utils/timeUtil";
import { validateCanceledNaverAppointment } from "utils/linkedChannelUtil";

const infoData = (data: any) =>
  data && [
    [{ label: "예약종류", value: AppointmentCategory.getName(data.category) }],
    [{ label: "예약부서", value: data.department?.category.name }],
    [{ label: "일자", value: data.date }],
    [{ label: "방문시간", value: format(new Date(data.startAt), "HH시 mm분") }],
    [
      {
        label: "예상 소요시간",
        value: estimatedServiceMinutesToString(data),
      },
    ],
    [{ label: "내원경로", value: data.acquisitionChannel?.name }],
    [{ label: "의사", value: data.doctor?.name }],
    [{ label: "상담사", value: data.counselor?.name }],
    [{ label: "어시스트", value: data.assist?.name }],
    [{ label: "작성자", value: data.creator?.name }],
    [
      {
        label: "시/수술",
        value: data.treatmentItems.map((v: any) => (
          <>
            {v.category.name}-{v.name}
            <br />
          </>
        )),
      },
    ],
    [
      {
        label: "예약메모",
        value: (
          <Box
            dangerouslySetInnerHTML={{
              __html: data?.memo ?? "-",
            }}
            sx={{
              "*": {
                margin: 0,
              },
            }}
          />
        ),
      },
    ],
  ];

type DetailProps = {
  data: any;
  onClose: () => void;
  reload: () => void;
  edit: any;
  profileUrl?: string;
};

export function Detail({
  data,
  onClose,
  reload,
  edit,
  profileUrl,
}: DetailProps) {
  const { appointmentApi } = useApi();
  const snackbar = useSnackbarContext();

  const { hasPermission } = useUser();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const openEditAppointmentModal = () => {
    try {
      validateCanceledNaverAppointment(
        data.status,
        data.externalRequest?.status,
        data.externalLinkedChannel
      );
    } catch (e: any) {
      return snackbar.alert(e.message);
    }
    setOpenEditModal(true);
  };

  const closeEditAppointmentModal = () => {
    setOpenEditModal(false);
  };

  const onDelete = async () => {
    try {
      await appointmentApi.deleteAppointment(data?.id);
      snackbar.success("삭제되었습니다.");
      onClose();
      reload();
    } catch {
      snackbar.alert("삭제에 실패했습니다.");
    }
  };

  if (!data) return <></>;
  return (
    <Box
      sx={{
        height: "100%",
        paddingBottom: "74px",
      }}
    >
      <List data={infoData(data)} />
      <Box className={`action-btn-wrapper`}>
        {edit && (
          <>
            {hasPermission("APPOINTMENT_DELETE") && (
              <Button
                styled="fill"
                disabled={data.status === AppointmentStatus.registered}
                color="grey"
                onClick={() => setOpenAlert(true)}
                size="l"
              >
                삭제
              </Button>
            )}
            <Button
              styled="fill"
              color="primary"
              size="l"
              disabled={data.status === AppointmentStatus.registered}
              onClick={() => {
                openEditAppointmentModal();
              }}
            >
              수정
            </Button>
          </>
        )}
        {openEditModal && (
          <CreateAppointmentModal
            open={openEditModal}
            onClose={closeEditAppointmentModal}
            onSaveCallback={reload}
            customer={data.customer}
            appointment={data}
            profileUrl={profileUrl}
          />
        )}
        {openAlert && (
          <ConfirmModal
            title="삭제"
            body="내역을 삭제하시겠습니까?"
            confirmText="삭제"
            onClose={() => setOpenAlert(false)}
            onConfirm={onDelete}
            open={openAlert}
          />
        )}
      </Box>
    </Box>
  );
}
