import React, { useEffect, useCallback, useState } from "react";
import { useApi } from "providers/ApiProvider";
import { LabelWrapper, Label } from "components/Form/Label";
import { unusedCode } from "utils/unusedCodeUtil";
import { DropdownList, Button, Stack } from "./SurgerySelect.styled";

type SurgerySelectProps = {
  value: any;
  onChange: (value: any) => void;
};

export function SurgerySelect({ value, onChange }: SurgerySelectProps) {
  const { treatmentsApi } = useApi();
  const [treatmentItems, setTreatmentItems]: any = useState([]);

  const loadTreatmentItems = useCallback(async () => {
    const res = await treatmentsApi.getTreatmentItems();
    const payload = await res.data;
    setTreatmentItems(payload.data);
  }, [treatmentsApi]);

  useEffect(() => {
    loadTreatmentItems();
  }, [loadTreatmentItems]);

  const onChangeCategory = (v: any, i: number) => {
    const update = [...value];
    if (v.items) {
      update[i] = {
        category: v,
        ...v.items.filter((d: any) => d.visible)[0],
      };
    } else {
      const items = value[i];
      update[i] = {
        category: v,
        ...items,
      };
    }

    onChange(update);
  };

  const onChangeItem = (v: any, i: number) => {
    const update = [...value];
    update[i] = { ...update[i], ...v };
    onChange(update);
  };

  const addList = () => {
    const update = [...value];
    update.push({});
    onChange(update);
  };

  const removeList = (i: number) => {
    const update = [...value];
    update.splice(i, 1);
    onChange(update);
  };

  return (
    <>
      {value?.length > 0 && (
        <LabelWrapper column={2}>
          {value.map((v: any, i: number) => {
            const categoryOptions = [
              ...value
                .filter(
                  (f: any, fi: number) =>
                    (f.category?.visible === false && fi === i) ||
                    (f.category?.isDeleted === true && fi === i)
                )
                .map((f: any) => {
                  return {
                    ...f.category,
                    name: unusedCode.getNameByUnusedValue(f.category),
                  };
                }),
              ...(treatmentItems.filter((d: any) => d.visible) ?? []),
            ].map((item) => {
              item.label = item.name;
              return item;
            });
            const itemOptions = [
              ...value
                .filter(
                  (f: any, fi: number) =>
                    (f.category?.id === v.category?.id &&
                      f.visible === false &&
                      fi === i) ||
                    (f.category?.id === v.category?.id &&
                      f?.isDeleted === true &&
                      fi === i)
                )
                .map((f: any) => {
                  return {
                    ...f,
                    name: unusedCode.getNameByUnusedValue(f),
                  };
                }),
              ...(treatmentItems
                .find((o: any) => o.id === v?.category?.id)
                ?.items.filter((d: any) => d?.visible) ?? []),
            ].map((item) => {
              item.label = item.name;
              return item;
            });
            return (
              <React.Fragment key={i}>
                <Label text={i === 0 ? "시/수술 카테고리" : ""}>
                  <>
                    <DropdownList
                      options={categoryOptions}
                      value={categoryOptions.find(
                        (f: any) => f.id === v?.category?.id
                      )}
                      onChange={(v) => {
                        onChangeCategory(v, i);
                      }}
                      placeholder="시/수술 카테고리를 선택하세요."
                      noOptionsText={"시/수술 카테고리가 없습니다."}
                    />
                  </>
                </Label>
                <Label text={i === 0 ? "시/수술명" : ""}>
                  <Stack
                    gap={"17px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                  >
                    <DropdownList
                      options={itemOptions}
                      value={itemOptions.find((f: any) => f.id === v?.id)}
                      onChange={(v) => {
                        onChangeItem(v, i);
                      }}
                      placeholder="시/수술명을 선택하세요."
                      noOptionsText={"시/수술명이 없습니다."}
                    />
                    <Button
                      styled="fill"
                      size="m"
                      onClick={() =>
                        i === value.length - 1 ? addList() : removeList(i)
                      }
                      className={`${
                        i === value.length - 1 ? "primary" : "alert"
                      }`}
                    >
                      {i === value.length - 1 ? "+" : "-"}
                    </Button>
                  </Stack>
                </Label>
              </React.Fragment>
            );
          })}
        </LabelWrapper>
      )}
    </>
  );
}
