import { useCallback, useEffect, useState, useRef } from "react";
import Icon from "components/Icon";
import { SlideModal, Wrapper, Header } from "components/Modal/SlideModal";
import { SearchHistoryList } from "pages/Customer/list/SearchHistoryList";
import { List } from "pages/Customer/list/List";
import { useApi } from "providers/ApiProvider";
import { useSearchHistory } from "hooks/useSearchHistory";
import { InfiniteScroll } from "components/InfiniteScroll";
import { SearchInput, Body, IconButton } from "./SearchCustomerModal.styled";
import { useSnackbarContext } from "~/SnackbarProvider_v2";

const parseSearchInput = (searchQuery: string) => {
  const params: any = {};

  if (!searchQuery || !searchQuery.trim()) {
    return params;
  }

  const cleanedQuery = searchQuery.replace(/-/gi, "").trim();
  const isNumericOnly = /^\d+$/.test(cleanedQuery);

  if (isNumericOnly) {
    if (cleanedQuery.length === 4) {
      params.phoneNumberLast = cleanedQuery;
    } else if (cleanedQuery.length === 11) {
      params.phoneNumber = cleanedQuery;
    }
    // 그 외의 패턴은 이름으로 처리
    else {
      params.name = searchQuery.trim();
    }
  } else {
    params.name = searchQuery.trim();
  }

  return params;
};

type SearchCustomerModalProps = {
  open: boolean;
  onClose: () => void;
  type: "appointment" | "customer";
  placeholder: string;
  showHistory?: boolean;
};

export function SearchCustomerModal({
  open,
  onClose,
  type,
  placeholder,
  showHistory = false,
}: SearchCustomerModalProps) {
  const { customersApi } = useApi();
  const { addHistory, removeHistory, removeAllHistory, history } =
    useSearchHistory();
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [init, setInit] = useState(true);
  const inputRef = useRef<HTMLInputElement>();
  const snackbar = useSnackbarContext();

  useEffect(() => {
    inputRef.current && inputRef.current?.focus();
  }, [inputRef.current]);

  const loadData = useCallback(
    async ({ searchValue = "", page = 1 }) => {
      // 검색어가 공백만 있는 경우 처리
      const trimmedSearch = searchValue.trim();
      if (trimmedSearch === "" && page === 1) {
        setInit(false);
        setSearchValue("");
        setData([]);
        setHasMore(false);
        return;
      }

      try {
        setInit(false);
        setHasMore(false);
        setSearchValue(searchValue);

        // 검색 기록 추가 (유효한 검색어인 경우에만)
        if (showHistory && trimmedSearch) {
          addHistory(trimmedSearch);
        }

        const res = await customersApi.searchCustomer({
          page,
          ...parseSearchInput(searchValue),
        });

        if (res.data?.data) {
          setData((prevData) =>
            page === 1 ? res.data.data : [...prevData, ...res.data.data]
          );
          setPage(page);
          setHasMore(res.data.pagination.pages > page);
        } else {
          if (page === 1) {
            setData([]);
          }
          setHasMore(false);
        }
      } catch (error) {
        snackbar.alert("고객 검색 중 오류가 발생했습니다.");
      }
    },
    [addHistory, customersApi, showHistory]
  );

  const updateNextPage = useCallback(() => {
    if (hasMore) loadData({ searchValue, page: page + 1 });
  }, [hasMore, loadData, searchValue, page]);

  useEffect(() => {
    setSearchValue("");
    setData([]);
    setPage(1);
    setInit(true);
  }, [open]);

  return (
    <SlideModal open={open}>
      <Wrapper style={{ overflow: "hidden" }}>
        <Header>
          <IconButton onClick={onClose}>
            <Icon variant="back_arrow" />
          </IconButton>
          <SearchInput
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) =>
              e.key === "Enter" && loadData({ searchValue, page: 1 })
            }
            ref={inputRef as any}
            placeholder={placeholder}
          />
          <IconButton
            sx={{
              color: searchValue ? "#0060ff" : "#ADAEBC",
            }}
            onClick={() => loadData({ searchValue, page: 1 })}
          >
            <Icon variant="search" />
          </IconButton>
        </Header>
        <Body>
          {init && showHistory ? (
            <SearchHistoryList
              data={history}
              onDelete={removeHistory}
              onDeleteAll={removeAllHistory}
              onSearch={(v: any) => loadData({ searchValue: v, page: 1 })}
            />
          ) : (
            <InfiniteScroll action={updateNextPage}>
              <List
                data={data}
                onClose={onClose}
                type={type}
                onCustomerDelete={() => {
                  loadData({ searchValue, page: 1 });
                }}
              />
            </InfiniteScroll>
          )}
        </Body>
      </Wrapper>
    </SlideModal>
  );
}
