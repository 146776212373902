import {
  Button as MuiButton,
  css,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { color as colorTheme } from "styles/styles";
import { SerializedStyles } from "@emotion/react";

export type ButtonStyleType = "fill" | "outline" | "text";
export type ButtonSizeType = "l" | "m" | "s" | "xs" | "xxs";
export type ButtonColorType =
  | "primary"
  | "secondary"
  | "alert"
  | "mix"
  | "grey"
  | "green"
  | "black"
  | "deepGrey"
  | "orange";

export type StyledButtonProps = Omit<MuiButtonProps, "color" | "size"> & {
  $color?: ButtonColorType;
  $size?: ButtonSizeType;
  styled?: ButtonStyleType;
};

const getColor = (color: ButtonColorType) => {
  switch (color) {
    case "primary":
      return {
        default: colorTheme.primary.unoblue,
        hover: { fill: "#234EC5", outline: colorTheme.bluegrey[200] },
      };
    case "secondary":
      return {
        default: colorTheme.bluegrey[700],
        hover: { fill: "#212735", outline: colorTheme.grey[200] },
      };
    case "alert":
      return {
        default: colorTheme.alert,
        hover: { fill: "#BC4646", outline: "#eb57570d" },
      };
    case "mix":
      return {
        default: colorTheme.primary.deepblue,
        hover: { fill: colorTheme.primary.deepblue, outline: "#EDEFF133" },
      };
    case "grey":
      return {
        default: colorTheme.line,
        hover: { fill: colorTheme.line, outline: "#EDEFF133" },
      };
    case "green":
      return {
        default: "#68D083",
        hover: { fill: "#6CA97C", outline: "#EDEFF133" },
      };
    case "black":
      return {
        default: "#3A3A3A",
        hover: { fill: "#454545", outline: colorTheme.grey[200] },
      };
    case "deepGrey":
      return {
        default: colorTheme.grey[700],
        hover: { fill: "#212735", outline: colorTheme.grey[200] },
      };
    case "orange":
      return {
        default: "#ff9900",
        hover: { fill: "#E28800", outline: "#E28800" },
      };
    default:
      return {
        default: colorTheme.primary.unoblue,
        hover: { fill: "#234EC5", outline: colorTheme.bluegrey[200] },
      };
  }
};

const sizeStyles: Record<ButtonSizeType, SerializedStyles> = {
  l: css`
    min-height: 28px;
    padding: 0px 32px;
    border-radius: 2px;
    font-weight: bold;
  `,
  m: css`
    min-height: 28px;
    padding: 0px 18px;
    border-radius: 2px;
    font-weight: bold;
  `,
  s: css`
    min-height: 24px;
    padding: 0px 8px;
    font-size: 12px;
    border-radius: 2px;
    font-weight: 500;
  `,
  xs: css`
    min-height: 18px;
    padding: 0px 4px;
    font-size: 12px;
    border-radius: 2px;
    font-weight: 500;
  `,
  xxs: css`
    min-width: 48px;
    height: 19px;
    padding: 4px 6px;
    font-size: 11px;
    border-radius: 2px;
  `,
};

export const StyledButton = styled(MuiButton, {
  shouldForwardProp: (prop) =>
    !["styled", "$size", "$color"].includes(prop as string),
})<StyledButtonProps>((props) => {
  const {
    $color: color = "primary",
    styled: buttonStyle = "fill",
    $size: size = "m",
  } = props;

  const buttonColor = getColor(color as ButtonColorType);

  const styleVariants = {
    fill: css`
      color: #fff;
      background: ${buttonColor.default};
      border-color: ${buttonColor.default};
      &:hover {
        background: ${buttonColor.hover.fill};
        border-color: ${buttonColor.hover.fill};
      }
      &:disabled {
        color: #fff;
        border-color: ${colorTheme.bluegrey[500]};
        background-color: ${colorTheme.bluegrey[500]};
        opacity: 1;
      }
    `,
    outline: css`
      color: ${buttonColor.default};
      border-color: ${color === "mix" ? colorTheme.line : buttonColor.default};
      background: #fff;
      &:hover {
        background: ${buttonColor.hover.outline};
      }
      &:disabled {
        color: ${color === "primary"
          ? colorTheme.bluegrey[500]
          : colorTheme.grey[500]};
        border-color: ${color === "primary"
          ? colorTheme.bluegrey[500]
          : colorTheme.grey[500]};
        opacity: 1;
      }
    `,
    text: css`
      height: auto;
      padding: 0;
      border: 0;
      color: ${buttonColor.default};
      text-decoration-line: underline;
      &:hover {
        text-decoration-line: underline;
        background-color: transparent;
      }
      &:disabled {
        border: 0;
        color: ${buttonColor.default};
        background-color: transparent;
        opacity: 1;
      }
    `,
  } as const;

  return css`
    &&& {
      flex: 0 0 auto;
      gap: 4px;
      min-width: auto;
      line-height: inherit;
      font-size: 14px;
      letter-spacing: normal;
      padding: 0px 18px;
      border: 1px solid;
      transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1), width 0s;
      ${sizeStyles[size]}
      ${styleVariants[buttonStyle]}
    }
  `;
});
