import { ReactNode, useMemo, useState } from "react";
import { AppBar } from "@mui/material";
import { QuestionMark } from "components/QuestionMark";
import { Box, DefaultModal, Toolbar } from "./CenterAlignedTAB.styled";
import { useLocation } from "react-router-dom";

type CenterAlignedTABProps = {
  trailingButton: null | ReactNode;
  title: null | ReactNode;
  leadingButton: null | ReactNode;
};

export function CenterAlignedTAB({
  leadingButton,
  title,
  trailingButton = null,
}: CenterAlignedTABProps) {
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);

  const isPenchartPage = useMemo(() => {
    const { pathname, search } = location;
    return (
      pathname === "/pencharts" || decodeURIComponent(search).includes("펜차트")
    );
  }, [location]);

  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <AppBar
      position="sticky"
      color="inherit"
      sx={{
        boxShadow: "none",
        textAlign: "space-between",
      }}
    >
      <Toolbar>
        <Box>{leadingButton}</Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {title}
        </Box>
        {trailingButton && <Box>{trailingButton}</Box>}
        {isPenchartPage && (
          <Box
            className={`guide-text ${
              location.pathname === "/pencharts"
                ? "absolute pos-right r-21"
                : ""
            }`}
            onClick={handleOpen}
          >
            이미지 등록 안내
            <QuestionMark size="medium" />
          </Box>
        )}
      </Toolbar>
      <DefaultModal
        open={openModal}
        title="이미지 등록 안내"
        body={
          <Box className="penchart-guide-text">
            <span>jpg, png 파일만 등록할 수 있습니다.</span>
            <span>
              이미지 파일 크기가 50MB를 초과하거나, 가로 또는 세로 길이가
              4096px을 초과하는 경우에는 앱에서 업로드 할 수 없으며 PC에서
              업로드해 주시길 바랍니다.
              <br />
              (안드로이드 기기는 4128px까지 가능)
            </span>
          </Box>
        }
        onClose={handleClose}
      />
    </AppBar>
  );
}
