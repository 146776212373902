import { ApiClient } from "core/apiClient";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import { generateCookieName } from "utils/cookieUtil";
import { useApi } from "./ApiProvider";

const AuthContext = createContext<any>(null);
const ACCESS_TOKEN_COOKIE_NAME = generateCookieName("access-token");
const REFRESH_TOKEN_COOKIE_NAME = generateCookieName("refresh-token");
const SECURE = process.env.REACT_APP_ENV !== "local";

export const AuthError = {
  ACCESS_DENIED: "ACCESS_DENIED",
  OWNER_ACCESS_DENIED: "OWNER_ACCESS_DENIED",
  ACCESS_RESTRICTED: "ACCESS_RESTRICTED",
  UNAUTHORIZED: "UNAUTHORIZED",
};

export function AuthProvider({ children }: { children?: any }) {
  const api = useApi();
  const { userApi } = api;
  const [cookies, setCookie, removeCookie] = useCookies([
    ACCESS_TOKEN_COOKIE_NAME,
    REFRESH_TOKEN_COOKIE_NAME,
  ]);

  const [error, setError] = useState<any>(null);
  const [intialized, setInitialized] = useState(false);

  const clear = useCallback(() => {
    removeCookie(ACCESS_TOKEN_COOKIE_NAME, {
      path: "/",
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
    removeCookie(REFRESH_TOKEN_COOKIE_NAME, {
      path: "/",
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
  }, [removeCookie]);

  const setTokens: any = useCallback(
    (accessToken: any, refreshToken: any, sessionOnly: any) => {
      api.setTokens(accessToken, refreshToken);

      setCookie(ACCESS_TOKEN_COOKIE_NAME, accessToken, {
        secure: SECURE,
        maxAge: sessionOnly ? undefined : 60 * 60 * 24 * 7,
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        path: "/",
      });

      setCookie(REFRESH_TOKEN_COOKIE_NAME, refreshToken, {
        secure: SECURE,
        maxAge: sessionOnly ? undefined : 60 * 60 * 24 * 30,
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        path: "/",
      });
    },
    []
  );

  const signin = useCallback(
    async (email: any, password: any, autoLogin: any) => {
      const res = await userApi.signin(email, password);
      const { accessToken, refreshToken } = await res.data;
      if (accessToken === null || refreshToken === null) {
        return "certUser";
      }
      setTokens(accessToken, refreshToken, !autoLogin);
      setError(null);
      return "manualUser";
    },
    []
  );

  const confirmCertification = useCallback(
    async (email: any, confirmNumber: any, autoLogin: any) => {
      const response = await userApi.confirmCertification(email, confirmNumber);
      const { accessToken, refreshToken } = await response.data;
      setTokens(accessToken, refreshToken, !autoLogin);
      setError(null);
    },
    []
  );

  const signout = useCallback(() => {
    clear();
  }, [clear]);

  const accessToken = useMemo(
    () => cookies[ACCESS_TOKEN_COOKIE_NAME],
    [cookies]
  );

  const refreshToken = useMemo(
    () => cookies[REFRESH_TOKEN_COOKIE_NAME],
    [cookies]
  );

  useEffect(() => {
    setInitialized(true);
    api.setTokens(accessToken, refreshToken);

    return api.on(
      ApiClient.Event.REFRESH_TOKEN,
      ({
        accessToken,
        refreshToken,
      }: {
        accessToken?: any;
        refreshToken?: any;
      }) => {
        setTokens(accessToken, refreshToken);
      }
    );
  }, [accessToken, api, refreshToken, setTokens]);

  useEffect(() => {
    return api.on(ApiClient.Event.ACCESS_DENIED, () => {
      setError(AuthError.ACCESS_DENIED);
      clear();
    });
  }, [api, clear]);

  useEffect(() => {
    return api.on(ApiClient.Event.ACCESS_RESTRICTED, () => {
      setError(AuthError.ACCESS_RESTRICTED);
      clear();
    });
  }, [api, clear]);

  useEffect(() => {
    return api.on(ApiClient.Event.OWNER_ACCESS_DENIED, () => {
      setError(AuthError.OWNER_ACCESS_DENIED);
      clear();
    });
  }, [api, clear]);

  useEffect(() => {
    return api.on(ApiClient.Event.UNAUTHORIZED, () => {
      setError(AuthError.UNAUTHORIZED);
      clear();
    });
  }, [api, clear]);

  useEffect(() => {
    if (
      (error === AuthError.UNAUTHORIZED ||
        error === AuthError.ACCESS_DENIED ||
        error === AuthError.OWNER_ACCESS_DENIED ||
        error === AuthError.ACCESS_RESTRICTED) &&
      location.pathname !== "/login"
    ) {
      location.href = "/login";
    }
  }, [error]);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        authed: Boolean(refreshToken),
        setTokens,
        clear,
        signin,
        signout,
        error,
        setError,
        confirmCertification,
      }}
    >
      {intialized && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
