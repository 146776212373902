import { makeAutoObservable, runInAction } from "mobx";

type Department = { id: number; name: string; [key: string]: any };

class Appointments {
  public departments: Department[] = [];
  public departmentId: null | number = null;
  public selectedDepartmentFilters: number[] | undefined = undefined;
  public selectedStatusFilters: number[] = [];
  public countFilter = "ALL";

  constructor() {
    makeAutoObservable(this);
  }

  setDepartmentId = (departmentId: null | number) => {
    runInAction(() => {
      this.departmentId = departmentId;
    });
  };

  setDepartments = (departments: Department[]) => {
    runInAction(() => {
      this.departments = departments;
    });
  };

  setSelectedDepartmentFilters = (categories: number[]) => {
    runInAction(() => {
      this.selectedDepartmentFilters = categories;
    });
  };

  setSelectedStatusFilters = (statuses: number[]) => {
    runInAction(() => {
      this.selectedStatusFilters = statuses;
    });
  };

  toggleDepartmentFilter = (departmentId: number) => {
    runInAction(() => {
      if (this.selectedDepartmentFilters === undefined) {
        this.selectedDepartmentFilters = [];
      }

      const isSelected = this.selectedDepartmentFilters.some(
        (item) => item === departmentId
      );

      if (isSelected) {
        this.selectedDepartmentFilters = this.selectedDepartmentFilters.filter(
          (item) => item !== departmentId
        );
      } else {
        this.selectedDepartmentFilters.push(departmentId);
      }
    });
  };

  toggleStatusFilter = (statusId: number) => {
    runInAction(() => {
      const isSelected = this.selectedStatusFilters.some(
        (item) => item === statusId
      );

      if (isSelected) {
        this.selectedStatusFilters = this.selectedStatusFilters.filter(
          (item) => item !== statusId
        );
      } else {
        this.selectedStatusFilters.push(statusId);
      }
    });
  };

  clearDepartmentFilters = () => {
    runInAction(() => {
      if (this.selectedDepartmentFilters !== undefined) {
        this.selectedDepartmentFilters = [];
      }
    });
  };

  clearStatusFilters = () => {
    runInAction(() => {
      this.selectedStatusFilters = [];
    });
  };

  isDepartmentSelected = (departmentId: number): boolean => {
    if (this.selectedDepartmentFilters === undefined) {
      return false;
    }
    return this.selectedDepartmentFilters.some((item) => item === departmentId);
  };

  isStatusSelected = (statusId: number): boolean => {
    return this.selectedStatusFilters.some((item) => item === statusId);
  };

  setCountFilter = (filter: string) => {
    runInAction(() => {
      this.countFilter = filter;
    });
  };
}

export default new Appointments();
