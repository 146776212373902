import { useMemo, forwardRef, useState, useCallback, useEffect } from "react";
import { getOptions } from "~/hooks/useFormOptions";
import { DropdownList as _DropdownList } from "components/DropdownList";
import { styled } from "@mui/material";
import { useApi } from "providers/ApiProvider";

export const DropdownList = styled(_DropdownList)(
  ({ theme }) => `
  height: 34px;
  
  input {
    padding-left: 20px !important;
    font-size: 14px !important;
    &::placeholder {
      color: ${theme.palette.bluegrey["600"]};
      opacity: 1;
    }
  }
  .MuiOutlinedInput-root {
    border-radius: 4px !important;
    font-size: 14px !important;
  }
  fieldset {
    border-color: ${theme.palette.layout.line};
  }
`
);

type AcquisitionChannelSelectProps = {
  value: any;
  onChange: (value: any) => void;
};

const AcquisitionChannelSelect = forwardRef(
  ({ value, onChange }: AcquisitionChannelSelectProps, ref) => {
    const { customersApi } = useApi();
    const [acquisitionChannelList, setAcquisitionChannelList] = useState();

    const loadCreated = useCallback(async () => {
      const res = await customersApi.getAcquisitionChannels("");
      const payload = await res.data;

      setAcquisitionChannelList(payload?.data);
    }, [customersApi]);

    useEffect(() => {
      loadCreated();
    }, [loadCreated]);

    const options = useMemo(() => {
      const data = acquisitionChannelList ?? [];
      if (!value || !value.id) return data;

      const extraOption = { ...value };
      if (
        (value.status === "inactive" || value.visible === false) &&
        !data.find((f: any) => f.id === value.id)
      ) {
        extraOption.visible = false;
      } else if (
        (value.status === "deleted" ||
          value?.isDeleted === true ||
          value?.deletedAt !== null) &&
        !data.find((f: any) => f.id === value.id)
      ) {
        extraOption.isDeleted = true;
      }

      if (data.find((f: any) => f.id === value.id)) {
        return data;
      } else {
        return getOptions(data, extraOption);
      }
    }, [acquisitionChannelList, value]);

    return (
      <>
        <DropdownList
          options={options.map((item) => {
            item.label = item.name;
            return item;
          })}
          value={options.find(
            (f: any) =>
              f.id ===
              (typeof value === "string" || typeof value === "number"
                ? value
                : value?.id)
          )}
          onChange={(v) => {
            onChange(v ? v : null);
          }}
          placeholder="내원경로를 선택하세요."
        />
      </>
    );
  }
);

export default AcquisitionChannelSelect;
