import { ReactNode, useState } from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { ClearButton } from "~/components/ClearButton";
import {
  IconWrapper,
  AutoCompleteSelect,
  Popper,
  Li,
  Input,
} from "./DropdownList.styled";

type DropdownListProps = {
  variant?: "default" | "search" | "none";
  options: any[];
  onChange: (params?: any) => void;
  value: any;
  placeholder?: string;
  startAdornment?: ReactNode;
  useClearIcon?: boolean;
  autoFocus?: boolean;
  renderInput?: any;
  renderOption?: any;
  disabled?: boolean;
  disableClearable?: boolean;
  getOptionLabel?: any;
  isOptionEqualToValue?: any;
  style?: any;
  noOptionsText?: string;
  className?: string;
};

export const DropdownList = ({
  variant = "default",
  options = [],
  onChange,
  value = null,
  placeholder = "",
  startAdornment,
  useClearIcon = true,
  autoFocus = false,
  renderInput,
  renderOption,
  disableClearable,
  ...props
}: DropdownListProps) => {
  const [isClose, setIsClose] = useState(true);

  return (
    <AutoCompleteSelect
      {...props}
      open={!isClose}
      variant={variant}
      isActive={!isClose}
      options={options}
      onChange={(_e: any, value: any) => {
        onChange(value);
      }}
      PopperComponent={Popper}
      value={value}
      onOpen={() => {
        setIsClose(false);
      }}
      onClose={() => {
        setIsClose(true);
      }}
      renderOption={
        renderOption
          ? renderOption
          : (props: any, option: any) => {
              return (
                <div key={props["data-option-index"]}>
                  <Li {...props}>
                    <div>{option.label}</div>
                  </Li>
                </div>
              );
            }
      }
      renderInput={
        renderInput
          ? renderInput
          : (params: any) => (
              <Input
                {...params}
                autoFocus={autoFocus}
                placeholder={placeholder ?? ""}
                inputProps={{ ...params.inputProps, readOnly: true }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      {startAdornment && startAdornment}
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
            )
      }
      disableClearable={disableClearable || isClose}
      clearIcon={useClearIcon && <ClearButton />}
      popupIcon={
        <IconWrapper>
          <KeyboardArrowDownRoundedIcon />
        </IconWrapper>
      }
    />
  );
};
