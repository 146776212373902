import { ApiResource } from "./apiResource";
import { ApiEmptyResponse, ApiDataResponse } from "../apiClient";

export class AppointmentApi extends ApiResource {
  async createAppointment(data: any) {
    return this.client.post<ApiDataResponse<any>>(`/appointments`, data);
  }

  async getAppointment(appointmentId: string) {
    return this.client.get<ApiDataResponse<any>>(
      `/appointments/${appointmentId}`
    );
  }

  async editAppointment(appointmentId: string, data: any) {
    return this.client.put<ApiDataResponse<any>>(
      `/appointments/${appointmentId}`,
      data
    );
  }

  async deleteAppointment(appointmentId: string) {
    return this.client.delete<ApiEmptyResponse>(
      `/appointments/${appointmentId}`
    );
  }

  async resotreAppointment(appointmentId: string | number, params: any) {
    return this.client.post<ApiDataResponse<any>>(
      `/appointments/${appointmentId}/restore`,
      params
    );
  }

  async getConfig(key: string) {
    return this.client.get<ApiDataResponse<any>>(
      `/appointments/v2/calendar/configs`,
      {
        params: { configKey: key },
      }
    );
  }

  async getStatusSettings() {
    return this.client.get<ApiDataResponse<any>>(
      "/sessions/session_status_config"
    );
  }
}
