import { initializeApp } from "firebase/app";
import {
  getRemoteConfig,
  getValue,
  fetchAndActivate,
} from "firebase/remote-config";

export class FirebaseClient {
  constructor() {
    if (!process.env.REACT_APP_FIREBASE_API_KEY) {
      return;
    }

    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    };

    const app = initializeApp(firebaseConfig);
    const remoteConfig = getRemoteConfig(app);
    remoteConfig.settings.minimumFetchIntervalMillis = 0;
    this.remoteConfig = remoteConfig;
  }

  async getStableVersion() {
    if (!this.remoteConfig) return null;

    try {
      await fetchAndActivate(this.remoteConfig);
      return getValue(this.remoteConfig, "stable_version").asString();
    } catch (error) {
      console.warn("stable_version config not found, defaulting to null");
      return null;
    }
  }
}
