import { useMemo, useState, useCallback, useEffect, MouseEvent } from "react";
import { useDialog } from "~/hooks/useDialog";
import { LabelWrapper } from "components/Form/Label";
import { Box, styled, Stack, Typography as MuiTypography } from "@mui/material";
import { Button } from "~/components/Button";
import Icon from "components/Icon";
import AddRemainingSurgeryModal from "~/modals/AddRemainingSurgeryCommonModal";
import { useApi } from "providers/ApiProvider";

const Typography = styled(MuiTypography)(
  ({ theme }) => `
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  font-family: unset;
`
);

export const AddRemainingSurgeryButton = ({
  customerId,
  onAddSurgeries,
}: {
  customerId: number;
  onAddSurgeries: any;
}) => {
  const surgeryDialog = useDialog();
  const { ticketsApi } = useApi();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [ticketList, setTicketList] = useState([]);
  const ticketsParams = useMemo(
    () => ({
      customerId: customerId,
      minRemainingCount: 1,
      limit: 100,
    }),
    [customerId]
  );
  const loadTickets = useCallback(async () => {
    const res = await ticketsApi.getTickets(ticketsParams);
    const payload = await res.data;
    const data = payload?.data;

    setTicketList(data);
  }, [ticketsApi, ticketsParams]);

  useEffect(() => {
    loadTickets();
  }, [loadTickets]);

  const openRemainingSurgeryModal = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    surgeryDialog.open();
  };

  return (
    <>
      <LabelWrapper column={1}>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="body1">시/수술</Typography>
          <Button
            styled="outline"
            color="mix"
            onClick={openRemainingSurgeryModal}
            style={{
              height: "34px",
              padding: "4px 10px",
              borderRadius: "4px",
            }}
            disabled={(ticketList?.length ?? 0) === 0 ? true : false}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <Icon variant="syringe" width={20} height={20} />
              <span>{`진행 시/수술 등록(${ticketList?.length ?? 0})`}</span>
            </Box>
          </Button>
        </Stack>
      </LabelWrapper>
      <AddRemainingSurgeryModal
        anchorEl={anchorEl}
        data={ticketList ?? []}
        open={surgeryDialog.opened}
        onAddSurgeries={onAddSurgeries}
        onClose={surgeryDialog.close}
      />
    </>
  );
};
