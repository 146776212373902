import { ApiResource } from "./apiResource";
import { ApiDataResponse } from "../apiClient";

export class ClinicsApi extends ApiResource {
  async getClinic(clinicId: string) {
    return this.client.get<ApiDataResponse<any>>(`/clinics/${clinicId}`);
  }

  async getConfigs(params?: any) {
    return this.client.get<ApiDataResponse<any>>(`/clinics/configs`, {
      params,
    });
  }

  async getClinicDrive(clinicId: string) {
    return this.client.get<ApiDataResponse<any>>(`/drives/clinics/${clinicId}`);
  }

  async getHolidays(params: any) {
    return this.client.get<ApiDataResponse<any>>("/holidays", {
      params,
    });
  }

  async getExternalLinkedChannel() {
    return this.client.get<ApiDataResponse<any>>("/external_linked/channels");
  }
}
