import {
  useAppointmentContext,
  AppointmentContextType,
} from "../AppointmentPage";
import {
  DefaultStatItem,
  StatItemWrapper,
  StatLabel,
  StatCount,
  LayoutStatCount,
  Wrapper,
  StatDivision,
  StatGroup,
  Box,
} from "./StatItems.styled";
import { NewBadge } from "~/components/badge/NewBadge";
import { RegistrationTypeBadge } from "~/components/badge/RegistrationTypeBadge";
import { StatItemsType } from "~/type/statItemsType";
import { QuestionMark } from "~/components/QuestionMark";
import { useImperativeModal } from "~/ImperativeModalProvider";
import { DefaultModal } from "~/components/CenterAlignedTAB/CenterAlignedTAB.styled";

type StatItemProps = {
  disabled?: any | undefined;
  selected?: any | undefined;
  label: any;
  count?: number;
  color?: string;
  onClick?: any;
};

export const StatItem = ({
  disabled,
  selected,
  label,
  count = 0,
  color = "blue",
  onClick,
}: StatItemProps) => {
  return (
    <StatItemWrapper disabled={disabled} selected={selected} onClick={onClick}>
      <StatLabel color={color}>{label}</StatLabel>
      <StatCount color={color}>{count}</StatCount>
    </StatItemWrapper>
  );
};

type StatItemsProps = {
  data: any;
  showTooltipIcon?: boolean;
};

export const StatItems = ({ data, showTooltipIcon }: StatItemsProps) => {
  const imperativeModal = useImperativeModal();
  const { countFilter, setCountFilter } =
    useAppointmentContext() as AppointmentContextType;

  const handleClick = (statType: string) => {
    if (!isDisabled(statType)) {
      if (countFilter === statType) {
        setCountFilter("ALL");
      } else {
        setCountFilter(statType);
      }
    } else {
      if (countFilter === "ALL") {
        setCountFilter("ALL");
      }
    }
  };

  const isDisabled = (statType: string) => {
    return countFilter !== "ALL" && countFilter !== statType;
  };

  const handleGuideClick = async () => {
    await imperativeModal.open((close) => (
      <DefaultModal
        open={true}
        title="안내"
        body={
          <span className="text no-dot">
            현재 목록에 보이는 결과에 대한 예약 및 당일 접수 전체와 신/구환(취소
            제외), 취소 현황입니다.
          </span>
        }
        onClose={close}
      />
    ));
  };

  return (
    <Wrapper className="sessions-stats">
      <span onClick={handleGuideClick}>
        <QuestionMark size="small" color="black" />
      </span>
      <DefaultStatItem>
        <StatLabel className="bold">전체</StatLabel>
        <LayoutStatCount>{data?.total ?? 0}</LayoutStatCount>
      </DefaultStatItem>
      <StatGroup selected={countFilter !== "ALL"}>
        <StatItem
          disabled={isDisabled(StatItemsType.NEW)}
          selected={countFilter === StatItemsType.NEW}
          label={
            <Box className="badge-wrapper">
              <NewBadge />
              신환
            </Box>
          }
          count={data?.new ?? 0}
          onClick={() => handleClick(StatItemsType.NEW)}
        />
        <StatDivision>|</StatDivision>
        <StatItem
          disabled={isDisabled(StatItemsType.ESTABLISHED)}
          selected={countFilter === StatItemsType.ESTABLISHED}
          label="구환"
          count={data?.established ?? 0}
          onClick={() => handleClick(StatItemsType.ESTABLISHED)}
        />
        <StatDivision>|</StatDivision>
        <StatItem
          disabled={isDisabled(StatItemsType.CANCELED)}
          selected={countFilter === StatItemsType.CANCELED}
          label="취소"
          count={data?.canceled ?? 0}
          color="red"
          onClick={() => handleClick(StatItemsType.CANCELED)}
        />
      </StatGroup>
      <StatGroup selected={countFilter !== "ALL"}>
        <StatItem
          disabled={isDisabled(StatItemsType.TODAY)}
          selected={countFilter === StatItemsType.TODAY}
          label={
            <Box className="badge-wrapper">
              <RegistrationTypeBadge />
              당일접수
            </Box>
          }
          count={data?.today ?? 0}
          onClick={() => handleClick(StatItemsType.TODAY)}
        />
        <StatDivision>|</StatDivision>
        <StatItem
          disabled={isDisabled(StatItemsType.APPOINTMENT)}
          selected={countFilter === StatItemsType.APPOINTMENT}
          label="예약접수"
          count={data?.appointment ?? 0}
          onClick={() => handleClick(StatItemsType.APPOINTMENT)}
        />
      </StatGroup>
    </Wrapper>
  );
};
