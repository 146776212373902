import {
  styled as muiStyled,
  Stack as MuiStack,
  TextField as _MuiTextField,
  Box as MuiBox,
  InputAdornment,
  IconButton as MuiIconButton,
  outlinedInputClasses,
} from "@mui/material";
import styled from "@emotion/styled";
import { TextInput as _TextInput } from "components/Form/TextInput";
import { ComboBox as _ComboBox } from "components/ComboBox";
import { ComboBox as _MultiComboBox } from "~/components/crm/ComboBox";
import { TextField as _TextField } from "~/components/TextField";
import _Button from "components/Button";
import { FindRecommenderInput as _FindRecommenderInput } from "./FindRecommenderInput";
import { DropdownList as _DropdownList } from "~/components/DropdownList";
import _QuillTextField from "~/components/quill/QuillTextField";
import _Phone from "components/Phone";

export const IconButton = muiStyled(MuiIconButton)(
  ({ theme }) => `
  position: absolute;
  right: 30px; 
  width: 22px;
  height: 22px;
  color: ${theme.palette.grey["700"]};
`
);

export const IconWrapper = styled("div")(
  ({ theme }) => `
  display: flex;
  margin-right: 20px;
  path,
  svg {
    fill: ${theme.palette.bluegrey["600"]};
  }
`
);

export const Phone = styled(_Phone)(
  ({ theme }) => `
  height: 34px;
  width: 100%;
  input {
    padding-left: 20px !important;
    font-size: 14px !important;
    &::placeholder {
      color: ${theme.palette.bluegrey["600"]};
      opacity: 1;
    }
  }
  .MuiOutlinedInput-root {
    height: 34px;
    font-size: 14px !important;
  }
  fieldset {
    border-color: ${theme.palette.layout.line};
  }
`
);

export const QuillTextField = styled(_QuillTextField)(
  ({ theme }) => `
   
  .ql-editor.ql-blank::before {
    color: ${theme.palette.bluegrey["600"]};
    font-style: normal;
  }
  .ql-editor {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }
`
);

export const DropdownList = styled(_DropdownList)(
  ({ theme }) => `
  height: 34px;
  input {
    padding-left: 20px !important;
    font-size: 14px !important;
    &::placeholder {
      color: ${theme.palette.bluegrey["600"]};
      opacity: 1;
    }
  }
  .MuiOutlinedInput-root {
    height: 34px;
    font-size: 14px !important;
  }
  fieldset {
    border-color: ${theme.palette.layout.line};
  }
`
);

export const InputEndAdornment = styled(InputAdornment)`
  p {
    font-size: 12px;
    margin-right: 8px;
  }
`;

export const FindRecommenderInput = styled(_FindRecommenderInput)(
  ({ theme }) => `
  width: 100%;
  input {
    font-size: 14px !important;
    &::placeholder {
      color: ${theme.palette.bluegrey["600"]};
      opacity: 1;
    }
  }
  .MuiOutlinedInput-root {
    height: 34px;
    padding: 0 20px !important;
    font-size: 14px !important;
  }
  fieldset {
    border-color: ${theme.palette.layout.line};
  }
`
);

export const Box = muiStyled(MuiBox)(
  ({ theme }) => `
  &.calendar-icon-wrapper{
    position:absolute;
    top:37px;
    right: 12px;
  }
  &.post-code-wrapper {
    &.hidden {
      display: none;
    }
  }
  &.memo-wrapper {
    width: 100%;
    .ql-toolbar {
      border-radius: 4px 4px 0 0;
    }
    .quill {
      pre {
        border-radius: 0 0 4px 4px;
      }
    }
  }
  &.uber-memo-wrapper {
    textarea {
      padding: 12px 15px;
      border-radius: 4px;
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      resize: vertical;
      min-height: 56px;
      box-sizing: border-box;
      border: 1px solid ${theme.palette.layout.line};
      &::placeholder {
        color: ${theme.palette.bluegrey["600"]};
        opacity: 1;
      }
    }
  }
`
);

export const MuiTextField = styled(_MuiTextField)(
  ({ theme }) => `
  height: 34px;
  input {
    padding: 0 20px !important;
    font-size: 14px !important;
    height: 34px;

    &::placeholder {
      color: ${theme.palette.bluegrey["600"]};
      opacity: 1;
    }
  }
  .${outlinedInputClasses.root} {
    height: 100%;

    &:hover {
      .${outlinedInputClasses.notchedOutline}{
        border-color: ${theme.palette.primary["main"]};
      }
    }
    
    .${outlinedInputClasses.notchedOutline}{
      border-width: 1px;
    }

  }
  fieldset {
    border-color: ${theme.palette.layout.line};
  }
`
);

export const Button = styled(_Button)(
  () => `
  &.chart-number-btn {
    position: absolute;
    top:2px;
    right:0;
  }
  &.submit-btn {
    height: 54px;
  }
`
);

export const TextField = styled(_TextField)(
  ({ theme }) => `
  &.birthday-last-number {
    input {
      padding: 0 0 0 16px !important;
    }
  }
  &.chart-number {
    .Mui-disabled {
      input {
        background:${theme.palette.bluegrey["200"]}; 
         &::placeholder {
          color: ${theme.palette.bluegrey["600"]};
          opacity: 1;
        }
      }
    }
  }
  .Mui-disabled {
    input {
      background:${theme.palette.bluegrey["200"]} !important; 
    }
  }
  height: 34px;
  input {
    padding: 0 20px !important;
    font-size: 14px !important;
    height: 34px !important;

    &::placeholder {
      color: ${theme.palette.bluegrey["600"]};
      opacity: 1;
    }
    &.Mui-disabled {
      background: ${theme.palette.bluegrey["200"]};
    }
  }
  .MuiOutlinedInput-root {
    height: 100%;
  }
  fieldset {
    border-color: ${theme.palette.layout.line};
  }
`
);

export const MultiComboBox = styled(_MultiComboBox)(
  ({ theme }) => `
  width: 100%;
  height: 34px;
  input {
    padding: 0 20px !important;
    font-size: 14px !important;
    height: 34px;

    &::placeholder {
      color: ${theme.palette.bluegrey["600"]};
      opacity: 1;
    }
  }
  .MuiOutlinedInput-root {
    height: 100% !important;
  }
  fieldset {
    border-color: ${theme.palette.layout.line};
  }
`
);

export const ComboBox = styled(_ComboBox)(
  ({ theme }) => `
  height: 34px;
  input {
    padding: 0 20px !important;
    font-size: 14px !important;
    &::placeholder {
      color: ${theme.palette.bluegrey["600"]};
      opacity: 1;
      
    }
  }
  fieldset {
    border-color: ${theme.palette.layout.line};
  }
`
);

export const TextInput = styled(_TextInput)(
  ({ theme }) => `
  
  input {
    text-overflow:ellipsis;
    &::placeholder {
      color: ${theme.palette.bluegrey["600"]};
      opacity: 1;
      
    }
  }
  fieldset {
    border-color: ${theme.palette.layout.line};
  }
`
);

export const Stack = muiStyled(MuiStack)(
  () => `
  font-size: 14px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;

  &.sms-enable-wrapper {
    label {
      position: relative;
      top:1px;
      cursor:pointer;

      &:first-of-type {
        margin-right: 6px;
      }
    }
  }
`
);

export const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Title = styled("h1")`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;

  &:not(&:first-of-type) {
    margin-top: 10px;
  }
`;

export const MemoViewer = styled("div")`
  * {
    margin: 0;
    font-size: 14px;
  }
`;

export const MemoEditor = styled("textarea")`
  width: 100%;
  box-sizing: border-box;
  border: 0;
  resize: none;
  font-family: inherit;
  &::placeholder {
    color: #adaebc;
  }
`;

export const LabelText = styled("span")`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;
