import { useState, useCallback, useEffect } from "react";
import { useApi } from "providers/ApiProvider";
import { getOptions } from "hooks/useFormSelect";
import { DropdownList as _DropdownList } from "components/DropdownList";
import { styled } from "@mui/material";

export const DropdownList = styled(_DropdownList)(
  ({ theme }) => `
  height: 34px;
  
  input {
    padding-left: 20px !important;
    font-size: 14px !important;
    &::placeholder {
      color: ${theme.palette.bluegrey["600"]};
      opacity: 1;
    }
  }
  .MuiOutlinedInput-root {
    border-radius: 4px !important;
    font-size: 14px !important;
  }
  fieldset {
    border-color: ${theme.palette.layout.line};
  }
`
);

type AssistSelectProps = {
  value: any;
  onChange: (value: any) => void;
};

export function AssistSelect({ value, onChange }: AssistSelectProps) {
  const { userApi } = useApi();
  const [assists, setAssists] = useState([]);

  const loadAssists = useCallback(async () => {
    const res = await userApi.getDuty("피부관리사,간호사,간호조무사");
    const payload = await res.data;
    payload.data && setAssists(payload.data);
  }, [userApi]);

  useEffect(() => {
    loadAssists();
  }, [loadAssists]);

  return (
    <>
      <DropdownList
        options={getOptions(assists ?? []).map((item: any) => {
          item.label = item.name;
          return item;
        })}
        value={assists.find((f: any) => f.id === value?.id)}
        onChange={(v) => {
          onChange(v ?? null);
        }}
        placeholder="어시스트를 선택하세요."
      />
    </>
  );
}
