import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
  ReactNode,
} from "react";
import { SnackBar } from "~/modals/common/Snackbar";

interface SnackbarOptions {
  leadingItems?: ReactNode;
  actionItems?: ReactNode;
  [key: string]: any;
}

type SnackbarType = "alert" | "unoblue";

interface Snackbar {
  open: (
    type: SnackbarType,
    message: ReactNode,
    options?: SnackbarOptions
  ) => void;
  close: () => void;
  alert: (message: ReactNode, options?: SnackbarOptions) => void;
  success: (message: ReactNode, options?: SnackbarOptions) => void;
}

interface SnackPackItem {
  message: ReactNode;
  key: number;
}

const SnackbarContext = createContext<Snackbar | null>(null);

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [snackPack, setSnackPack] = useState<SnackPackItem[]>([]);
  const [messageInfo, setMessageInfo] = useState<SnackPackItem | null>(null);
  const [show, setShow] = useState(false);
  const [type, setType] = useState<SnackbarType>("unoblue");
  const [options, setOptions] = useState<SnackbarOptions>({});

  const alert = useCallback((message: ReactNode, options?: SnackbarOptions) => {
    setType("alert");
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
    setOptions(options || {});
  }, []);

  const success = useCallback(
    (message: ReactNode, options?: SnackbarOptions) => {
      setType("unoblue");
      setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
      setOptions(options || {});
    },
    []
  );

  const open = useCallback(
    (type: SnackbarType, message: ReactNode, options?: SnackbarOptions) => {
      setType(type);
      setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
      setOptions(options || {});
    },
    []
  );

  const close = useCallback(() => {
    setShow(false);
    setMessageInfo(null);
  }, []);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setShow(true);
    } else if (snackPack.length && messageInfo && show) {
      setShow(false);
      setMessageInfo(null);
    }
  }, [snackPack, messageInfo, show]);

  const value = useMemo(
    () => ({
      open,
      close,
      alert,
      success,
    }),
    [alert, close, open, success]
  );

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      {show && (
        <SnackBar
          color={type}
          show={show}
          message={messageInfo ? messageInfo.message : ""}
          onClosed={close}
          leadingItems={options?.leadingItems}
          actionItems={options?.actionItems}
        />
      )}
    </SnackbarContext.Provider>
  );
};

export function useSnackbarContext() {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error(
      "useSnackbarContext must be used within a SnackbarProvider"
    );
  }
  return context;
}
