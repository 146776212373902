import { ReactNode } from "react";
import { useImperativeModal } from "ImperativeModalProvider";
import { CenterAlignedTAB } from "components/CenterAlignedTAB/CenterAlignedTAB";
import { CustomerInfoModal } from "modals/CustomerInfoModal";
import { Box, Stack, Button } from "./ActionPage.styled";

type ActionPageProps = {
  children: ReactNode;
  onCancel: () => void;
  trailingButton: null | ReactNode;
  title: ReactNode | null;
  customer: any;
};

export function ActionPage({
  title,
  trailingButton = null,
  children,
  onCancel,
  customer,
}: ActionPageProps) {
  const imperativeModal = useImperativeModal();

  const handleOpenCustomerPopup = async () => {
    await imperativeModal.open((close) => (
      <CustomerInfoModal open={true} customerData={customer} onClose={close} />
    ));
  };

  return (
    <Box className="action-page-container">
      <CenterAlignedTAB
        leadingButton={
          <Stack flexDirection={"row"} gap={"14px"}>
            <Box className="button-wrapper cancel-button-wrapper">
              <Button color="deepGrey" styled="text" onClick={onCancel}>
                취소
              </Button>
            </Box>
            {customer && (
              <Box className="button-wrapper customer-popup-button-wrapper">
                <Button
                  className="customer-popup-btn"
                  styled="outline"
                  size="s"
                  onClick={handleOpenCustomerPopup}
                >
                  고객정보 보기
                </Button>
              </Box>
            )}
          </Stack>
        }
        title={title}
        trailingButton={trailingButton}
      />
      <Box sx={{ overflow: "auto" }}>{children}</Box>
    </Box>
  );
}
