export type Types = "NEW" | "ESTABLISHED" | "CANCLED" | "TODAY" | "APPOINTMENT";

export const StatItemsType = {
  NEW: "NEW",
  ESTABLISHED: "ESTABLISHED",
  CANCELED: "CANCELED",
  TODAY: "TODAY",
  APPOINTMENT: "APPOINTMENT",
  NONE: "NONE",
  NAVER: "NAVER",

  getName(type: Types) {
    switch (type) {
      case StatItemsType.NEW:
        return "신환";
      case StatItemsType.ESTABLISHED:
        return "구환";
      case StatItemsType.CANCELED:
        return "취소";
      case StatItemsType.TODAY:
        return "당일접수";
      case StatItemsType.APPOINTMENT:
        return "예약접수";
      default:
        return type;
    }
  },
};
