import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";

export const myTheme = {
  palette: {
    text: {
      primary: "#282828",
      disabled: "#adaebc",
    },
    primary: {
      main: "#0060ff",
      deepblue: "#293142",
    },
    error: {
      main: "#f7685b",
      contrastText: "#fff",
    },
    neutral: {
      main: "#fff",
      contrastText: "#292929",
    },
    grey: {
      main: "#dee2ec",
      contrastText: "#fff",
      background: "#f5f5f8",
      700: "#2D2D2D",
      600: "#3A3A3A",
      500: "#BBBBBB",
      300: "#E6E6E6",
      200: "#F1F1F1",
    },
    unoblue: "#2C62F6",
    grey600: {
      main: "#3a3a3a",
      contrastText: "#fff",
    },
    bluegrey: {
      700: "#4A4E70",
      600: "#A1B1CA",
      500: "#D7E3F1",
      300: "#E6EEF8",
      200: "#F3F8FF",
      100: "#F9FBFF",
    },
    action: {
      disabled: "#efeff4",
    },
    layout: {
      line: "#dee2ec",
      background: "#edeff1",
    },
  },
  typography: {
    fontFamily: [
      "Noto Sans KR",
      "맑은 고딕",
      "malgun gothic",
      "AppleGothicNeoSD",
      "Apple SD 산돌고딕 Neo",
      "Microsoft NeoGothic",
      "Droid sans",
      "sans-serif",
    ].join(","),
  },
};

export const globalTheme = createTheme(myTheme);

export const theme = createTheme(globalTheme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: "initial",
          fontWeight: 600,
          fontSize: "14px",
          minWidth: "auto",
          boxShadow: "none !important",
          borderRadius: "4px",
          padding: "0 16px",
        },
        outlined: {
          border: "1px solid currentColor",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: "0",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: "100%",
          "&:hover": {
            "&& fieldset": {
              borderColor: globalTheme.palette.primary.main,
            },
          },
          "&.Mui-disabled select, &.Mui-disabled input": {
            background: "#fbfbfd",
            WebkitTextFillColor: "#adaebc",
          },
        },
        notchedOutline: {
          borderColor: "#efeff4",
          transition: "border-color 0.1s",
          borderWidth: "1px !important",
        },
      },
    },
  },
});

export const ThemeProvider = ({ children }: any) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};
