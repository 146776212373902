import { useState, useCallback, useEffect, useMemo } from "react";
import { useSnackbarContext } from "~/SnackbarProvider_v2";
import { LabelWrapper, Label } from "components/Form/Label";
import { AppointmentTypeButton } from "pages/AppointmentPage/form/AppointmentTypeButton";
import { DateInput } from "components/Form/DateInput";
import { AssistSelect } from "components/Form/select/AssistSelect";
import { CounselorSelect } from "components/Form/select/CounselorSelect";
import { DoctorSelect } from "components/Form/select/DoctorSelect";
import { StartHourSelect } from "components/Form/select/TimeSelect";
import { SurgerySelect } from "components/Form/select/SurgerySelect";
import Icon from "components/Icon";
import { format, differenceInMinutes, addMinutes } from "date-fns";
import DepartmentSelect from "./DepartmentSelect";
import CreatedSelect from "./CreatedSelect";
import AcquisitionChannelSelect from "./AcquisitionChannelSelect";
import { SMSRadio, SMSListCheckbox } from "./SMSRadio";
import { useApi } from "providers/ApiProvider";
import { useUser } from "providers/UserProvider";
import {
  Button,
  Wrapper,
  MuiTextField,
  QuillTextField,
  Boilerplate,
  Box,
} from "./Form.styled";
import { AddRemainingSurgeryButton } from "./AddRemainingSurgeryButton";
import { AppointmentStatus, AppointmentCategory } from "type/appointmentStatus";
import { TimeDurationField } from "components/TimeDurationField/TimeDurationField";
import { minutesToTime, parseTime, toMinutes } from "utils/timeUtil";
import { SmsScheduleType, SmsSituation, SmsType } from "./smsRule";
import { useImperativeModal } from "ImperativeModalProvider";
import { ImmediateSmsConfirmModal } from "modals/ImmediateSmsConfirmModal";
import { NaverLinkedConfirmModal } from "modals/NaverLinkedConfirmModal";
import { LinkedChannelType } from "~/type/linkedChannelType";
import { externalUpdateConflictErrorMessage } from "~/utils/linkedChannelUtil";

type SmsRuleData = {
  id: number;
  title: string;
  contents: string;
  visible: boolean;
  callerNumberId: number;
  smsType: SmsType;
  smsSituation: SmsSituation;
  smsScheduleType: SmsScheduleType;
  daysOffset: number;
  scheduleTime: string;
  callerNumber: {
    clinicId: number;
    number: string;
    visible: boolean;
    deletedAt: string;
    comment: string;
  };
  image: {
    id: number;
    url: string;
  };
  departments: {
    id: number;
    name: string;
    category: {
      id: number;
      name: string;
    };
  }[];
  treatmentItem: {
    id: number;
    name: string;
    category: {
      id: number;
      name: string;
    };
  }[];
  allDepartments: boolean;
  reserveCount: number;
};

type FormProps = {
  appointment?: any;
  customer?: any;
  onSaveCallback: () => void;
};

export function Form({ appointment, customer, onSaveCallback }: FormProps) {
  const imperativeModal = useImperativeModal();
  const { user } = useUser();
  const [date, setDate] = useState(
    format(new Date(appointment?.date ?? new Date()), "yyyy-MM-dd")
  );
  const [category, setCategory] = useState(
    appointment?.category ?? AppointmentCategory.consulting
  );
  const [startHour, setStartHour] = useState(
    appointment
      ? { value: appointment?.startHour }
      : { value: user.clinic.workStart }
  );
  let defaultTime = minutesToTime(user.clinic.appointmentTimeUnit);
  if (appointment) {
    const durationMinutes = differenceInMinutes(
      new Date(appointment.endAt),
      new Date(appointment.startAt)
    );
    defaultTime = minutesToTime(durationMinutes);
  }
  const [time, setTime] = useState(defaultTime);
  const [acquisitionChannel, setAcquisitionChannel] = useState(
    appointment?.acquisitionChannel
  );
  const [assist, setAssist] = useState(appointment?.assist);
  const [counselor, setCounselor] = useState(
    appointment?.counselor ?? customer.counselor
  );
  const [doctor, setDoctor] = useState(appointment?.doctor ?? customer.doctor);
  const [department, setDepartment] = useState(appointment?.department ?? null);
  const [surgery, setSurgery] = useState(
    appointment?.treatmentItems
      ? Array.isArray(appointment?.treatmentItems) &&
        appointment?.treatmentItems.length === 0
        ? [{}]
        : appointment?.treatmentItems
      : [{}]
  );
  const [sendSms, setSendSms] = useState(true);
  const [memo, setMemo] = useState(appointment?.memo ?? "");
  const [creator, setCreator] = useState(
    appointment?.creator || {
      id: user.id,
      name: user.name,
      status: user.status,
    }
  );
  const [loading, setLoading] = useState(false);
  const [memoBoilerplateList, setMemoBoilerplateList]: any = useState([]);
  const [smsRuleData, setSMSRuleData] = useState([]);
  const [smsIds, setSmsIds]: any = useState([]);
  const { clinicsApi, smsApi, filesApi, appointmentApi } = useApi();
  const snackbar = useSnackbarContext();
  const smsRules = useMemo(() => smsRuleData ?? [], [smsRuleData]);
  const [clinicConfig, setClinicConfig] = useState<any>([]);

  const useRepeatAcquistion = useMemo(() => {
    const find = clinicConfig.find(
      (item: { codeName: string }) => item.codeName === "useRepeat"
    );
    return find?.codeValue ? JSON.parse(find?.codeValue || "false") : false;
  }, [clinicConfig]);

  const savedAcquisitionChannelId =
    useRepeatAcquistion && customer.acquisitionChannels.length === 1
      ? customer.acquisitionChannels[0]?.id
      : null;

  useEffect(() => {
    if (savedAcquisitionChannelId) {
      setAcquisitionChannel(
        appointment?.id
          ? appointment?.acquisitionChannel || null
          : savedAcquisitionChannelId
      );
    }
  }, [savedAcquisitionChannelId]);

  const loadClinicConfig = useCallback(async () => {
    const res = await clinicsApi.getConfigs();
    const payload = await res.data;
    setClinicConfig(payload?.data);
  }, [clinicsApi]);

  useEffect(() => {
    loadClinicConfig();
  }, [loadClinicConfig]);

  const loadSMSRule = useCallback(async () => {
    const res = await smsApi.getSmsNotifications();
    const payload = await res.data;
    setSMSRuleData(payload?.data);
  }, [smsApi]);

  useEffect(() => {
    loadSMSRule();
  }, [loadSMSRule]);

  useEffect(() => {
    smsRules.length && onCheckAllSMS(smsRules);
  }, [smsRules, department, date, startHour, sendSms]);

  const onCheckAllSMS = (smsRules: SmsRuleData[]) => {
    setSmsIds(filterSMS(smsRules).map((v) => v.id));
  };

  const loadMemoBoilerPlate = useCallback(async () => {
    const res = await filesApi.getMemoBoilerPlates("appointment");
    const payload = await res.data;

    setMemoBoilerplateList(payload?.data);
  }, [filesApi]);

  useEffect(() => {
    loadMemoBoilerPlate();
  }, [loadMemoBoilerPlate]);

  const validate = useCallback(() => {
    if (!(date && startHour && time && department && creator)) {
      throw new Error("필수항목을 모두 입력해주세요.");
    }
    if (time.hours === 0 && time.minutes === 0) {
      throw new Error("예상 소요시간을 확인해주세요.");
    }
    if (
      toMinutes(parseTime(startHour.value)) + toMinutes(time) >
      toMinutes(parseTime(user.clinic.workEnd))
    ) {
      throw new Error("진료시간을 초과하였습니다.");
    }
  }, [date, department, startHour, time, user.clinic.workEnd, creator]);

  const filterSMS = (list: SmsRuleData[]) => {
    //부서와 시간에 따라 보낼 수 있는 sms를 필터링
    const departmentId = department?.id;
    const isValidDate = date && /^\d{4}-\d{2}-\d{2}$/.test(date);

    if (!isValidDate || !startHour?.value || !departmentId) {
      return [];
    }

    const scheduledAt = new Date(date);
    if (isNaN(scheduledAt.getTime())) {
      return [];
    }

    const startAt = new Date(`${date} ${startHour.value}`);
    const today = new Date();
    if (today >= startAt) {
      return [];
    }

    return list
      .filter((smsRule) => {
        const smsSendDate = getSmsScheduleDate(smsRule, scheduledAt);
        return (
          smsRule.smsScheduleType === "immediate" ||
          format(today, `yyyy-MM-dd'T'HH:mm`) <= smsSendDate
        );
      })
      .filter((smsRule) =>
        smsRule.departments.some((d) => d.id === departmentId)
      );
  };

  const getSmsScheduleDate = (
    smsRule: SmsRuleData,
    scheduledAt: Date
  ): string => {
    const today = new Date();

    if (smsRule.scheduleTime === null || smsRule.daysOffset === null) {
      return format(today, `yyyy-MM-dd'T'HH:mm`);
    }

    const offsetDate = new Date(scheduledAt);
    offsetDate.setDate(offsetDate.getDate() + smsRule.daysOffset);

    return format(offsetDate, `yyyy-MM-dd'T'${smsRule.scheduleTime}`);
  };

  const onSave = useCallback(async () => {
    setLoading(true);
    try {
      validate();
    } catch (e: any) {
      setLoading(false);
      snackbar.alert(e.message);
      return;
    }

    try {
      const startAt = new Date(`${date} ${startHour.value}`);
      const endAt = addMinutes(startAt, toMinutes(time));

      const data: any = {
        acquisitionChannelId:
          acquisitionChannel instanceof Object
            ? acquisitionChannel?.id
            : acquisitionChannel,
        category: category,
        assistId: assist?.id,
        counselorId: counselor?.id,
        createdBy: creator?.id,
        customerId: customer.id,
        departmentId: department?.id,
        doctorId: doctor?.id,
        memo: memo ? memo.replace(/(?:\r\n|\r|\n)/g, "<br />") : undefined,
        date: date,
        startAt: format(startAt, "yyyy-MM-dd'T'HH:mm"),
        endAt: format(endAt, "yyyy-MM-dd'T'HH:mm"),
        status: AppointmentStatus.scheduled,
        treatmentItemIds: surgery.map((v: any) => v?.id).filter((v: any) => v),
      };

      if (appointment?.id) {
        const formDataStartAt = appointment.startAt;
        if (formDataStartAt !== data.startAt) {
          if (
            appointment.externalLinkedChannel === "NAVER" &&
            appointment.appointmentExternalRequestId !== null
          ) {
            const response = await clinicsApi.getExternalLinkedChannel();
            const payload = await response.data;
            const naverChannel = payload.data.find(
              (item: { channel: LinkedChannelType }) => item.channel === "NAVER"
            );
            if (naverChannel && naverChannel.isLinked) {
              const confirmed = await imperativeModal.open((close) => (
                <NaverLinkedConfirmModal
                  onConfirm={() => close(true)}
                  onCancel={() => close(false)}
                />
              ));
              if (!confirmed) {
                return;
              }
            }
          }
        }

        if (
          formDataStartAt !== data.startAt ||
          appointment.department.id !== data.departmentId
        ) {
          const immediateSms = filterSMS(
            smsRules.filter((f: any) => f.smsScheduleType === "immediate")
          ).map((v) => v.id);

          const sendSmsItems = smsIds.filter(
            (f: number) => immediateSms.indexOf(f) > -1
          );
          if (sendSmsItems.length > 0) {
            const confirmed = await imperativeModal.open((close) => (
              <ImmediateSmsConfirmModal
                smsRules={smsRules.filter(
                  (f: any) => sendSmsItems.indexOf(f.id) > -1
                )}
                onConfirm={() => close(true)}
                onCancel={() => close(false)}
              />
            ));

            if (confirmed) {
              data.sendSms = smsIds;
            } else {
              const reservedSms = smsIds.filter(
                (f: number) => immediateSms.indexOf(f) === -1
              );
              data.sendSms = reservedSms;
            }
          }
        }

        try {
          await appointmentApi.editAppointment(appointment?.id, data);
          snackbar.success("예약을 변경했습니다.");
        } catch (e: any) {
          if (e.code === 409 && e.message === "EXTERNAL UPDATE CONFLICT") {
            return snackbar.alert(externalUpdateConflictErrorMessage);
          }
          return snackbar.alert("예약 변경에 실패했습니다.");
        }
      } else {
        if (sendSms && smsIds.length) {
          data.sendSms = smsIds;
        }
        await appointmentApi.createAppointment(data);
        if (data.sendSms) {
          snackbar.success(
            "예약 및 문자를 생성했습니다. 문자설정은 웹CRM에서 가능합니다."
          );
        } else {
          snackbar.success("예약을 등록했습니다.");
        }
      }
      onSaveCallback();
    } catch (e) {
      snackbar.alert("알 수 없는 에러가 발생했습니다.");
      throw e;
    } finally {
      setLoading(false);
    }
  }, [
    validate,
    date,
    startHour?.value,
    time,
    acquisitionChannel,
    category,
    assist?.id,
    counselor?.id,
    creator?.id,
    customer.id,
    department?.id,
    doctor?.id,
    memo,
    surgery,
    appointment?.id,
    appointment?.smsSent,
    appointment?.scheduledOn,
    appointment?.startHour,
    onSaveCallback,
    smsIds,
    sendSms,
    appointmentApi,
    clinicsApi,
  ]);

  const sessionDurationOptions = useMemo(() => {
    const clinic = user.clinic;
    if (!clinic) return [];

    const start = parseTime(clinic.workStart);
    const end = parseTime(clinic.workEnd);
    const interval = clinic.appointmentTimeUnit;

    const totalMinutes =
      end.hours * 60 + end.minutes - (start.hours * 60 + start.minutes);

    const hours = Math.floor(totalMinutes / 60);

    const times = (function () {
      const candidates = [];
      for (let i = 0; i <= hours; i++) {
        if (i === 0) {
          switch (interval) {
            case 10:
              [10, 20, 30, 40, 50].forEach((min) =>
                candidates.push({ hours: i, minutes: min })
              );
              break;
            case 30:
              candidates.push({ hours: i, minutes: 30 });
              break;
            case 60:
            default:
              break;
          }
        } else {
          [0, 30].forEach((min) => candidates.push({ hours: i, minutes: min }));
        }
      }
      return candidates;
    })();

    return times.filter((t) => t.hours * 60 + t.minutes < totalMinutes);
  }, [user]);

  return (
    <Wrapper>
      <Label text="예약종류" isRequire>
        <AppointmentTypeButton value={category} onChange={setCategory} />
      </Label>
      <LabelWrapper column={3}>
        <Label text="일자" isRequire>
          <>
            <DateInput
              value={date}
              onChange={(v) => {
                setDate(format(new Date(v), "yyyy-MM-dd"));
              }}
              InputComponent={MuiTextField}
              inputFormat="YYYY-MM-DD"
              mask="____-__-__"
              placeholder="일자를 선택하세요."
              editInput
            />
            <Box component={"span"} className="calendar-icon-wrapper">
              <Icon variant="calendar" />
            </Box>
          </>
        </Label>
        <Label text="방문시간" isRequire>
          <StartHourSelect
            value={startHour?.value ?? ""}
            onChange={(v) => setStartHour(v)}
            date={date}
            department={department}
            disableClearable={true}
          />
        </Label>
        <Label text="예상 소요시간" isRequire>
          <TimeDurationField
            sx={{
              width: "100%",
            }}
            value={time}
            onChange={setTime}
            options={sessionDurationOptions}
          />
        </Label>
      </LabelWrapper>
      <LabelWrapper column={3}>
        <Label text="예약부서" isRequire>
          <DepartmentSelect
            value={department}
            disableClearable={true}
            onChange={(v: any) => {
              setDepartment(v);
            }}
          />
        </Label>
        <Label text="내원경로">
          <AcquisitionChannelSelect
            value={acquisitionChannel || ""}
            onChange={(v: any) => {
              setAcquisitionChannel(v);
            }}
          />
        </Label>
        <Label text="작성자" isRequire>
          <CreatedSelect
            value={creator}
            disableClearable={true}
            onChange={(v: any) => {
              setCreator(v);
            }}
          />
        </Label>
      </LabelWrapper>
      <LabelWrapper column={3}>
        <Label text="의사">
          <DoctorSelect value={doctor} onChange={setDoctor} />
        </Label>
        <Label text="상담사">
          <CounselorSelect value={counselor} onChange={setCounselor} />
        </Label>
        <Label text="어시스트">
          <AssistSelect value={assist} onChange={setAssist} />
        </Label>
      </LabelWrapper>
      {category === AppointmentCategory.surgery && (
        <AddRemainingSurgeryButton
          customerId={customer.id}
          onAddSurgeries={(list: any) => {
            const surgeries = [
              ...surgery.filter((item: any) => Object.keys(item).length > 0),
              ...list.map((v: any) => v.treatmentItem),
            ];
            setSurgery(surgeries);
          }}
        />
      )}
      <SurgerySelect value={surgery} onChange={setSurgery} />
      <LabelWrapper column={1}>
        <Label text="예약 메모">
          <>
            {memoBoilerplateList?.length > 0 && (
              <Boilerplate>
                <p>자주 쓰는 상용구</p>
                {memoBoilerplateList.slice(0, 5).map((v: any, i: number) => (
                  <button
                    key={i}
                    onClick={() =>
                      setMemo((memo === "<p><br></p>" ? "" : memo) + v.contents)
                    }
                  >
                    {v.title}
                  </button>
                ))}
              </Boilerplate>
            )}
            <QuillTextField
              value={memo ?? ""}
              onChange={(v: any) => setMemo(v)}
              placeholder="메모를 입력하세요."
            />
          </>
        </Label>
      </LabelWrapper>
      {!appointment?.id && Boolean(filterSMS(smsRules).length) && (
        <LabelWrapper column={1}>
          <SMSRadio
            data={filterSMS(smsRules)}
            value={sendSms}
            onChange={(v) => {
              setSendSms(v);
            }}
          />
          {sendSms && (
            <SMSListCheckbox
              options={filterSMS(smsRules)}
              value={smsIds}
              onChange={(v) => {
                setSmsIds(v);
              }}
            />
          )}
        </LabelWrapper>
      )}
      <Button
        styled="fill"
        color="primary"
        onClick={onSave}
        sx={{
          position: "sticky",
          bottom: "0",
          marginTop: "auto",
        }}
        disabled={loading}
      >
        {appointment?.id ? "수정완료" : "등록"}
      </Button>
    </Wrapper>
  );
}
