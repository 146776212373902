import {
  Box as MuiBox,
  styled,
  iconButtonClasses,
  Toolbar as MuiToolbar,
} from "@mui/material";
import { DefaultModal as _DefaultModal } from "components/Modal/DefaultModal";

export const Box = styled(MuiBox)(
  ({ theme }) => `
  &.guide-text{
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: right;
    display: flex;
    white-space: nowrap;
    color: ${theme.palette.primary["main"]};
    gap: 6px;
    align-items:center;
    cursor: pointer;
  }
  &.absolute {
    position: absolute;
  }
  &.pos-right {
    right: 0;
    &.r-21 {
      right: 21px;
    }
  }
  &.penchart-guide-text {
    span {
      font-size: 13px;
      padding-left: 9px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      display: block;
      position: relative;

      & + span {
        margin-top: 20px;
      }
      &::before {
        content: '';
        display: block;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: ${theme.palette.grey["700"]};
        position: absolute;
        left: 0;
        top: 8px;
      }
    }
  }
`
);

export const DefaultModal = styled(_DefaultModal)(
  ({ theme }) => `
  .MuiPaper-root {
    max-width: 340px;
   
  }

  .MuiDialogContent-root {
    padding-bottom: 48px;
  }

  .MuiDialogTitle-root {
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: ${theme.palette.grey["700"]};
  }

  .${iconButtonClasses.root}{
    margin-left: auto;
  }

  .text {
    font-size: 13px;
    padding-left: 9px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    display: block;
    position: relative;

    &.no-dot {
      padding-left: 0;
      &::before {
        display: none;
      }
    }

    & + span {
      margin-top: 20px;
    }

    &::before {
      content: '';
      display: block;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background: ${theme.palette.grey["700"]};
      position: absolute;
      left: 0;
      top: 8px;
    }
  }
`
);

export const Toolbar = styled(MuiToolbar)`
  height: 64px;
`;
