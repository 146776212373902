import { useState, useCallback, useRef, useEffect } from "react";
import { Dayjs } from "dayjs";
import { useSnackbarContext } from "~/SnackbarProvider_v2";
import { LabelWrapper, Label } from "components/Form/Label";
import { DateInput } from "components/Form/DateInput";
import { Radio } from "components/Form/Radio";
import DaumPostcode from "react-daum-postcode";
import { format, parse } from "date-fns";
import { ClickAwayListener } from "@mui/base";
import { useApi } from "providers/ApiProvider";
import { stripEmpty } from "~/utils/objUtil";
import { checkEmailFormat } from "~/utils/filters";
import { readFileMime } from "~/utils/fileUtil";
import {
  IconWrapper,
  Wrapper,
  Title,
  Stack,
  ComboBox,
  MultiComboBox,
  TextField,
  Button,
  Box,
  MuiTextField,
  FindRecommenderInput,
  InputEndAdornment,
  DropdownList,
  QuillTextField,
  Phone,
  IconButton,
  LabelText,
} from "./Form.styled";
import Icon from "components/Icon";
import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "~/components/Tabs";
import { Tooltip } from "components/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CloseIcon from "@mui/icons-material/Close";
import { Checkbox } from "~/components/Checkbox/Checkbox";
import FamilyInfo from "./FamilyInfo";
import { bloodTypeList, marriedList } from "./options";
import { useClinic } from "providers/ClinicProvider";
import {
  useCounselors,
  useDoctors,
  useFavorites,
  useLevels,
  useAcquisition,
  useComplaint,
  useRegion,
  useJob,
} from "./hooks";

type FormProps = {
  customer: any;
  profileImage: any;
  onSaveCallback: () => void;
};

type IgnoreKeys = string[];

export const convertEmptyStringsToNull = (
  obj: Record<string, any>,
  ignoreKeys: IgnoreKeys = []
) => {
  const result = { ...obj };

  for (const key in result) {
    if (Object.prototype.hasOwnProperty.call(result, key)) {
      const value = result[key];

      // ignoreKeys에 포함된 키는 변환하지 않음
      if (ignoreKeys.includes(key)) {
        continue;
      }

      // 문자열인 경우만 처리하고 나머지는 그대로 둠
      if (typeof value === "string" && value.trim() === "") {
        result[key] = null;
      }
    }
  }

  return result;
};

export function Form({ customer, onSaveCallback, profileImage }: FormProps) {
  const { customersApi, clinicsApi, filesApi, imageApi } = useApi();
  const snackbar = useSnackbarContext();
  const { clinic } = useClinic();
  const ssnHead2Ref = useRef();
  const [tab, setTab] = useState("0");
  const [type, setType] = useState(customer?.type ?? "domestic");
  const [autoChartNo, setAutoChartNo] = useState(true);
  const [chartNo, setChartNo] = useState(customer?.chartNo ?? "");
  const [name, setName] = useState(customer?.name ?? "");
  const [phoneNumber, setPhoneNumber] = useState(customer?.phoneNumber ?? "");
  const [birthday, setBirthday] = useState(customer?.birthday ?? null);
  const [married, setMarried] = useState(customer?.married ?? null);
  const [sex, setSex] = useState(customer?.sex ?? "female");
  const [smsEnabled, setSmsEnabled] = useState(customer?.smsEnabled ?? true);
  const [eventSmsEnabled, setEventSmsEnabled] = useState(
    customer?.eventSmsEnabled ?? true
  );
  const [job, setJob] = useState(customer?.job);
  const [level, setLevel] = useState(customer?.level);
  const [address, setAddress] = useState(customer?.address ?? "");
  const [addressDetail, setAddressDetail] = useState(
    customer?.addressDetail ?? ""
  );
  const [complaint, setComplaint] = useState(customer?.complaint);
  const [region, setRegion] = useState(customer?.region);
  const [memo, setMemo] = useState(customer?.memo ?? "");
  const [additionalInfo1, setAdditionalInfo1] = useState(
    customer?.additionalInfo1 ?? ""
  );
  const [additionalInfo2, setAdditionalInfo2] = useState(
    customer?.additionalInfo2 ?? ""
  );
  const [additionalInfo3, setAdditionalInfo3] = useState(
    customer?.additionalInfo3 ?? ""
  );
  const [isSaving, setIsSaving] = useState(false);
  const [counselor, setCounselor]: any = useState(customer?.counselor);
  const [doctor, setDoctor]: any = useState(customer?.doctor);
  const [favorites, setFavorites] = useState(customer?.favorites || []);
  const [bloodType, setBloodType] = useState(customer?.bloodType || null);
  const [familyRelationships, setFamilyRelationships] = useState(
    customer?.familyRelationships || []
  );
  const [acquisitionChannel, setAcquisitionChannel] = useState(
    customer?.acquisitionChannels || []
  );
  const [ssnHead1, setSsnHead1] = useState(
    customer?.ssnHead?.length === 7
      ? customer?.ssnHead.slice(0, 6)
      : customer?.ssnHead || ""
  );
  const [ssnHead2, setSsnHead2] = useState(
    customer?.ssnHead?.length === 7 ? customer?.ssnHead.slice(-1) : ""
  );
  const [divisionNumber, setDivisionNumber] = useState(
    customer?.divisionNumber || ""
  );
  const [height, setHeight] = useState(customer?.height || "");
  const [weight, setWeight] = useState(customer?.weight || "");
  const [recommender, setRecommender] = useState({
    recommenderId: customer?.recommenderId || null,
    recommenderName: customer?.recommenderName || null,
    recommender: customer?.recommender || null,
  });
  const [phoneNumber2, setPhoneNumber2] = useState(
    customer?.phoneNumber2 || ""
  );
  const [uberMemo, setUberMemo] = useState(customer?.uberMemo || "");
  const [email, setEmail] = useState(customer?.email || "");
  const { counselorOptions } = useCounselors(counselor);
  const { doctorOptions } = useDoctors(doctor);
  const { favoriteOptions } = useFavorites(favorites, setFavorites);
  const { levelOptions } = useLevels(level);
  const { acquisitionChannelOptions } = useAcquisition(
    acquisitionChannel,
    setAcquisitionChannel
  );
  const { regionOptions } = useRegion(region);
  const { complaintOptions } = useComplaint(complaint);
  const { jobOptions } = useJob(job);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openDivisionNumberTooltip, setOpenDivisionNumberTooltip] =
    useState(false);
  const [isOpenPost, setIsOpenPost] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!customer?.sex) {
      getDefaultSex();
    }
  }, [customer]);

  const getDefaultSex = async () => {
    const response = await clinicsApi.getConfigs({
      groupName: "customer",
    });
    const { data } = await response.data;
    const defaultValue = data.find(
      (item: any) => item.codeName === "sex"
    )?.codeValue;
    if (defaultValue) {
      setSex(defaultValue);
    }
  };

  const onChangeTab = (_: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const validateNumber = useCallback(
    (value: string) => {
      return /^[0-9]*$/.test(value);
    },
    [ssnHead1, height, weight, divisionNumber]
  );

  const onChangeOpenPost = () => {
    setIsOpenPost(!isOpenPost);
  };

  const onCompletePost = (data: any) => {
    let fullAddr =
      data.userSelectedType === "R" ? data.roadAddress : data.jibunAddress;
    let extraAddr = "";

    if (data.userSelectedType === "R") {
      if (data.bname !== "") {
        extraAddr += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddr +=
          extraAddr !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddr += extraAddr !== "" ? ` (${extraAddr})` : "";
    }

    setAddress(fullAddr);
    setIsOpenPost(false);
  };

  const handleChangeSsnHead1 = (value: string) => {
    if (!validateNumber(value)) {
      return snackbar.alert("숫자만 입력하세요.");
    }
    setSsnHead1(value);

    // 6자리 입력 완료 시
    if (value.length === 6) {
      (ssnHead2Ref.current as any)?.focus();
      const newBirthday = parse(value, "yyMMdd", new Date());

      if (isNaN(newBirthday.getTime())) {
        return;
      }

      if (newBirthday > new Date()) {
        newBirthday.setFullYear(newBirthday.getFullYear() - 100);
      }

      // birthday가 비어있는 경우에만 업데이트
      if (!birthday) {
        setBirthday(newBirthday);
      }
    }
  };

  const handleChangeDivisionNumber = (value: string) => {
    if (!validateNumber(value)) {
      return snackbar.alert("숫자만 입력하세요.");
    }

    setDivisionNumber(value);
  };

  const validate = () => {
    if (email && !checkEmailFormat(email)) {
      throw new Error("이메일 주소를 확인하세요.");
    }

    // 수정시에만 차트번호 체크
    if (customer && (!chartNo || chartNo.trim() === "")) {
      throw new Error("차트번호는 필수 입력사항입니다.");
    }

    if (divisionNumber) {
      //청구번호 7자리 숫자인지 확인
      if (divisionNumber.length !== 7) {
        throw new Error("청구번호는 7자리 숫자를 입력해 주세요.");
      }

      //청구번호 7자리 숫자인데, 주민번호 앞자리가 없거나, 6자리가 아닌 경우
      const ssn = (ssnHead1 || "").trim();
      if (ssn.length !== 6) {
        throw new Error("주민등록번호 앞자리를 입력해 주세요.");
      }
    }

    if (uberMemo && !name) {
      throw new Error("고객정보 청구 연동을 위해서는 이름을 입력하세요.");
    }
  };

  const getPresignedData = async (fileExtension: string) => {
    const response = await filesApi.createImage(fileExtension);
    return await response.data;
  };

  const handleToggleTooltip = () => {
    setOpenTooltip(!openTooltip);
  };

  const handleToggleDivisionNumberTooltip = () => {
    setOpenDivisionNumberTooltip(!openDivisionNumberTooltip);
  };

  const handleResetBirthday = () => {
    setBirthday(null);
  };

  const handleChangeTextarea = (event: any) => {
    if (textAreaRef.current) {
      setUberMemo(event.target.value);
    }
  };

  const handleChangeBirthday = (date: Dayjs | null): void => {
    if (!date) {
      setBirthday(null);
      return;
    }

    const dateValue = date.toDate();
    if (dateValue > new Date()) {
      dateValue.setFullYear(dateValue.getFullYear() - 100);
    }

    // 생년월일 변경 시
    const formattedDate = format(dateValue, "yyyyMMdd");
    setBirthday(dateValue);

    // 주민번호 앞자리가 비어있는 경우 자동으로 채워줌
    if (!ssnHead1) {
      const ssnValue = formattedDate.slice(2, 8);
      setSsnHead1(ssnValue);
    }
  };

  const onSave = useCallback(async () => {
    try {
      validate();
    } catch (e: any) {
      snackbar.alert(e.message);
      return;
    }

    let profileImageId: any = profileImage?.id || null;

    if (profileImageId === null && profileImage) {
      const ext =
        profileImage.type === undefined || profileImage.type === ""
          ? await readFileMime(profileImage)
          : profileImage.type;

      const {
        data: { presignedUrl, token },
      } = await getPresignedData(ext);
      await imageApi.uploadImage(presignedUrl, profileImage);
      const resp = await filesApi.uploadImages(token);
      const result = await resp.data;
      profileImageId = result.data.id;
    }

    try {
      const data: any = {
        ...recommender,
        type,
        name,
        phoneNumber: phoneNumber.replace(/-/gi, ""),
        phoneNumber2: phoneNumber2.replace(/-/gi, ""),
        birthday: birthday && format(new Date(birthday), "yyyy-MM-dd"),
        sex,
        smsEnabled,
        eventSmsEnabled,
        jobId: job?.id,
        levelId: level?.id,
        complaintId: complaint?.id,
        regionId: region?.id,
        memo,
        additionalInfo1,
        additionalInfo2,
        additionalInfo3,
        doctorId: doctor?.id || null,
        counselorId: counselor?.id || null,
        acquisitionChannels: acquisitionChannel.map((item: any) => item.id),
        favorites: favorites.map((item: any) => item.id),
        acquisitionChannel,
        ...recommender,
        height,
        weight,
        email,
        married,
        address,
        addressDetail,
        profileImageId,
        bloodType,
        familyRelationships: familyRelationships
          .filter((item: { id: null | string }) => item.id)
          .map((item: any) => item.id),
        divisionNumber,
      };

      if (clinic.isUberSync === true) {
        data.uberMemo = uberMemo;
      }

      const trimmedSsnHead1 = (ssnHead1 || "").trim();
      const ssnHead = trimmedSsnHead1
        ? trimmedSsnHead1 + (ssnHead2 || "").trim()
        : "";
      data.ssnHead = ssnHead;

      if (!autoChartNo) {
        data.chartNo = chartNo;
      }

      let saveData;
      if (customer) {
        saveData = convertEmptyStringsToNull(data, ["uberMemo"]);
      } else {
        saveData = stripEmpty(data, {
          emptyString: true,
          emptyArray: true,
        });
      }

      if (customer) {
        await customersApi.editCustomer(customer.id, saveData);
        snackbar.success("수정되었습니다.");
      } else {
        await customersApi.createCustomer(saveData);
        snackbar.success("등록되었습니다.");
      }
      onSaveCallback();
    } catch (e: any) {
      snackbar.alert(e.message || "error");
      setIsSaving(false);
      throw e;
    }
  }, [
    customersApi,
    autoChartNo,
    birthday,
    chartNo,
    clinic,
    complaint,
    counselor,
    customer,
    doctor,
    eventSmsEnabled,
    favorites,
    job,
    level,
    memo,
    name,
    onSaveCallback,
    phoneNumber,
    phoneNumber2,
    profileImage,
    region,
    sex,
    snackbar,
    smsEnabled,
    type,
    additionalInfo1,
    additionalInfo2,
    additionalInfo3,
    ssnHead1,
    ssnHead2,
    recommender,
    acquisitionChannel,
    height,
    weight,
    email,
    married,
    address,
    addressDetail,
    bloodType,
    familyRelationships,
    divisionNumber,
    uberMemo,
  ]);

  return (
    <Wrapper>
      <Title>기본정보</Title>
      <LabelWrapper column={3}>
        <Label text="차트번호">
          <>
            <TextField
              type="default"
              className="chart-number"
              disabled={autoChartNo}
              value={chartNo ?? ""}
              onChange={(v) => setChartNo(v)}
              disableClear={true}
              placeholder={"고객 등록완료 시 자동발급"}
            />
            <Button
              styled="text"
              size="xs"
              color="primary"
              className="chart-number-btn"
              onClick={() => {
                setChartNo(customer ? customer.chartNo : "");
                setAutoChartNo((v) => !v);
              }}
            >
              {autoChartNo ? (customer?.id ? "변경" : "직접입력") : "취소"}
            </Button>
          </>
        </Label>

        <Label text="고객명">
          <TextField
            type="default"
            value={name}
            onChange={setName}
            placeholder="고객명을 입력하세요"
          />
        </Label>
        <Label text="전화번호">
          <Phone
            phone={phoneNumber || ""}
            onChange={(phoneNumber: string) => setPhoneNumber(phoneNumber)}
            placeholder="전화번호를 입력하세요"
          />
        </Label>
      </LabelWrapper>
      <LabelWrapper column={3}>
        <Label text="주민등록번호">
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            sx={{ width: "100%" }}
          >
            <TextField
              type={"default"}
              maxLength={6}
              value={ssnHead1 || ""}
              onChange={(v) => handleChangeSsnHead1(v)}
              placeholder="앞 6자리"
            />
            <span style={{ padding: "0 10px" }}>-</span>
            <TextField
              type={"default"}
              className="birthday-last-number"
              maxLength={1}
              value={ssnHead2 || ""}
              ref={ssnHead2Ref}
              placeholder="1자리"
              onChange={(v) => {
                if (!/^[0-9]*$/.test(v)) {
                  snackbar.alert("숫자만 입력하세요.");
                  return;
                }
                if (v % 2) {
                  setSex("male");
                } else {
                  setSex("female");
                }
                setSsnHead2(v);
              }}
              style={{
                width: "119px",
              }}
            />
          </Stack>
        </Label>
        <Label text="생년월일 (만 나이 자동계산)">
          <>
            <DateInput
              value={birthday}
              onChange={handleChangeBirthday}
              InputComponent={MuiTextField}
              inputFormat="YYYY-MM-DD"
              mask="____-__-__"
              placeholder="생년월일을 선택하세요"
              editInput
            />
            {birthday && (
              <IconButton onClick={handleResetBirthday}>
                <CloseIcon sx={{ fontSize: 11 }} />
              </IconButton>
            )}

            <Box component={"span"} className="calendar-icon-wrapper">
              <Icon variant="calendar" />
            </Box>
          </>
        </Label>
        <Label text="성별">
          <Radio
            value={sex}
            onChange={setSex}
            options={[
              { label: "남성", value: "male" },
              { label: "여성", value: "female" },
            ]}
          />
        </Label>
      </LabelWrapper>
      <LabelWrapper column={3}>
        <Label
          text={
            <LabelText>
              청구번호
              <Tooltip
                open={openDivisionNumberTooltip}
                title={
                  <span>
                    청구 프로그램 연동 시 고객 구분을 위해 필요한 식별
                    번호입니다.
                  </span>
                }
              >
                <Button
                  onClick={handleToggleDivisionNumberTooltip}
                  styled="text"
                  size="xs"
                  color={"deepGrey"}
                >
                  <ClickAwayListener
                    onClickAway={() => {
                      setOpenDivisionNumberTooltip(false);
                    }}
                  >
                    <HelpOutlineIcon
                      sx={{ fontSize: "16px", marginLeft: "4px" }}
                    />
                  </ClickAwayListener>
                </Button>
              </Tooltip>
            </LabelText>
          }
        >
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            sx={{ width: "100%" }}
          >
            <TextField
              type={"default"}
              maxLength={7}
              value={divisionNumber || ""}
              onChange={(v) => handleChangeDivisionNumber(v)}
              placeholder="청구번호 7자리"
            />
          </Stack>
        </Label>
        <Label text="내/외국인">
          <Radio
            value={type}
            onChange={setType}
            options={[
              { label: "내국인", value: "domestic" },
              { label: "외국인", value: "foreigner" },
            ]}
          />
        </Label>
        <Label text="문자수신동의">
          <Stack
            flexDirection={"row"}
            className="sms-enable-wrapper"
            alignItems={"center"}
          >
            <Checkbox
              checked={smsEnabled}
              onChange={() => {
                setSmsEnabled(!smsEnabled);
              }}
            />
            <label
              onClick={() => {
                setSmsEnabled(!smsEnabled);
              }}
            >{`문자수신`}</label>
            (&nbsp;
            <Checkbox
              checked={eventSmsEnabled}
              disabled={!smsEnabled}
              onChange={() => {
                if (smsEnabled === false) {
                  return;
                }
                setEventSmsEnabled(!eventSmsEnabled);
              }}
            />
            <label
              onClick={() => {
                if (smsEnabled === false) {
                  return;
                }
                setEventSmsEnabled(!eventSmsEnabled);
              }}
            >
              마케팅문자 포함
            </label>
            &nbsp;)
          </Stack>
        </Label>
      </LabelWrapper>
      <LabelWrapper column={3}>
        <Label
          text={
            <LabelText>
              최초 내원경로 (최대 3개) - 내원경로 반복등록 사용 시 1개만 선택
              <Tooltip
                open={openTooltip}
                title={
                  <span>
                    예약∙접수 등록 시 내원경로 자동 입력을 할 수 있습니다.
                    <br />
                    환경설정 후 최초 내원경로를 1개만 선택해 주세요.
                    <br />
                    (환경설정 - 고객코드 설정 - 내원경로 반복등록)
                  </span>
                }
              >
                <Button
                  onClick={handleToggleTooltip}
                  styled="text"
                  size="xs"
                  color={"deepGrey"}
                >
                  <ClickAwayListener
                    onClickAway={() => {
                      setOpenTooltip(false);
                    }}
                  >
                    <HelpOutlineIcon
                      sx={{ fontSize: "16px", marginLeft: "4px" }}
                    />
                  </ClickAwayListener>
                </Button>
              </Tooltip>
            </LabelText>
          }
          style={{ gridColumn: "1 / 3" }}
        >
          <MultiComboBox
            multiple={true}
            options={acquisitionChannelOptions}
            value={acquisitionChannelOptions.filter((o: any) =>
              acquisitionChannel.map((v: any) => v.id).includes(o.id)
            )}
            onChange={(v) => {
              if (v.length > 3) {
                snackbar.alert("최대 3개까지 선택할 수 있습니다.");
                return;
              }
              setAcquisitionChannel(v);
            }}
            placeholder="내원경로를 선택하세요"
            limit={3}
          />
        </Label>
        <Label text="소개자">
          <FindRecommenderInput value={recommender} setValue={setRecommender} />
        </Label>
      </LabelWrapper>
      <Label text="메모">
        <Box className="memo-wrapper">
          <QuillTextField
            value={memo ?? ""}
            onChange={(v) => setMemo(v)}
            placeholder="메모를 입력하세요"
          />
        </Box>
      </Label>
      {clinic.isUberSync === true && (
        <Box className="uber-memo-wrapper">
          <Label text="청구 연동 고객메모">
            <textarea
              ref={textAreaRef}
              value={uberMemo}
              onChange={(e) => handleChangeTextarea(e)}
              placeholder="청구 연동 고객메모를 입력하세요"
            />
          </Label>
        </Box>
      )}

      <LabelWrapper column={3}>
        <Label text="추가정보1">
          <TextField
            type="default"
            value={additionalInfo1}
            onChange={setAdditionalInfo1}
            placeholder="추가정보를 입력하세요 (20자 이내)"
          />
        </Label>
        <Label text="추가정보2">
          <TextField
            type="default"
            value={additionalInfo2}
            onChange={setAdditionalInfo2}
            placeholder="추가정보를 입력하세요 (20자 이내)"
          />
        </Label>
        <Label text="추가정보3">
          <TextField
            type="default"
            value={additionalInfo3}
            onChange={setAdditionalInfo3}
            placeholder="추가정보를 입력하세요 (20자 이내)"
          />
        </Label>
      </LabelWrapper>
      <Title>진료정보</Title>
      <LabelWrapper column={3}>
        <Label text="담당상담사">
          <ComboBox
            value={counselorOptions?.find((f) => f.id === counselor?.id)}
            onChange={(v: any) => {
              setCounselor({ id: v ? v.id : null });
            }}
            placeholder="상담사를 선택하세요"
            options={counselorOptions}
            getOptionLabel={(option: any) => option.label ?? ""}
          />
        </Label>
        <Label text="담당의사">
          <ComboBox
            value={doctorOptions?.find((f) => f.id === doctor?.id)}
            onChange={(v: any) => {
              setDoctor({ id: v ? v.id : null });
            }}
            placeholder="의사를 선택하세요"
            options={doctorOptions}
            getOptionLabel={(option: any) => option.label ?? ""}
          />
        </Label>
        <Label text="관심항목 (최대 3개)">
          <MultiComboBox
            multiple={true}
            options={favoriteOptions}
            value={favoriteOptions.filter((o: any) =>
              favorites.map((v: any) => v.id).includes(o.id)
            )}
            onChange={(v) => {
              if (v.length > 3) {
                snackbar.alert("최대 3개까지 선택할 수 있습니다.");
                return;
              }
              setFavorites(v);
            }}
            placeholder="관심항목을 선택하세요"
            limit={3}
          />
        </Label>
      </LabelWrapper>
      <LabelWrapper column={3}>
        <Label text="고객등급">
          <ComboBox
            value={levelOptions?.find((f: any) => f.id === level?.id)}
            onChange={(v: any) => {
              setLevel({ id: v ? v.id : null });
            }}
            placeholder="고객등급을 선택하세요"
            options={levelOptions}
            getOptionLabel={(option: any) => option.label ?? ""}
          />
        </Label>
      </LabelWrapper>
      <TabContext value={tab}>
        <Tabs value={tab} onChange={onChangeTab}>
          <Tab label="상세정보" value="0" />
          <Tab label="가족관계" value="1" />
          <Tab label="신체정보" value="2" />
        </Tabs>
        <TabPanel value="0" sx={{ p: "0 0 30px" }}>
          <Stack flexDirection={"column"} gap={"20px"}>
            <LabelWrapper column={3}>
              <Label text="주소" style={{ gridColumn: "1 / 3" }}>
                <>
                  <TextField
                    type="default"
                    value={address || ""}
                    onChange={(value: any) => {
                      if (value === "") {
                        setAddress("");
                      }
                    }}
                    onClick={() => {
                      if (!address) {
                        onChangeOpenPost();
                      }
                    }}
                    disabled={isOpenPost}
                    placeholder="주소를 검색하세요"
                    endAdornment={
                      <IconWrapper>
                        <Icon variant="search" />
                      </IconWrapper>
                    }
                  />
                  {isOpenPost && (
                    <ClickAwayListener
                      onClickAway={() => {
                        setIsOpenPost(false);
                      }}
                    >
                      <DaumPostcode
                        style={{
                          display: "inline-table",
                          position: "absolute",
                          height: "448px",
                          left: "0",
                          bottom: "35px",
                          border: "1px solid #000000",
                        }}
                        onComplete={onCompletePost}
                        autoClose={false}
                      />
                    </ClickAwayListener>
                  )}
                </>
              </Label>
              <Label text="상세주소">
                <TextField
                  type={"default"}
                  value={addressDetail || ""}
                  onChange={(v) => {
                    setAddressDetail(v);
                  }}
                  placeholder="상세주소를 입력하세요"
                />
              </Label>
            </LabelWrapper>
            <LabelWrapper column={3}>
              <Label text="직업">
                <ComboBox
                  value={jobOptions?.find((f: any) => f.id === job?.id)}
                  onChange={(v: any) => {
                    setJob({ id: v ? v.id : null });
                  }}
                  placeholder="직업을 선택하세요"
                  options={jobOptions}
                />
              </Label>
              <Label text="결혼여부">
                <DropdownList
                  options={marriedList}
                  value={marriedList.find((f: any) => f.id === married)}
                  onChange={(v) => {
                    setMarried(v?.id ?? null);
                  }}
                  placeholder="결혼 여부를 선택하세요"
                />
              </Label>
              <Label text="국가/지역">
                <ComboBox
                  value={regionOptions?.find((f: any) => f.id === region?.id)}
                  onChange={(v: any) => {
                    setRegion({ id: v ? v.id : null });
                  }}
                  placeholder="국가/지역을 선택하세요"
                  options={regionOptions}
                />
              </Label>
            </LabelWrapper>
            <LabelWrapper column={3}>
              <Label text="불만사항">
                <ComboBox
                  value={complaintOptions?.find(
                    (f: any) => f.id === complaint?.id
                  )}
                  onChange={(v: any) => {
                    setComplaint({ id: v ? v.id : null });
                  }}
                  placeholder="불만사항을 선택하세요"
                  options={complaintOptions}
                  getOptionLabel={(option: any) => option.label ?? ""}
                />
              </Label>
              <Label text="이메일">
                <TextField
                  type="default"
                  value={email || ""}
                  onChange={(v) => setEmail(v)}
                  placeholder="이메일을 입력하세요"
                />
              </Label>
              <Label text="전화번호2">
                <Phone
                  phone={phoneNumber2 || ""}
                  onChange={(phoneNumber: string) =>
                    setPhoneNumber2(phoneNumber)
                  }
                  placeholder="전화번호를 입력하세요"
                />
              </Label>
            </LabelWrapper>
          </Stack>
        </TabPanel>
        <TabPanel value="1" sx={{ p: "0 0 30px" }}>
          <FamilyInfo
            familyRelationships={familyRelationships}
            setFamilyRelationships={(family: any) => {
              setFamilyRelationships(family);
            }}
          />
        </TabPanel>
        <TabPanel value="2" sx={{ p: "0 0 30px" }}>
          <LabelWrapper column={3}>
            <Label text="키">
              <TextField
                type="default"
                maxLength={3}
                value={height || ""}
                onChange={(v) => {
                  if (!validateNumber(v)) {
                    return snackbar.alert("숫자만 입력하세요.");
                  }
                  setHeight(v);
                }}
                placeholder="키를 입력하세요"
                endAdornment={
                  <InputEndAdornment position="end">cm</InputEndAdornment>
                }
              />
            </Label>
            <Label text="혈액형">
              <DropdownList
                options={bloodTypeList}
                value={bloodTypeList.find((f) => f.id === bloodType)}
                onChange={(v: any) => {
                  setBloodType(v?.id ?? null);
                }}
                placeholder="혈액형"
              />
            </Label>
            <Label text="몸무게">
              <TextField
                type="default"
                maxLength={3}
                value={weight || ""}
                onChange={(v) => {
                  if (!validateNumber(v)) {
                    return snackbar.alert("숫자만 입력하세요.");
                  }
                  setWeight(v);
                }}
                placeholder="몸무게를 입력하세요"
                endAdornment={
                  <InputEndAdornment position="end">kg</InputEndAdornment>
                }
              />
            </Label>
          </LabelWrapper>
        </TabPanel>
      </TabContext>
      <Button
        styled="fill"
        color="primary"
        onClick={onSave}
        disabled={isSaving}
        className="submit-btn"
      >
        {customer ? "수정완료" : "등록"}
      </Button>
    </Wrapper>
  );
}
