import { useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FileData } from "core/resources/filesApi";
import { ToolbarPositionType } from "~/pages/FileEditPage";
import ThumbnailItem from "./ThumbnailItem";
import { Box } from "./ThumbnailContainer.styled";

interface ThumnailContainerProps {
  toolbarPosition: ToolbarPositionType;
  files: FileData[];
  onClickThumbnail: (file: FileData) => void;
}

const ThumbnailContainer = ({
  toolbarPosition,
  files,
  onClickThumbnail,
}: ThumnailContainerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { id } = useParams();
  const itemHeight = 210; // className="thumbnail-item" fixed height

  useEffect(() => {
    if (!id || !files.length) return;
    const targetIndex = files.findIndex((item) => item.id === Number(id));
    if (targetIndex !== -1) {
      const listElement = ref.current;
      if (!listElement) return;
      listElement.scrollTo({
        top: itemHeight * targetIndex,
      });
    }
  }, [files, id]);

  return (
    <Box
      className={`thumbnail-container ${
        toolbarPosition === "none" ? "" : toolbarPosition
      }`}
    >
      <Box className="thumbnail-wrapper">
        <Box className="thumbnail-list-wrapper">
          <Box id="thumbnail-items" className="thumbnail-items" ref={ref}>
            {files.map((item) => (
              <ThumbnailItem
                key={item.id}
                item={item}
                onClickItem={onClickThumbnail}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ThumbnailContainer;
