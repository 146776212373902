import { Box as MuiBox, Stack as MuiStack, styled } from "@mui/material";
import { Button as _Button } from "components/Button";

export const Stack = styled(MuiStack)(() => ``);

export const Box = styled(MuiBox)(
  () => `
  &.action-page-container {
    height: 100%;
    display: grid;
    grid-template-rows: max-content 1fr;
  }
  &.button-wrapper {
    button {
      text-decoration-line: none !important;
      white-space: nowrap;
      height: 28px;
    }
    &.customer-popup-button-wrapper {
      width: 126px;
    }
    &.cancel-button-wrapper {
      button {
        font-weight: 500 !important;
      }
    }
  }
`
);

export const Button = styled(_Button)``;
