import { ApiResource } from "./apiResource";
import { ApiDataResponse } from "../apiClient";

export class TreatmentsApi extends ApiResource {
  async getTreatmentItems() {
    return this.client.get<ApiDataResponse<any>>(
      "/treatment_items/categories",
      {
        params: {
          limit: 10000,
          visible: true,
          orderBy: "order asc",
        },
      }
    );
  }
}
