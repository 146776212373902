import {
  styled,
  Box as MuiBox,
  Stack as MuiStack,
  Typography as MuiTypography,
  typographyClasses,
} from "@mui/material";
import { Button as _Button } from "components/Button";

export const Box = styled(MuiBox)(
  ({ theme }) => `
  &.image-editor-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .coating {
      width: 100%;
      height: 100%;
      background: red;
      opacity:0;
      position: absolute;
      z-index: 1300;
    }
  }

  &.image-editor {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &.title-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  &.customer-info {
    font-size: 13px;
    font-weight: 400;
    line-height: 18.82px;
    text-align: center;
    color: ${theme.palette.grey["700"]};
  }

  &.circular-progress-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`
);

export const Stack = styled(MuiStack)(() => ``);

export const Typography = styled(MuiTypography)(
  ({ theme }) => `
  &.${typographyClasses.h6} {
    max-width: 510px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    white-space: nowrap;
    color: ${theme.palette.grey["700"]};
    font-size: 15px;
    font-weight: 700;
    line-height: 21.72px;
  }
`
);

export const Button = styled(_Button)`
  &.save-button {
    text-decoration-line: none !important;
    white-space: nowrap;
  }
`;
