import _Button from "components/Button";
import { DropdownList as _DropdownList } from "components/DropdownList";
import { styled, Stack as MuiStack } from "@mui/material";

export const DropdownList = styled(_DropdownList)(
  ({ theme }) => `
  height: 34px;
  
  input {
    padding-left: 20px !important;
    font-size: 14px !important;
    &::placeholder {
      color: ${theme.palette.bluegrey["600"]};
      opacity: 1;
    }
  }
  .MuiOutlinedInput-root {
    border-radius: 4px !important;
    font-size: 14px !important;
  }
  fieldset {
    border-color: ${theme.palette.layout.line};
  }
`
);

export const Button = styled(_Button)(
  ({ theme }) => `
  width: 20px !important;
  height: 20px !important;
  min-height: unset !important;
  border-radius: 50% !important;
  border: 0 none !important;
  padding: 0 !important;

  &.primary {
    background: ${theme.palette.primary.deepblue} !important;
  }
  &.alert {
    background: ${theme.palette.error.main} !important;
  }
  
`
);

export const Stack = styled(MuiStack)(
  () => `
  width: 100%;
`
);
