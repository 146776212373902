import { useState, useMemo, useRef } from "react";
import { useDialog } from "~/hooks/useDialog";
import MemoBoilerplateModal from "~/modals/memo/MemoBoilerplateModal";
import "./quill.css";
import "react-quill/dist/quill.snow.css";
import { Box } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { CustomToolbar } from "./CustomToolbar";
import { ColorPopper } from "./ColorPopper";
import { StyledReactQuill } from "./QuillTextField.styled";

type QuillTextFieldProps = {
  tabName?: string;
  id?: number | string;
  value: string;
  onChange: (params: any) => void;
  setMemoBoilerplateList?: (value: any[]) => void;
  boilerplate?: boolean;
  placeholder?: string;
  style?: any;
};

export default function QuillTextField({
  tabName,
  value,
  onChange,
  setMemoBoilerplateList,
  boilerplate,
  style,
  ...props
}: QuillTextFieldProps) {
  const BoilerplateModal = useDialog();
  const quillRef: any = useRef(null);
  const colorRef = useRef(null);
  const backgroundRef = useRef(null);
  const [colorAnchorEl, setColorAnchorEl] = useState(null);
  const [backgroundAnchorEl, setBackgroundAnchorEl] = useState(null);

  const colorPopper = useDialog();
  const backgroundPopper = useDialog();

  const addBoilerMemo = (newValue: any) => {
    onChange((value === "<p><br></p>" ? "" : value) + newValue.join(""));
  };

  const onUpdate = (update: any) => {
    addBoilerMemo(update);
  };

  const toolbarId = useMemo(() => `t-${uuidv4().substring(0, 8)}`, []);

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: `#${toolbarId}`,
        handlers: {
          boilerplate: () => {
            BoilerplateModal.open();
          },
          color: () => {
            setColorAnchorEl(colorRef.current);
            colorPopper.toggle();
          },
          background: () => {
            setBackgroundAnchorEl(backgroundRef.current);
            backgroundPopper.toggle();
          },
        },
      },
    };
  }, []);

  const onChangeColor = (value: any) => {
    const editor = quillRef.current.getEditor();
    editor.format("color", value);
  };

  const onChangeBackground = (value: any) => {
    const editor = quillRef.current.getEditor();
    editor.format("background", value);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {BoilerplateModal.opened && tabName && setMemoBoilerplateList && (
        <MemoBoilerplateModal
          open={BoilerplateModal.opened}
          onClose={BoilerplateModal.close}
          tabName={tabName}
          onUpdate={onUpdate}
          updateMemoBoilerplateList={setMemoBoilerplateList}
        />
      )}
      <ColorPopper
        open={colorPopper.opened}
        onClose={colorPopper.close}
        onChange={onChangeColor}
        anchorEl={colorAnchorEl}
      />
      <ColorPopper
        open={backgroundPopper.opened}
        onClose={backgroundPopper.close}
        onChange={onChangeBackground}
        anchorEl={backgroundAnchorEl}
      />
      <CustomToolbar
        id={toolbarId}
        boilerplate={boilerplate}
        colorRef={colorRef}
        backgroundRef={backgroundRef}
      />
      <StyledReactQuill
        ref={quillRef}
        style={style}
        {...props}
        id={props.id?.toString()}
        modules={modules}
        formats={[
          "background",
          "bold",
          "color",
          "italic",
          "strike",
          "underline",
        ]}
        value={value}
        onChange={onChange}
        preserveWhitespace
      />
    </Box>
  );
}
