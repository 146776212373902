import styled from "@emotion/styled";
import { Box as MuiBox, styled as muiStyled } from "@mui/material";

export const Box = muiStyled(MuiBox)(
  () => `
  &.badge-wrapper {
    display: flex;
    align-items: center;
    gap: 2px;
  }
`
);

export const DefaultStatItem = styled.div`
  display: flex;
  column-gap: 2px;
  font-size: 12px;
  padding: 3px 6px;
  border-radius: 2px;
`;

export const StatItemWrapper = styled.div<{
  disabled?: boolean;
  selected?: boolean;
}>`
  display: flex;
  column-gap: 2px;
  font-size: 12px;
  padding: 3px 8px;
  border-radius: 2px;

  ${({ disabled, selected }) =>
    disabled
      ? `
          div,
          span {
            color: #bbbbbb;
          }
          .badge {
            background: #bbbbbb;
            color: white;
          }
        `
      : !selected &&
        `
          cursor: pointer;
          &:hover {
            background-color: #e6eef8;
            div,
            span {
              font-weight: 700;
            }
          }
        `}

  ${({ selected }) =>
    selected
      ? `
          cursor: pointer;
          background: #2d2d2d;
          div,
          span {
            color: white;
            font-weight: 700;
          }
        `
      : ``}
`;

export const StatLabel = styled.span(
  ({ theme }) => `
  font-weight: 400;
  color: ${theme.palette.grey["700"]};
  &.bold {
    font-weight: 700;
  }
  &.red {
    color: ${theme.palette.error["main"]};
  }
`
);

export const StatCount = styled.span`
  font-weight: 700;
  color: ${({ color, theme }) =>
    color === "red" ? theme.palette.error["main"] : theme.palette.grey["700"]};
`;

export const LayoutStatCount = styled.span`
  font-weight: 700;
  color: ${({ color, theme }) =>
    color === "red" ? theme.palette.error["main"] : theme.palette.grey["700"]};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: ${({ theme }) => theme.palette.grey["700"]};
  margin-right: 16px;
`;

export const StatDivision = styled.div`
  padding: 0px 2px;
  font-size: 10px;
  color: #dee2ec;
`;

export const StatGroup = styled.div<{ selected?: boolean }>`
  display: flex;
  background: ${({ selected }: { selected?: boolean }) =>
    selected ? "#f1f1f1" : "#F9FBFF"};
  border-radius: 2px;
  margin-right: 5px;
  align-items: center;
  padding: 4px 6px;
`;
