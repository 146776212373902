import { ReactNode } from "react";
import {
  StyledButton,
  ButtonStyleType,
  ButtonSizeType,
  ButtonColorType,
  StyledButtonProps,
} from "./Button.styled";

export interface ButtonProps {
  size?: ButtonSizeType;
  styled?: ButtonStyleType;
  color?: ButtonColorType;
  onClick?: (params?: any) => void;
  disabled?: boolean;
  children?: ReactNode;
  icon?: ReactNode;
  style?: any;
  className?: string;
  autoFocus?: boolean;
  value?: any;
}

export const Button = ({
  size = "m",
  styled = "fill",
  color = "primary",
  onClick,
  disabled,
  children,
  icon,
  className,
  autoFocus = false,
  ...rest
}: ButtonProps) => {
  const buttonProps: StyledButtonProps = {
    className,
    $size: size,
    $color: color,
    styled,
    onClick,
    disabled,
    autoFocus,
    ...rest,
  };

  return (
    <StyledButton {...buttonProps}>
      {icon && icon}
      {children}
    </StyledButton>
  );
};

export default Button;
