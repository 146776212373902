import styled from "@emotion/styled";
import { FindFamilyInput as _FindFamilyInput } from "./FindFamilyInput";
import { Label as _Label } from "components/Form/Label";
import Button from "components/Button";

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Label = styled(_Label)`
  div {
    gap: 5px;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
`;

export const FindFamilyInput = styled(_FindFamilyInput)`
  height: 34px;
  input {
    padding-left: 20px !important;
    font-size: 14px !important;
    &::placeholder {
      color: ${({ theme }) => theme.palette.bluegrey["600"]};
      opacity: 1;
    }
  }
  .MuiOutlinedInput-root {
    height: 34px;
    font-size: 14px !important;
    padding: 0 20px !important;
  }
  fieldset {
    border-color: ${({ theme }) => theme.palette.layout.line};
  }
`;

export const ActionButton = styled(Button)`
  width: 16px !important;
  height: 16px !important;
  min-height: 16px !important;
  border-radius: 50% !important;
  padding: 0 !important;
  border: 0 none !important;
  margin-left: 8px;
`;

export const AddButton = styled(ActionButton)`
  background: #293142 !important;
`;

export const RemoveButton = styled(ActionButton)`
  background: #eb5757 !important;
`;
