import "./quill.css";
import { useState, useEffect, useRef, ComponentType } from "react";
import styled from "@emotion/styled";
import sanitizeHtml from "sanitize-html";
import ShowMorePopover from "./ShowMorePopover";

const QuillWrapper = styled.div`
  height: auto;
  overflow: hidden;

  div {
    display: -webkit-box;
    ${({ maxLine }: any) => (maxLine ? `-webkit-line-clamp:${maxLine}` : "")};
    -webkit-box-orient: vertical;
    white-space: pre-line;
    width: max-content;
    max-width: -webkit-fill-available;
    word-break: break-word;
  }
` as ComponentType<any>;

const MoreMemoButton = styled.span`
  color: #a1b1ca;
  cursor: pointer;
  text-align: left;
`;

type QuillTextProps = {
  value: string;
  style?: any;
  maxLine?: number;
};

export default function QuillText({
  value,
  style,
  maxLine,
  ...props
}: QuillTextProps) {
  const memoRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showMoreMemoText, setShowMoreMemoText] = useState(false);
  const textClass = /^<p>[\s\S]*<\/p>$/.test(value)
    ? "quill-text"
    : "quill-plain-text";

  const getText = (str: string) => {
    if (!str) return "";
    const arr = str
      .split("</p>")
      .map((v) => v && `${v}</p>`)
      .filter((v) => v.length);

    return arr.join("");
  };

  const openPopover = (e: any) => {
    setAnchorEl(e.target);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const element: any = memoRef.current;
    if (element.scrollHeight > element.offsetHeight + 1) {
      setShowMoreMemoText(true);
    } else {
      setShowMoreMemoText(false);
    }
  }, [value]);

  const checkInnerText = (_value: string) => {
    const value = getText(_value);
    try {
      return (
        <div
          className={textClass}
          style={{ textAlign: "left", ...style }}
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(value, {
              allowedAttributes: false,
            }),
          }}
        />
      );
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  return (
    <>
      <QuillWrapper maxLine={maxLine} ref={memoRef} {...props}>
        {checkInnerText(value)}
      </QuillWrapper>
      {maxLine && showMoreMemoText && (
        <>
          <MoreMemoButton onClick={(e) => openPopover(e)}>
            [더보기]
          </MoreMemoButton>
          <ShowMorePopover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={closePopover}
            value={value}
          />
        </>
      )}
    </>
  );
}
