import styled from "@emotion/styled";

const Badge = styled.div`
  flex-shrink: 0;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;
  background-color: #ff9900;
  border-radius: 2px;

  font-family: Noto Sans KR;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: center;
`;

export const RegistrationTypeBadge = (props: any) => (
  <Badge className="badge" {...props}>
    당
  </Badge>
);
