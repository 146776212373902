import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useApi } from "./ApiProvider";
import { useAuth } from "./AuthProvider";
import { observer } from "mobx-react-lite";
import { useUserStore } from "~/hooks/useUserStore";

interface UserContextValue {
  user: any;
  refresh: () => void;
  hasPermission: (name: string) => boolean;
  userConfig: Record<string, any>;
  loadUserConfig: (key: string) => Promise<void>;
  updateUserConfig: (key: string, value: string) => Promise<void>;
  getUserConfigValue: (key: string, defaultValue?: any) => any;
}

const UserContext = createContext<UserContextValue | null>(null);

export const UserProvider = observer(
  ({ children }: { children: ReactNode }) => {
    const { clear, authed } = useAuth();
    const { userApi } = useApi();
    const {
      setUser,
      loadUserConfig: loadUserStoreConfig,
      updateUserConfig: updateUserStoreConfig,
      user,
      getUserConfigValue,
      hasPermission,
      userConfig,
    } = useUserStore();

    useEffect(() => {
      if (!authed) return;
      loadUser();
    }, [authed]);

    const loadUser = useCallback(async () => {
      let res;
      try {
        res = await userApi.me();
      } catch (e) {
        clear();
        return;
      }

      const payload = await res.data;
      setUser(payload.data);
    }, [clear, userApi]);

    const loadUserConfig = useCallback(async (key: string) => {
      await loadUserStoreConfig(key);
    }, []);

    const updateUserConfig = useCallback(async (key: string, value: string) => {
      await updateUserStoreConfig(key, value);
    }, []);

    const contextValue: UserContextValue = {
      user: user,
      refresh: loadUser,
      hasPermission: hasPermission,
      userConfig: userConfig,
      loadUserConfig,
      updateUserConfig,
      getUserConfigValue: getUserConfigValue,
    };

    return (
      <UserContext.Provider value={contextValue}>
        {children}
      </UserContext.Provider>
    );
  }
);

export function useUser() {
  return useContext(UserContext) as UserContextValue;
}
