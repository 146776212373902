import { useMemo, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { EmptyView } from "components/EmptyView";
import { useSnackbarContext } from "~/SnackbarProvider_v2";
import InfinityScroll from "components/InfinityScroll";
import useSWRInfinite from "swr/infinite";
import { useApi } from "providers/ApiProvider";
import Button from "components/Button";
import { Box } from "pages/Customer/detail/SurveyTable.styled";
import { useSurvey } from "hooks/useSurvey";
import { CustomerType } from "type/customer";

const PAGE_SIZE = 20;

export function SurveyTable({ customer }: { customer: CustomerType }) {
  const { surveyApi } = useApi();
  const { createSurvey, updateSurvey } = useSurvey(customer);
  const snackbar = useSnackbarContext();

  const getKey = (pageIndex: number, previousPageData: any) => {
    if (pageIndex === 0) {
      return {
        customerId: customer.id,
        page: 1,
        limit: PAGE_SIZE,
        orderBy: `id desc`,
      };
    }
    if (!previousPageData?.data?.length) return null;
    return {
      customerId: customer.id,
      page: pageIndex + 1,
      limit: PAGE_SIZE,
      orderBy: `id desc`,
    };
  };

  const { data, error, size, setSize, isValidating, mutate } = useSWRInfinite(
    getKey,
    async (params) => {
      const response = await surveyApi.list(params);
      return response.data;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      persistSize: true,
    }
  );

  const isEmpty = data?.[0]?.data?.length === 0;
  const isLoadingMore = isValidating;
  const hasMore = data ? size < (data[0]?.pagination?.pages || 0) : false;

  const list = useMemo(() => {
    if (!data) return [];

    return data.flatMap((page, pageIndex) =>
      (page?.data || []).map((item: any, index: number) => ({
        ...item,
        rowIndex:
          (page.pagination?.total || 0) - (pageIndex * PAGE_SIZE + index),
      }))
    );
  }, [data]);

  const loadMore = () => {
    if (hasMore && !isLoadingMore) {
      setSize(size + 1);
    }
  };

  const handleNewSurvey = async () => {
    await createSurvey(mutate);
  };

  const handleOpenSurvey = async (surveyId: number) => {
    await updateSurvey(surveyId, mutate);
  };

  useEffect(() => {
    if (error) {
      snackbar.alert(error.message || "데이터 로드 중 오류가 발생했습니다");
    }
  }, [error, snackbar]);

  return (
    <div style={{ height: "calc(100% - 40px)" }}>
      <Box className="tab-header">
        <Button
          styled="outline"
          color="primary"
          size="xxs"
          className="penchart-btn"
          onClick={handleNewSurvey}
        >
          문진등록
        </Button>
      </Box>
      <Box className="tab-body">
        <InfinityScroll
          action={loadMore}
          isLoading={isLoadingMore}
          hasMore={hasMore}
        >
          <Table
            stickyHeader
            sx={{ background: "#fff", borderBottom: "1px solid #F9FBFF" }}
            className="appointment-table"
          >
            {isEmpty && (
              <caption>
                <EmptyView text="문진내역이 없습니다." />
              </caption>
            )}
            <TableHead>
              <TableRow
                sx={{
                  "& th": {
                    padding: "13px 0",
                    color: "#282828",
                    fontWeight: 700,
                    borderBottom: "1px solid #F9FBFF",
                  },
                }}
              >
                <TableCell align="center">No.</TableCell>
                <TableCell align="center">상태</TableCell>
                <TableCell align="center">일자</TableCell>
                <TableCell align="center">제목</TableCell>
                <TableCell align="center" width="10%">
                  작성자
                </TableCell>
              </TableRow>
            </TableHead>
            {!isEmpty && (
              <TableBody>
                {list.map((row: any) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "& td": {
                        padding: "10px 0",
                        color: "#282828",
                        borderColor: "#F9FBFF",
                      },
                    }}
                  >
                    <TableCell
                      align="center"
                      style={{ minWidth: "40px", width: "10%" }}
                    >
                      {row.rowIndex}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: "90px", width: "10%" }}
                    >
                      {row.status === "complete" ? "완료" : "미완료"}
                    </TableCell>
                    <TableCell align="center">{row.registeredDate}</TableCell>
                    <TableCell align="left">
                      <Box className="survey-title" component="span">
                        <Button
                          styled={"text"}
                          onClick={() => handleOpenSurvey(row.id)}
                        >
                          {row.title}
                        </Button>
                      </Box>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ width: "20%", minWidth: "90px" }}
                    >
                      <Box className="survey-creator" component="span">
                        {row.creatorName}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </InfinityScroll>
      </Box>
    </div>
  );
}

export default SurveyTable;
