import {
  useEffect,
  useCallback,
  forwardRef,
  useRef,
  ReactNode,
  useState,
  ForwardedRef,
} from "react";
import { observer } from "mobx-react";
import { format, getMonth, getYear } from "date-fns";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "assets/styles/datepicker.css";
import { useHoliday } from "providers/HolidayProvider";
import Icon from "./Icon";
import { ToolbarButton } from "components/ToolbarButton";
import { Popper } from "@mui/material";
import NativeSelect from "components/NativeSelect2";
import { Wrapper, DatePickerWrapper, DateText, Box } from "./DateInput.styled";

function range(start: number, end: number) {
  return Array(end - start + 1)
    .fill("")
    .map((_, idx) => start + idx);
}
const years = range(1900, 2100);
const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

type DateInputProps = Omit<ReactDatePickerProps, "onChange"> & {
  className?: string;
  placeholder?: string;
  dateFormat?: string;
  value?: any;
  maxDate?: Date | string;
  minDate?: Date | string;
  onChange?: (value?: any, event?: any) => void;
  disabled?: boolean;
  width?: string;
  style?: any;
  error?: string;
  autoFocus?: boolean;
  customInput?: any;
  readOnly?: boolean;
  selected?: any;
  popperPlacement?: any;
  popperModifiers?: any;
  wrapperClassName?: string;
  disablePortal?: boolean;
  onSelect?: (value?: any, event?: any) => void;
  id?: string;
};

const DateInput = forwardRef(
  (
    {
      className,
      placeholder,
      dateFormat = "yyyy년 MM월 dd일",
      value,
      onChange,
      disabled,
      maxDate,
      minDate,
      width,
      error,
      autoFocus,
      wrapperClassName,
      disablePortal = true,
      id,
      ...props
    }: DateInputProps,
    ref: ForwardedRef<DatePicker>
  ) => {
    const [open, setOpen] = useState(false);
    const holiday: any = useHoliday();
    const inputRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const [isPositioned, setIsPositioned] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const getHoliday = useCallback(
      async (date: any) => {
        (await holiday) && holiday.load(date ?? new Date());
      },
      [holiday]
    );

    useEffect(() => {
      if (value) getHoliday(value);
    }, [getHoliday, value]);

    useEffect(() => {
      if (autoFocus && ref) {
        const { current }: any = ref;
        if (current) {
          current?.setFocus();
        }
      }
    }, [ref, autoFocus]);

    useEffect(() => {
      const handleGlobalClick = (e: MouseEvent) => {
        if (!open) return;

        const target = e.target as HTMLElement;
        const isDatePickerClick = target.closest(".react-datepicker");
        const isInputClick = target.closest(`#date-input-${id}`);
        const isHeaderClick = headerRef.current?.contains(target);

        if (isHeaderClick || isDatePickerClick || isInputClick) {
          return;
        }

        setOpen(false);
        setAnchorEl(null);
      };

      document.addEventListener("mousedown", handleGlobalClick);
      return () => document.removeEventListener("mousedown", handleGlobalClick);
    }, [open, id]);

    const getDayName = (date: Date) => {
      return date.toLocaleDateString("ko-KR", { weekday: "long" }).substr(0, 1);
    };

    const getDayClassName = useCallback(
      (date: any) => {
        return (holiday?.holidayValues ?? []).some(
          (v: any) => v.locdate === format(date, "yyyy-MM-dd")
        )
          ? "holiday"
          : getDayName(new Date(date)) === "토"
          ? "saturday"
          : getDayName(new Date(date)) === "일"
          ? "sunday"
          : null;
      },
      [holiday?.holidayValues]
    );

    const onChangeDate = (v: any, e: any) => {
      if (!v || String(getYear(v)).length === 4) {
        onChange && onChange(v, e);
        setOpen(false);
        setAnchorEl(null);
      }
    };

    const onClickPrevMonth = (e: any, func: any) => {
      e.stopPropagation();
      func();
    };

    const onClickNextMonth = (e: any, func: any) => {
      e.stopPropagation();
      func();
    };

    const popperModifiers = [
      {
        name: "flip",
        enabled: true,
        options: {
          fallbackPlacements: ["top", "bottom"],
        },
      },
      {
        name: "preventOverflow",
        enabled: true,
        options: {
          boundary: "viewport",
          padding: 8,
        },
      },
    ];

    const handleInputClick = (event: React.MouseEvent<HTMLElement>) => {
      if (disabled) return;

      const currentTarget = event.currentTarget;

      if (open && anchorEl === currentTarget) {
        setOpen(false);
        setAnchorEl(null);
        return;
      }

      setAnchorEl(currentTarget);
      setOpen(true);
    };

    const getPopperContainer = useCallback(
      ({ children }: { children: ReactNode }) => {
        if (disablePortal) {
          return <>{children}</>;
        } else {
          return (
            <Popper
              id={`date-popover-${id}`}
              open={open}
              anchorEl={anchorEl}
              style={{
                zIndex: 1301,
                opacity: isPositioned ? 1 : 0,
                transition: "opacity 150ms ease-in-out",
                position: isPositioned ? "absolute" : "fixed",
                visibility: isPositioned ? "visible" : "hidden",
              }}
              placement="bottom-start"
              modifiers={[
                {
                  name: "flip",
                  enabled: true,
                  options: {
                    fallbackPlacements: ["top-start", "bottom-start"],
                  },
                },
                {
                  name: "preventOverflow",
                  enabled: true,
                  options: {
                    boundary: "viewport",
                    padding: 8,
                  },
                },
                {
                  name: "afterWrite",
                  enabled: true,
                  phase: "afterWrite",
                  fn: () => {
                    if (!isPositioned) {
                      setTimeout(() => {
                        setIsPositioned(true);
                      }, 100);
                    }
                  },
                },
              ]}
            >
              <Box className="date-picker-wrapper">{children}</Box>
            </Popper>
          );
        }
      },
      [open, disablePortal, anchorEl, id, isPositioned]
    );

    return (
      <DatePickerWrapper
        ref={inputRef}
        id={`date-input-${id}`}
        className={`date-picker-wrapper ${wrapperClassName}`}
        style={{
          width: width ? width : "auto",
          ...props?.style,
        }}
        onClick={handleInputClick}
      >
        <DatePicker
          ref={ref}
          popperModifiers={popperModifiers}
          showYearDropdown
          showMonthDropdown
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div onClick={(e) => e.stopPropagation()}>
              <Wrapper>
                <ToolbarButton
                  data-testid="previous-month"
                  onClick={(e: any) => onClickPrevMonth(e, decreaseMonth)}
                  disabled={prevMonthButtonDisabled}
                >
                  <Icon variant="chevron_left" />
                </ToolbarButton>
                <DateText>{format(new Date(date), "yyyy.MM.dd (E)")}</DateText>
                <ToolbarButton
                  data-testid="next-month"
                  onClick={(e: any) => onClickNextMonth(e, increaseMonth)}
                  disabled={nextMonthButtonDisabled}
                >
                  <Icon variant="chevron_right" />
                </ToolbarButton>
              </Wrapper>
              <Wrapper>
                <div>
                  <NativeSelect
                    value={getYear(date)}
                    onChange={(v: any) => {
                      changeYear(Number(v));
                    }}
                    optionLabel="label"
                    optionValue="value"
                    options={years.map((v) => ({ label: `${v}년`, value: v }))}
                  />
                </div>
                <div>
                  <NativeSelect
                    value={months[getMonth(date)]}
                    onChange={(v: any) => {
                      changeMonth(months.indexOf(v));
                    }}
                    optionLabel="label"
                    optionValue="value"
                    options={months.map((v) => ({ label: `${v}월`, value: v }))}
                  />
                </div>
              </Wrapper>
            </div>
          )}
          dropdownMode="select"
          className={className}
          placeholderText={placeholder ?? "날짜선택"}
          dateFormat={dateFormat}
          selected={value ? new Date(value) : null}
          onChange={onChangeDate}
          onYearChange={getHoliday}
          onMonthChange={getHoliday}
          dayClassName={getDayClassName}
          disabled={disabled}
          maxDate={maxDate && new Date(maxDate)}
          minDate={minDate && new Date(minDate)}
          open={open}
          onCalendarOpen={() => {
            setOpen(true);
          }}
          onCalendarClose={() => {
            setOpen(false);
            setAnchorEl(null);
          }}
          popperContainer={getPopperContainer}
          {...props}
        />
      </DatePickerWrapper>
    );
  }
);

export default observer(DateInput);
