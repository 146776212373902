import { useRef, useState, LegacyRef } from "react";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { useApi } from "providers/ApiProvider";
import { useDriveView } from "./DriveView";
import { useSnackbarContext } from "~/SnackbarProvider_v2";
import { SelectFileDialog } from "./SelectFileDialog";
import { useModal } from "hooks/useModal";
import { useSelectImages } from "hooks/useSelectImages";
import { useImperativeModal } from "ImperativeModalProvider";
import { AlertModal } from "modals/AlertModal";
import { getImageDimensions, bytesToSize } from "utils/fileUtil";
import { getMaxDimesionSize } from "utils/file";
import Icon from "components/Icon";

type DriveSpeedDialProps = {
  directoryId: number;
};

const { width, height } = getMaxDimesionSize();

export const DriveSpeedDial = ({ directoryId }: DriveSpeedDialProps) => {
  const { filesApi, imageApi } = useApi();
  const cameraInputRef = useRef<HTMLInputElement>();
  const imageInputRef = useRef<HTMLInputElement>();
  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const driveView = useDriveView();
  const snackbar = useSnackbarContext();
  const selectFileDialog = useModal();
  const imperativeModal = useImperativeModal();
  const { select } = useSelectImages();

  const onClickCamera = () => {
    cameraInputRef.current && cameraInputRef.current.click();
    closeSpeedDial();
  };

  const onClickImage = () => {
    imageInputRef.current && imageInputRef.current.click();
    closeSpeedDial();
  };

  const onClickDrive = () => {
    selectFileDialog.open();
    closeSpeedDial();
  };

  const closeSpeedDial = () => {
    setOpenSpeedDial(false);
  };

  const onChangeCameraFile = async (e: any) => {
    if (!e.target.files.length) return;

    const files = e.target.files;
    for (const file of files) {
      const dimensions: any = await getImageDimensions(file);
      const { size } = await bytesToSize(file.size, "MB");
      if (
        dimensions?.width > width ||
        dimensions?.height > height ||
        size > 50
      ) {
        return await imperativeModal.open((close) => (
          <AlertModal onClose={close} bgClickCancel={true}>
            이미지 파일 크기가 50MB를 초과하거나, 가로 또는 세로 길이가 4096px을
            초과하는 경우에는 <br />
            앱에서 업로드 할 수 없으며 PC에서 업로드해 주시길 바랍니다.
            <br />
            (안드로이드 기기는 4128px까지 가능)
          </AlertModal>
        ));
      }
    }

    const images: any = await select(Array.from(e.target.files));
    if (!images) return;

    try {
      await createPenchartFile(images[0]);
      snackbar.success(`이미지를 업로드했습니다.`);
    } catch (e) {
      snackbar.alert(`이미지 업로드에 실패했습니다.`);
      throw e;
    } finally {
      driveView && driveView.reload();
    }
  };

  const getPresignedData = async (fileExtension: string) => {
    const response = await filesApi.createImage(fileExtension);
    return await response.data;
  };

  const createPenchartFile = async (image: { mime: string; file: File }) => {
    const ext = image.mime.split("/")[1];
    const {
      data: { presignedUrl, token },
    } = await getPresignedData(ext);

    await imageApi.uploadImage(presignedUrl, image.file);

    const resp = await filesApi.uploadImages(token);
    const result = await resp.data;

    return await filesApi.createFile({
      imageId: result.data.id,
      name: image.file.name,
      parentId: directoryId,
    });
  };

  const onChangeImageFile = async (e: any) => {
    if (!e.target.files.length) return;

    const files = e.target.files;
    for (const file of files) {
      const dimensions: any = await getImageDimensions(file);
      const { size } = await bytesToSize(file.size, "MB");
      if (
        dimensions?.width > width ||
        dimensions?.height > height ||
        size > 50
      ) {
        return await imperativeModal.open((close) => (
          <AlertModal onClose={close} bgClickCancel={true}>
            이미지 파일 크기가 50MB를 초과하거나, 가로 또는 세로 길이가 4096px을
            초과하는 경우에는 <br />
            앱에서 업로드 할 수 없으며 PC에서 업로드해 주시길 바랍니다.
            <br />
            (안드로이드 기기는 4128px까지 가능)
          </AlertModal>
        ));
      }
    }

    const images: any = await select(Array.from(e.target.files));
    if (!images) return;

    try {
      await Promise.all(images.map(createPenchartFile));
      snackbar.success(`이미지 ${images.length}개를 업로드했습니다.`);
    } catch (e) {
      snackbar.alert(`일부 이미지 업로드에 실패했습니다.`);
      throw e;
    } finally {
      driveView.reload();
    }
  };

  const onClickCameraInput = (e: any) => {
    e.target.value = null;
  };

  const onClickImageInput = (e: any) => {
    e.target.value = null;
  };

  const onSelectFromPenchart = async ({
    files,
    directories,
  }: {
    files: number[];
    directories: number[];
  }) => {
    if (!files.length && !directories.length) {
      snackbar.alert("한 개 이상의 파일 또는 폴더를 선택해야합니다.");
      return;
    }

    try {
      await Promise.all([
        ...directories.map((srcId: number) =>
          filesApi.copyDirectory(srcId, directoryId)
        ),
        ...files.map((srcId: number) => filesApi.copyFile(srcId, directoryId)),
      ]);

      snackbar.success(`선택한 파일 및 폴더를 업로드했습니다.`);
    } catch (e) {
      snackbar.alert(`일부 파일 또는 폴더 업로드에 실패했습니다.`);
      throw e;
    } finally {
      selectFileDialog.close();
      driveView.reload();
    }
  };

  const onOpenSpeedDial = (e: any, reason: "toggle" | string) => {
    if (reason !== "toggle") return;
    setOpenSpeedDial(true);
  };

  const onCloseSpeedDial = (e: any, reason: "toggle" | string) => {
    if (reason !== "toggle") return;
    setOpenSpeedDial(false);
  };

  return (
    <>
      <SpeedDial
        className="drive-speed-dial"
        ariaLabel="DriveView speed dial"
        sx={{
          position: "absolute",
          bottom: 16,
          right: 16,
          ".MuiSpeedDial-actions": {
            paddingBottom: "42px",
          },
          ".MuiSpeedDialAction-staticTooltipLabel": {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            whiteSpace: "nowrap",
            fontSize: "14px",
            fontWeight: "bold",
            padding: "4px 10px",
          },
          ".MuiFab-root": {
            width: "56px",
            height: "56px",
            margin: "5px",
          },
        }}
        icon={<SpeedDialIcon />}
        onClose={onCloseSpeedDial}
        onOpen={onOpenSpeedDial}
        open={openSpeedDial}
      >
        <SpeedDialAction
          icon={<Icon variant="drive_folder" />}
          onClick={onClickDrive}
        />
        <SpeedDialAction
          icon={<Icon variant="photo" />}
          onClick={onClickImage}
        />
        <SpeedDialAction
          icon={<Icon variant="camera" />}
          onClick={onClickCamera}
        />
      </SpeedDial>
      <input
        ref={cameraInputRef as LegacyRef<HTMLInputElement>}
        style={{ display: "none" }}
        accept="image/*"
        type="file"
        capture
        onChange={onChangeCameraFile}
        onClick={onClickCameraInput}
      />
      <input
        ref={imageInputRef as LegacyRef<HTMLInputElement>}
        style={{ display: "none" }}
        accept=".png, .jpg, .jpeg"
        type="file"
        multiple
        onChange={onChangeImageFile}
        onClick={onClickImageInput}
      />
      <SelectFileDialog
        open={selectFileDialog.opened}
        onClose={selectFileDialog.close}
        onSelect={onSelectFromPenchart}
      />
    </>
  );
};
