import { useState } from "react";
import { useImperativeModal } from "ImperativeModalProvider";
import { useSnackbarContext } from "~/SnackbarProvider_v2";
import { Box } from "@mui/material";
import { List } from "components/List/List";
import { CreateCustomerModal } from "modals/CreateCustomerModal";
import { useApi } from "providers/ApiProvider";
import { useUser } from "providers/UserProvider";
import { ButtonWrapper, Button } from "pages/Customer/detail/Detail.styled";
import { useClinic } from "providers/ClinicProvider";
import { CustomerDeleteConfirmModal } from "modals/CustomerDeleteConfirmModal";
import { delay } from "utils/common";
import { CustomerType } from "~/type/customer";

const getSsn = (ssnHead = "") => {
  let ssnHead1 = "";
  let ssnHead2 = "";

  if (ssnHead.length === 7) {
    ssnHead1 = ssnHead.slice(0, 6);
    ssnHead2 = ssnHead.slice(-1);
  } else {
    ssnHead1 = ssnHead;
  }

  return ssnHead1 === "" ? "" : ssnHead1 + "-" + ssnHead2;
};
const infoData = (customer: any) =>
  (customer && [
    [{ label: "차트번호", value: customer.chartNo }],
    [
      {
        label: "주민등록번호",
        value: customer.ssnHead ? getSsn(customer.ssnHead) : "",
      },
      { label: "생년월일", value: customer.birthday },
    ],
    [
      { label: "성별", value: customer?.sex === "female" ? "여성" : "남성" },
      {
        label: "내/외국인",
        value: customer?.type === "domestic" ? "내국인" : "외국인",
      },
    ],
    [
      { label: "전화번호", value: customer?.phoneNumber },
      {
        label: "소개자",
        value: customer.recommender
          ? `${customer.recommender?.name}(${customer.recommender?.chartNo})`
          : "",
      },
    ],
    [
      { label: "고객등급", value: customer.level?.name },
      { label: "신환 구분일", value: customer.establishedOn },
    ],
    [
      { label: "추가정보1", value: customer.additionalInfo1 },
      { label: "추가정보2", value: customer.additionalInfo2 },
    ],
    [{ label: "추가정보3", value: customer.additionalInfo3 }],
    [
      { label: "문자수신", value: customer?.smsEnabled ? "동의" : "거부" },
      {
        label: "마케팅수신",
        value: customer?.eventSmsEnabled ? "동의" : "거부",
      },
    ],
    [
      { label: "담당상담사", value: customer.counselor?.name },
      { label: "담당의사", value: customer.doctor?.name },
    ],
    [
      {
        label: "관심항목",
        value:
          customer.favorites?.length > 0
            ? customer.favorites.map((v: any) => v.name).join(", ")
            : "-",
      },
    ],
    [
      {
        label: "최초내원경로",
        value:
          customer.acquisitionChannels?.length > 0
            ? customer.acquisitionChannels.map((v: any) => v.name).join(", ")
            : "-",
      },
    ],
    [
      { label: "주소", value: customer.address },
      { label: "상세주소", value: customer.addressDetail },
    ],
    [
      { label: "직업", value: customer.job?.name },
      {
        label: "결혼여부",
        value:
          customer.married !== null
            ? customer.married === true
              ? "기혼"
              : "미혼"
            : "",
      },
    ],
    [
      { label: "국가/지역", value: customer.region?.name },
      { label: "불만사항", value: customer.complaint?.content },
    ],
    [
      { label: "이메일", value: customer.email },
      { label: "전화번호2", value: customer.phoneNumber2 },
    ],
    [
      {
        label: "가족관계",
        value:
          customer.familyRelationships?.length > 0 ? (
            <div>
              {customer.familyRelationships.map((v: any) => (
                <div key={v.id}>
                  {v.name}/{v.birthday ?? "-"}/
                  {v.sex === "female" ? "여성" : "남성"}/{v.phoneNumber ?? "-"}
                </div>
              ))}
            </div>
          ) : (
            "-"
          ),
      },
    ],
    [
      { label: "키", value: customer.height ? `${customer.height}cm` : "" },
      {
        label: "혈액형",
        value: customer.bloodType ? `${customer.bloodType}형` : "",
      },
    ],
    [{ label: "몸무게", value: customer.weight ? `${customer.weight}kg` : "" }],
    [
      {
        label: "메모",
        value: (
          <Box
            dangerouslySetInnerHTML={{ __html: customer.memo }}
            sx={{
              "*": {
                margin: 0,
              },
            }}
          />
        ),
      },
    ],
  ]) ?? [[]];

interface InfoListProps {
  customer: CustomerType;
  onCustomerDelete?: () => void;
  reload?: () => void;
  edit?: boolean;
}

export function InfoList({
  customer,
  onCustomerDelete,
  reload,
  edit,
}: InfoListProps) {
  const { customersApi } = useApi();
  const snackbar = useSnackbarContext();
  const { hasPermission } = useUser();
  const { clinic } = useClinic();
  const imperativeModal = useImperativeModal();
  const [openEditModal, setOpenEditModal] = useState(false);

  const openEditCustomerModal = () => {
    setOpenEditModal(true);
  };
  const closeEditCustomerModal = () => {
    setOpenEditModal(false);
  };

  const handleCustomerDelete = async () => {
    const handleDelete = async (close: any) => {
      try {
        await customersApi.deleteCustomer(customer?.id);
        snackbar.success("삭제되었습니다.");
        await delay(100);
        onCustomerDelete?.();
        close();
      } catch (e) {
        snackbar.alert("삭제에 실패했습니다.");
      }
    };

    await imperativeModal.open((close) => (
      <CustomerDeleteConfirmModal
        onClose={close}
        onConfirm={() => handleDelete(close)}
        customer={customer}
      />
    ));
  };

  if (!customer) return null;
  return (
    <Box
      sx={{
        height: "100%",
        overflow: "auto",
        paddingBottom: "112px",
      }}
    >
      <List
        data={
          clinic.isUberSync
            ? infoData(customer).concat([
                [
                  {
                    label: "청구 연동 고객메모",
                    value: customer.uberMemo ?? "",
                  },
                ],
              ])
            : infoData(customer)
        }
      />
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          width: "100%",
          padding: "10px",
          columnGap: "10px",
          bottom: 0,
          background: "#ffffff",
          button: {
            width: "100%",
            height: "46px",
          },
        }}
      >
        {edit && (
          <>
            {hasPermission("CUSTOMER_DELETE") && (
              <ButtonWrapper>
                <Button color="grey" onClick={() => handleCustomerDelete()}>
                  삭제
                </Button>
              </ButtonWrapper>
            )}
            <ButtonWrapper>
              <Button onClick={openEditCustomerModal}>수정</Button>
            </ButtonWrapper>
          </>
        )}
      </Box>
      {openEditModal && (
        <CreateCustomerModal
          open={true}
          onClose={closeEditCustomerModal}
          onSaveCallback={reload}
          customer={customer}
        />
      )}
    </Box>
  );
}
