import { useEffect, useMemo, useState } from "react";
import { DialogTitle, DialogActions, DialogContent, Card } from "@mui/material";
import Button from "~/components/Button";
import { observer } from "mobx-react";
import Icon from "~/components/Icon";
import { Dialog, Stack, IconButton } from "./FilterPopup.styled";
import { useAppointmentsStore } from "~/hooks/useAppointmentsStore";
import { useApi } from "~/providers/ApiProvider";
import { useUserStore } from "~/hooks/useUserStore";
import {
  useStatusSettings,
  StatusSettings,
} from "providers/StatusSettingsProvider";
import {
  PERSIST_FILTERS_KEY,
  APPOINTMENT_FILTERS_KEY,
} from "~/store/userStore";

type FilterPopupProps = {
  onClose: (param?: any) => void;
};

type Department = { id: number; name: string; [key: string]: any };

const FilterPopup = (props: FilterPopupProps) => {
  const { onClose } = props;
  const [tempDepartmentFilters, setTempDepartmentFilters] = useState<number[]>(
    []
  );
  const [tempStatusFilters, setTempStatusFilters] = useState<number[]>([]);
  const [departmentsData, setDepartmentsData] = useState<Department[]>([]);
  const { departmentApi } = useApi();
  const { updateUserConfig, getUserConfigValue, loadUserConfig } =
    useUserStore();
  const { loadConfig, settings } = useStatusSettings() as StatusSettings;

  const {
    selectedDepartmentFilters,
    selectedStatusFilters,
    setSelectedDepartmentFilters,
    setSelectedStatusFilters,
  } = useAppointmentsStore();

  useEffect(() => {
    loadDepartmentCategories();
    loadConfig();
    loadUserSettings();
  }, []);

  useEffect(() => {
    selectedDepartmentFilters &&
      setTempDepartmentFilters([...selectedDepartmentFilters]);
    setTempStatusFilters([...selectedStatusFilters]);
  }, [selectedDepartmentFilters, selectedStatusFilters]);

  const loadUserSettings = async () => {
    const shouldPersistFilters =
      getUserConfigValue(PERSIST_FILTERS_KEY, "false") === "true";

    if (shouldPersistFilters) {
      await loadUserConfig(APPOINTMENT_FILTERS_KEY);
      const savedFilters = getUserConfigValue(
        APPOINTMENT_FILTERS_KEY,
        '{"selectedDepartmentFilters":[],"selectedStatusFilters":[]}'
      );

      try {
        const parsedFilters = JSON.parse(savedFilters);
        if (
          parsedFilters.selectedDepartmentFilters &&
          parsedFilters.selectedStatusFilters
        ) {
          setTempDepartmentFilters(parsedFilters.selectedDepartmentFilters);
          setTempStatusFilters(parsedFilters.selectedStatusFilters);
        }
      } catch (error) {
        console.error("Failed to parse saved filters:", error);
      }
    }
  };

  const loadDepartmentCategories = async () => {
    const response = await departmentApi.getDepartments();
    const { data } = response.data;
    const allDepartments = data.flatMap(
      (item: {
        id: number;
        name: string;
        departments: {
          visible: boolean;
        }[];
      }) => {
        return item.departments
          .filter((departmentItem) => departmentItem.visible)
          .map((department) => ({
            ...department,
            categoryId: item.id,
            categoryName: item.name,
          }));
      }
    );

    setDepartmentsData(allDepartments);
  };

  const toggleDepartment = (departmentId: number) => {
    setTempDepartmentFilters((prev) => {
      if (prev.some((item) => item === departmentId)) {
        return prev.filter((item) => item !== departmentId);
      }
      return [...prev, departmentId];
    });
  };

  const toggleStatus = (statusId: number) => {
    setTempStatusFilters((prev) => {
      if (prev.some((item) => item === statusId)) {
        return prev.filter((item) => item !== statusId);
      }
      return [...prev, statusId];
    });
  };

  const clearDepartmentFilters = () => {
    setTempDepartmentFilters([]);
  };

  const clearStatusFilters = () => {
    setTempStatusFilters([]);
  };

  const isDepartmentSelected = (departmentId: number): boolean => {
    return tempDepartmentFilters.some((item) => item === departmentId);
  };

  const isStatusSelected = (statusId: number): boolean => {
    return tempStatusFilters.some((item) => item === statusId);
  };

  const handleApplyOnce = async () => {
    setSelectedDepartmentFilters(tempDepartmentFilters);
    setSelectedStatusFilters(tempStatusFilters);

    await updateUserConfig(PERSIST_FILTERS_KEY, "false");

    onClose();
  };

  const handleApplyAlways = async () => {
    setSelectedDepartmentFilters(tempDepartmentFilters);
    setSelectedStatusFilters(tempStatusFilters);

    await updateUserConfig(PERSIST_FILTERS_KEY, "true");

    const filtersToSave = {
      selectedDepartmentFilters: tempDepartmentFilters,
      selectedStatusFilters: tempStatusFilters,
    };

    await updateUserConfig(
      APPOINTMENT_FILTERS_KEY,
      JSON.stringify(filtersToSave)
    );

    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const statusesData = useMemo(() => {
    return settings;
  }, [settings]);

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>
        필터
        <IconButton onClick={handleClose}>
          <Icon variant="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack gap={"10px"} flexDirection={"row"}>
          <Card className="department-card">
            <div className="header">
              부서{" "}
              <Button
                styled="text"
                disabled={tempDepartmentFilters.length === 0}
                onClick={clearDepartmentFilters}
              >
                필터 해제
              </Button>
            </div>
            <div className="body">
              {departmentsData.map((item, i) => {
                const isActive = isDepartmentSelected(item.id);
                return (
                  <Button
                    styled="outline"
                    color="grey"
                    className={`item-btn ${isActive ? "active" : ""}`}
                    key={`department-${i}`}
                    onClick={() => toggleDepartment(item.id)}
                  >
                    {`${item.categoryName} > ${item.name}`}
                  </Button>
                );
              })}
            </div>
          </Card>
          <Card className="statuses-card">
            <div className="header">
              상태
              <Button
                styled="text"
                disabled={tempStatusFilters.length === 0}
                onClick={clearStatusFilters}
              >
                필터 해제
              </Button>
            </div>
            <div className="body">
              {statusesData.map((item, i) => {
                const isActive = isStatusSelected(item.id);
                return (
                  <Button
                    styled="outline"
                    color="grey"
                    className={`item-btn ${isActive ? "active" : ""}`}
                    key={`status-${i}`}
                    onClick={() => toggleStatus(item.id)}
                  >
                    {item.name}
                  </Button>
                );
              })}
            </div>
          </Card>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection={"row"} gap={"10px"}>
          <Button styled="fill" color="primary" onClick={handleApplyOnce}>
            이번에만 적용
          </Button>
          <Button styled="fill" color="primary" onClick={handleApplyAlways}>
            항상 적용
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default observer(FilterPopup);
