import {
  TextField,
  Popper as MuiPopper,
  textFieldClasses,
  Autocomplete,
  autocompleteClasses,
  inputBaseClasses,
  styled as muiStyled,
} from "@mui/material";
import styled from "@emotion/styled";

const fontSize = "12px";

interface StyledAutocompleteProps {
  isActive?: boolean;
  variant?: "default" | "search" | "none";
}

export const Input = muiStyled(TextField)`
  &.${textFieldClasses.root} {
    width: 100%;
    height: 100%;
    padding-left: 0;

    & input {
      height: 29px;
      box-sizing: border-box;
      padding: 4px 8px;
      font-size: 12px;
      text-align: left;
      line-height: 1rem;
      cursor: pointer;
    }

    & .MuiOutlinedInput-root {
      border-radius: 2px;
      height: 100%;
      padding: 0;
    }
  }
`;

export const Li = styled.li`
  min-height: 29px;
  font-size: ${fontSize};
  padding: 5px 10px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const Popper = muiStyled(MuiPopper)`
  .${autocompleteClasses.listbox} {
    padding: 4px 0;
    max-height: 406px;
  }

  .${autocompleteClasses.paper} {
    box-shadow: none;
    border: 1px solid #dee2ec;
    border-radius: initial;
  }

  .${autocompleteClasses.option} {
    background: none;
    &.Mui-focused {
      background-color: #f1f1f1;
    }
  }

  .${autocompleteClasses.noOptions} {
    font-size: ${fontSize};
    padding: 4px 10px;
    min-height: 100px;
  }
`;

export const AutoCompleteSelect = muiStyled(Autocomplete, {
  shouldForwardProp: (prop) => prop !== "isActive" && prop !== "variant",
})<StyledAutocompleteProps>`
  &.${autocompleteClasses.root} {
    width: 100%;

    & .MuiOutlinedInput-notchedOutline {
      border: ${({ theme, variant, isActive }) =>
        variant === "none"
          ? "none"
          : isActive
          ? `solid 1px ${theme.palette.primary.main}`
          : variant === "search"
          ? "none"
          : `1px solid ${theme.palette.grey[300]}`};
      border-radius: ${({ variant }) => (variant === "search" ? 0 : 2)}px;
    }
  }

  .${autocompleteClasses.input} {
    font-size: ${fontSize};
  }

  .${autocompleteClasses.endAdornment} {
    right: 4px;
    position: relative;
    top: 10px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.grey[700]};

    svg,
    path {
      fill: ${({ theme }) => theme.palette.grey[700]};
    }
  }

  &&& .${inputBaseClasses.root}{
    padding-right: 0;
  }

  .Mui-disabled {
    input {
      background: none;
    }
    button {
      border: 0 none;
    }
  }

  .${autocompleteClasses.clearIndicator} {
    margin-right: -10px;
    color: ${({ theme }) => theme.palette.grey[700]};

    svg,
    path {
      fill: ${({ theme }) => theme.palette.grey[700]};
    }
  }
`;
