import {
  Dialog as MuiDialog,
  styled,
  paperClasses,
  Box as MuiBox,
  Stack as MuiStack,
  dialogActionsClasses,
  dialogTitleClasses,
  dialogContentClasses,
  buttonClasses,
  stackClasses,
  formControlLabelClasses,
  IconButton as MuiIconButton,
  cardClasses,
} from "@mui/material";

export const IconButton = styled(MuiIconButton)(
  () => `
  margin-left: auto;
`
);

export const Dialog = styled(MuiDialog)(
  ({ theme }) => `

  .${paperClasses.root}{
    width: 612px;
  }

  .${dialogTitleClasses.root}{
    display: flex;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    padding: 15px 26px;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.palette.layout.line};
  }

  .${stackClasses.root}{
    height: 100%;
  }
    
  .${paperClasses.root}{
    height: 100%;
    max-height: 720px;
  }

  .${dialogContentClasses.root}{
    padding: 20px 21px 0 !important;
    height: 100%;

    .${cardClasses.root} {
      border: 1px solid ${theme.palette.layout.line};
      box-shadow: none;

      .header{
        padding:13px 20px;
        font-weight: 700;
        font-size: 13px;
        line-height: 15.6px;
        letter-spacing: 0%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid ${theme.palette.layout.line};
      }

      .body {
        padding: 19px 20px;
        height: 100%;
        background:#F9FAFC;
        display: flex;
        flex-direction: column;
        gap: 8px;
        height: calc(100% - 42px);
        overflow-y: auto;

        .item-btn {
          border-radius: 3px;
          min-height: 40px;
          justify-content: flex-start;
          word-break: break-all;
          text-align: left;

          &.active {
            border-color: ${theme.palette.primary["main"]};
            background:#2C62F61A; 
            color: ${theme.palette.primary["main"]};
          }
        }
      }

      .${buttonClasses.text}{
        font-weight: 500;
        font-size: 11px;
        line-height: 13.2px;
        letter-spacing: 0%;
        text-decoration: none;
        min-height: 16px;
        color: ${theme.palette.grey["700"]};

        &.${buttonClasses.disabled} {
          color: ${theme.palette.grey["400"]};
        }

      }
    }
  }

  .${dialogActionsClasses.root}{
    padding: 10px 26px 20px;
    .${stackClasses.root}{
      width: 100%;

      .${buttonClasses.root}{
        height: 54px;
        width: 100%;
        flex-shrink: 1;
      }
    }
    
  }
`
);

export const Box = styled(MuiBox)(
  () => `
  &.checkbox-wrapper {
    margin-left: auto;
    padding-bottom: 35px;
    
    .${formControlLabelClasses.label}{
      font-size: 13px;
    }
  }
`
);

export const Stack = styled(MuiStack)(() => ``);
