import ReactQuill, { ReactQuillProps } from "react-quill";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import "./quill.css";
import "react-quill/dist/quill.snow.css";

interface StyledQuillProps extends ReactQuillProps {
  customStyle?: string;
}

export const StyledReactQuill = styled(ReactQuill)<StyledQuillProps>`
  width: 100%;
  .ql-snow {
    border-color: #dee2ec;
    ${({ customStyle }) =>
      customStyle &&
      css`
        ${customStyle}
      `}
  }
  & .ql-container {
    font-size: 12px;
    height: auto;
  }
`;
