import { ReactComponent as Consulting } from "assets/icons/common/ic-consulting.svg";
import { ReactComponent as Treatment } from "assets/icons/common/ic-treatment.svg";
import { ReactComponent as Operation } from "assets/icons/common/ic-surgery.svg";
import { ReactComponent as SkinCare } from "assets/icons/common/ic-skincare.svg";
import { ReactComponent as Payment } from "assets/icons/common/ic-payment.svg";
import { ReactComponent as AppointmentIcon1 } from "assets/icons/common/ic-appointment.svg";
import { ReactComponent as Registration } from "assets/icons/common/ic-registration.svg";
import { ReactComponent as NoticeIcon } from "assets/icons/ic-sidemenu-notice-key.svg";
import { ReactComponent as Unchecked } from "assets/icons/checkbox.svg";
import { ReactComponent as Checked } from "assets/icons/checkbox-checked.svg";
import { ReactComponent as CloseIcon1 } from "assets/icons/close.svg";
import { ReactComponent as CloseIcon2 } from "assets/icons/ic-close.svg";
import { ReactComponent as AppointmentIcon2 } from "assets/icons/ic-navi-appointment.svg";
import { ReactComponent as FAQIcon } from "assets/icons/ic-navi-faq.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as PencilIcon } from "assets/icons/edit_16_icon.svg";
import { ReactComponent as NullIcon } from "assets/icons/empty_icon.svg";
import { ReactComponent as FolderIcon } from "assets/icons/ic-folder.svg";
import { ReactComponent as RefreschIcon } from "assets/icons/ic-refresh.svg";
import { ReactComponent as DragDefaultIcon } from "assets/icons/drag.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search_icon.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar_icon.svg";
import { ReactComponent as CameraIcon } from "assets/icons/ic-camera.svg";
import { ReactComponent as PhotoIcon } from "assets/icons/ic-photo.svg";
import { ReactComponent as DriveFolderUploadIcon } from "assets/icons/ic-drive-folder.svg";
import { ReactComponent as BackspaceIcon } from "assets/icons/ic-backspace.svg";
import { ReactComponent as ChevronDownIcon } from "assets/icons/chevron_down.svg";
import { ReactComponent as ChevronLeftIcon } from "assets/icons/chevron_left.svg";
import { ReactComponent as ChevronRightIcon } from "assets/icons/chevron_right.svg";
import { ReactComponent as ChevronUpIcon } from "~/assets/icons/chevron_up.svg";
import { ReactComponent as SortIcon } from "assets/icons/ic-sort.svg";
import { ReactComponent as SortArrowIcon } from "~/assets/icons/ic-sorting-arrow.svg";
import { ReactComponent as PenchartSortIcon } from "assets/icons/ic-penchart-sort.svg";
import { ReactComponent as BackArrowIcon } from "assets/icons/ic-backarrow.svg";
import { ReactComponent as ThumbIcon } from "assets/icons/ic-thumb.svg";
import { ReactComponent as SMSNullIcon } from "~/assets/icons/ic-sms-null.svg";
import { ReactComponent as ArrowLeftFillIcon } from "assets/icons/ic-arrow-left-fill-dark.svg";
import { ReactComponent as ArrowRightFillIcon } from "assets/icons/ic-arrow-right-fill-dark.svg";
import { ReactComponent as NaviIcon } from "assets/icons/ic-navi.svg";
import { ReactComponent as SyringeIcon } from "~/assets/icons/ic-syringe.svg";
import { ReactComponent as NaverIcon } from "assets/icons/ic-naver.svg";
import { ReactComponent as FilterIcon } from "assets/icons/ic-filter.svg";
import { ReactComponent as FilterIconOn } from "assets/icons/ic-filter-on.svg";
import { ReactComponent as ErrorFile } from "assets/icons/ic-errorfile.svg";

export type IconProps = {
  variant:
    | "consulting"
    | "treatment"
    | "operation"
    | "skincare"
    | "payment"
    | "appointment"
    | "appointment2"
    | "registration"
    | "notice"
    | "checked"
    | "unchecked"
    | "close"
    | "close2"
    | "chart"
    | "faq"
    | "edit"
    | "pencil"
    | "empty"
    | "folder"
    | "refresh"
    | "drag"
    | "calendar"
    | "search"
    | "photo"
    | "camera"
    | "drive_folder"
    | "backspace"
    | "chevron_down"
    | "chevron_left"
    | "chevron_right"
    | "chevron_up"
    | "back_arrow"
    | "sort"
    | "sort_arrow"
    | "penchart_sort"
    | "thumb"
    | "sms_null"
    | "arrow_left_fill"
    | "arrow_right_fill"
    | "navi"
    | "syringe"
    | "naver"
    | "filter"
    | "filter_on"
    | "errorFile"
    | string;
  [key: string]: any;
};
const Icon = ({ variant, ...rest }: IconProps) => {
  switch (variant) {
    case "consulting":
      return <Consulting {...rest} />;
    case "treatment":
      return <Treatment {...rest} />;
    case "operation":
      return <Operation {...rest} />;
    case "skincare":
      return <SkinCare {...rest} />;
    case "payment":
      return <Payment {...rest} />;
    case "appointment":
      return <AppointmentIcon1 {...rest} />;
    case "appointment2":
      return <AppointmentIcon2 {...rest} />;
    case "registration":
      return <Registration {...rest} />;
    case "notice":
      return <NoticeIcon {...rest} />;
    case "checked":
      return <Checked {...rest} />;
    case "unchecked":
      return <Unchecked {...rest} />;
    case "close":
      return <CloseIcon1 {...rest} />;
    case "close2":
      return <CloseIcon2 {...rest} />;
    case "faq":
      return <FAQIcon {...rest} />;
    case "edit":
      return <EditIcon {...rest} />;
    case "pencil":
      return <PencilIcon {...rest} />;
    case "empty":
      return <NullIcon {...rest} />;
    case "folder":
      return <FolderIcon {...rest} />;
    case "refresh":
      return <RefreschIcon {...rest} />;
    case "drag":
      return <DragDefaultIcon {...rest} />;
    case "calendar":
      return <CalendarIcon {...rest} />;
    case "search":
      return <SearchIcon {...rest} />;
    case "photo":
      return <PhotoIcon {...rest} />;
    case "camera":
      return <CameraIcon {...rest} />;
    case "drive_folder":
      return <DriveFolderUploadIcon {...rest} />;
    case "backspace":
      return <BackspaceIcon {...rest} />;
    case "chevron_down":
      return <ChevronDownIcon {...rest} />;
    case "chevron_left":
      return <ChevronLeftIcon {...rest} />;
    case "chevron_right":
      return <ChevronRightIcon {...rest} />;
    case "chevron_up":
      return <ChevronUpIcon {...rest} />;
    case "back_arrow":
      return <BackArrowIcon {...rest} />;
    case "sort":
      return <SortIcon {...rest} />;
    case "sort_arrow":
      return <SortArrowIcon {...rest} />;
    case "penchart_sort":
      return <PenchartSortIcon {...rest} />;
    case "thumb":
      return <ThumbIcon {...rest} />;
    case "sms_null":
      return <SMSNullIcon {...rest} />;
    case "arrow_left_fill":
      return <ArrowLeftFillIcon {...rest} />;
    case "arrow_right_fill":
      return <ArrowRightFillIcon {...rest} />;
    case "navi":
      return <NaviIcon {...rest} />;
    case "syringe":
      return <SyringeIcon {...rest} />;
    case "naver":
      return <NaverIcon {...rest} />;
    case "filter":
      return <FilterIcon {...rest} />;
    case "filter_on":
      return <FilterIconOn {...rest} />;
    case "errorFile":
      return <ErrorFile {...rest} />;
    default:
      return <></>;
  }
};

export default Icon;
