import { useState } from "react";
import { NormalModal } from "~/modals/common/NormalModal";
import {
  Box,
  Stack,
  BlueTextBox,
  TextFieldBox,
} from "modals/CustomerDeleteConfirmModal.styled";
import { Button } from "~/components/Button";
import { TextInput } from "components/TextInput";
import { CustomerType } from "~/type/customer";

export const CustomerDeleteConfirmModal = ({
  onClose,
  onConfirm,
  customer,
}: {
  onClose: () => void;
  onConfirm: () => void;
  customer: CustomerType;
}) => {
  const [confirmText, setConfirmText] = useState<string>("");

  const handleChange = (value: string) => {
    setConfirmText(value);
  };

  return (
    <NormalModal
      title="고객 삭제 시 주의사항"
      open={true}
      onClose={onClose}
      footer={
        <>
          <Button color="mix" styled="outline" onClick={onClose}>
            취소
          </Button>
          <Button disabled={confirmText !== "복구불가"} onClick={onConfirm}>
            삭제
          </Button>
        </>
      }
    >
      <Box>
        <Box>고객 삭제 시 고객 정보와 모든 차트 정보가 함께 삭제됩니다.</Box>
        <Box>
          의료법상 보존의무가 있는 진료기록을 포함한 경우 삭제에 대한 책임은
          귀원에 있으니 <br />
          주의바랍니다.(의료법 제 22조 2항)
        </Box>
        <Stack>
          <BlueTextBox>
            {customer.name ?? "-"} ({customer.chartNo})
          </BlueTextBox>
          &nbsp;고객을 삭제하시겠습니까?
        </Stack>
        <Stack>
          삭제 후에는
          <TextFieldBox className="m-l-r-5">
            <TextInput
              value={confirmText}
              placeholder="복구불가"
              onChange={(value: string) => handleChange(value)}
            />
          </TextFieldBox>
          합니다.
        </Stack>
      </Box>
    </NormalModal>
  );
};
