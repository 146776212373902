import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { ChartCard } from "./ChartCard";
import { Wrapper, EmptyText, DescriptionText } from "./ChartHistoryItem.styled";
import { useApi } from "providers/ApiProvider";
import { InfinityScrollWrapper } from "components/InfinityScrollWrapper";

export const ChartHistoryItems = observer(({ customer }: { customer: any }) => {
  const limit = 20;
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const { sessionApi } = useApi();

  const [params, setParams] = useState({
    customerId: customer?.id,
    orderBy: "date desc",
    page: 1,
    limit,
    progresses: "CLOSED,OPEN",
  });

  const loadApi = async () => {
    const response = await sessionApi.getSessionsHistories(params);
    const payload = await response.data;
    if (params.page < Math.ceil(payload?.pagination.total / limit)) {
      setParams({
        ...params,
        page: params.page + 1,
      });
    }
    setData([...data, ...payload.data]);
    setTotal(payload.pagination.total);
  };

  useEffect(() => {
    loadApi();
  }, []);

  const updateMoreViewLimit = () => {
    if (data.length <= total) {
      loadApi();
    }
  };

  return (
    <Wrapper id="chartItemsDiv">
      <InfinityScrollWrapper
        targetId={"chartItemsDiv"}
        dataLength={data.length}
        next={updateMoreViewLimit}
        hasMore={data.length < total}
      >
        {data.length > 0 ? (
          data.map((item, i) => <ChartCard key={i} item={item} />)
        ) : (
          <EmptyText>
            <DescriptionText>{"조회 결과가 없습니다."}</DescriptionText>
          </EmptyText>
        )}
      </InfinityScrollWrapper>
    </Wrapper>
  );
});
