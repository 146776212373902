import { makeAutoObservable, runInAction } from "mobx";
import { apiClient } from "~/core/apiClient";
import * as Sentry from "@sentry/react";

export const APPOINTMENT_FILTERS_KEY = "appointmentFilters";
export const PERSIST_FILTERS_KEY = "persistAppointmentFilters";

class UserStore {
  public user: any = null;
  public userConfig: Record<string, any> = {};

  constructor() {
    makeAutoObservable(this);
  }

  setUser = (userData: any) => {
    runInAction(() => {
      this.user = userData;

      if (userData) {
        Sentry.setContext("info", {
          clinic_name: userData.clinic.name,
          clinic_id: userData.clinic.id,
          name: userData.name,
          user_id: userData.id,
          email: userData.email,
        });
      }
    });
  };

  clearUser = () => {
    runInAction(() => {
      this.user = null;
    });
  };

  loadUserConfig = async (key: string) => {
    try {
      const response = await apiClient.userApi.getConfig(key);

      if (response?.data) {
        runInAction(() => {
          this.userConfig = {
            ...this.userConfig,
            [key]: response.data.data,
          };
        });
      }
    } catch (error) {
      console.error(`Error loading user config for key ${key}:`, error);
    }
  };

  updateUserConfig = async (key: string, value: string) => {
    try {
      const response = await apiClient.userApi.updateConfig(key, value);

      if (response?.data) {
        runInAction(() => {
          this.userConfig = {
            ...this.userConfig,
            [key]: response.data.data,
          };
        });
      }
    } catch (error) {
      console.error(`Error updating user config for key ${key}:`, error);
    }
  };

  getUserConfigValue = (key: string, defaultValue: any = null) => {
    return this.userConfig[key]?.value ?? defaultValue;
  };

  hasPermission = (code: string): boolean => {
    if (!this.user || !this.user.role || !this.user.role.permissions)
      return false;
    return (
      this.user.role.permissions.find((v: any) => v.code === code) &&
      this.user.role.permissions.find((v: any) => v.code === code).allow ===
        "ON"
    );
  };
}

export default new UserStore();
