import styled from "@emotion/styled";
import { EmptyView as _EmptyView } from "components/EmptyView";

export const EmptyView = styled(_EmptyView)(
  () => `
  min-height: calc(100vh - 262px);
`
);

export const SortColumnWrapper = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
