import { ReactNode } from "react";
import styled from "@emotion/styled";

const borderColor = "#dee2ec";
const PlaceHolderCell = styled.tr`
  height: 42px;
  background: #f9fbff;
  color: #a1b1ca;
  font-size: 14px;
  text-align: center;

  height: 110px;
  font-weight: 700;
  background-color: #ffffff;
  color: #9cb2cd;
  border: 1px solid;
  border-color: ${borderColor};

  &&& td {
    padding: 9px;
  }
`;

type PlaceHolderProps = {
  children: ReactNode;
  styleType: "chart";
};

export const PlaceHolder = ({ children }: PlaceHolderProps) => {
  return (
    <PlaceHolderCell>
      <td colSpan={100}>{children}</td>
    </PlaceHolderCell>
  );
};
