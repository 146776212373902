import { useMemo, forwardRef, useState, useEffect, useCallback } from "react";
import { getOptions } from "~/hooks/useFormOptions";
import { DropdownList as _DropdownList } from "components/DropdownList";
import { styled } from "@mui/material";
import { useApi } from "providers/ApiProvider";

export const DropdownList = styled(_DropdownList)(
  ({ theme }) => `
  height: 34px;
  
  input {
    padding-left: 20px !important;
    font-size: 14px !important;
    &::placeholder {
      color: ${theme.palette.bluegrey["600"]};
      opacity: 1;
    }
  }
  .MuiOutlinedInput-root {
    border-radius: 4px !important;
    font-size: 14px !important;
  }
  fieldset {
    border-color: ${theme.palette.layout.line};
  }
`
);

type CreatedSelectProps = {
  value: any;
  onChange: (value: any) => void;
  disableClearable?: boolean;
};

const CreatedSelect = forwardRef(
  ({ value, onChange, disableClearable }: CreatedSelectProps, ref) => {
    const { userApi } = useApi();
    const [createdList, setCreatedList] = useState();

    const loadCreated = useCallback(async () => {
      const res = await userApi.getDuty("");
      const payload = await res.data;

      setCreatedList(payload?.data);
    }, [userApi]);

    useEffect(() => {
      loadCreated();
    }, [loadCreated]);

    const options = useMemo(() => {
      const data = createdList ?? [];
      if (!value || !value.id) return data;

      const extraOption = { ...value };
      if (
        (value.status === "inactive" || value.visible === false) &&
        !data.find((f: any) => f.id === value.id)
      ) {
        extraOption.visible = false;
      } else if (
        (value.status === "deleted" ||
          value?.isDeleted === true ||
          value?.deletedAt !== null) &&
        !data.find((f: any) => f.id === value.id)
      ) {
        extraOption.isDeleted = true;
      }

      if (data.find((f: any) => f.id === value.id)) {
        return data;
      } else {
        return getOptions(data, extraOption);
      }
    }, [createdList, value]);

    return (
      <>
        <DropdownList
          options={options.map((item) => {
            item.label = item.name;
            return item;
          })}
          value={options.find((f: any) => f.id === value?.id)}
          onChange={(v) => {
            onChange(v ? v : null);
          }}
          placeholder="작성자를 선택하세요."
          disableClearable={disableClearable}
        />
      </>
    );
  }
);

export default CreatedSelect;
