import { createContext, useEffect, useMemo, useState, useContext } from "react";
import { NavPage } from "../Nav/NavPage";
import { Info } from "pages/AppointmentPage/list/Info";
import { List } from "pages/AppointmentPage/list/List";
import { ControlHeader } from "pages/AppointmentPage/list/Header";
import { useAppointment } from "hooks/useAppointment";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { Wrapper } from "pages/AppointmentPage/AppointmentPage.styled";
import { observer } from "mobx-react";
import { useImperativeModal } from "ImperativeModalProvider";
import { useClinic } from "providers/ClinicProvider";
import { useAppointmentsStore } from "~/hooks/useAppointmentsStore";
import FilterPopup from "~/modals/FilterPopup";
import { useUser } from "~/providers/UserProvider";
import {
  PERSIST_FILTERS_KEY,
  APPOINTMENT_FILTERS_KEY,
} from "~/store/userStore";

type Department = {
  id: number;
  name: string;
  [key: string]: any;
};

type AppointmentType = {
  registrationType: string;
  status: string;
  visitType: string;
  deletedAt: string | null;
  department: Department;
  [key: string]: any;
};

type AppointmentStats = {
  [key: string]: number;
};

export type AppointmentContextType = {
  appointment: {
    appointments: AppointmentType[];
    departments: Department[];
    count: AppointmentStats;
    reload: () => void;
    onChangeStatus: (appointmentId: number | string, status: string) => void;
    setDepartmentId: (departmentId: number | null) => void;
    setPage: (page: number) => void;
    updateDepartmentIds: (departmentIds: number[]) => void;
  };
  appointments: AppointmentType[];
  departments: Department[];
  allDepartments: Department[];
  stats: AppointmentStats;
  date: Date;
  setDate: (date: Date) => void;
  departmentId: string | null;
  setDepartmentId: (departmentId: number | null) => void;
  countFilter: string;
  setCountFilter: (filter: string) => void;
  openFilterPopup: () => Promise<void>;
  reload: () => void;
  onChangeStatus: (appointmentId: number | string, status: string) => void;
  updateOrder: (order: string, target?: string) => void;
  updatePage: (page: number) => void;
};

export const AppointmentContext = createContext<null | AppointmentContextType>(
  null
);

export const AppointmentPage = observer(() => {
  const clinic = useClinic();
  const { loadUserConfig, getUserConfigValue } = useUser();
  const [searchParams, setSearchParams] = useSearchParams({
    date: format(new Date(), "yyyy-MM-dd"),
  });
  const imperativeModal = useImperativeModal();
  const {
    countFilter,
    selectedDepartmentFilters,
    selectedStatusFilters,
    setCountFilter,
    setSelectedDepartmentFilters,
    setSelectedStatusFilters,
  } = useAppointmentsStore();

  const [filtersLoaded, setFiltersLoaded] = useState(false);

  useEffect(() => {
    loadSavedFilters();
  }, []);

  const loadSavedFilters = async () => {
    await Promise.all([
      loadUserConfig(PERSIST_FILTERS_KEY),
      loadUserConfig(APPOINTMENT_FILTERS_KEY),
    ]);

    const configValue = await getUserConfigValue(
      APPOINTMENT_FILTERS_KEY,
      '{"selectedDepartmentFilters":[],"selectedStatusFilters":[]}'
    );
    const { selectedDepartmentFilters, selectedStatusFilters } =
      JSON.parse(configValue);

    setSelectedDepartmentFilters(selectedDepartmentFilters);
    setSelectedStatusFilters(selectedStatusFilters);
    setFiltersLoaded(true);
  };

  const date = useMemo(
    () =>
      searchParams.get("date")
        ? new Date(searchParams.get("date") as string)
        : new Date(),
    [searchParams]
  );

  const departmentId = useMemo(
    () =>
      searchParams.get("departmentId")
        ? searchParams.get("departmentId")
        : null,
    [searchParams]
  );

  const appointment = useAppointment({
    date,
    customerId: null,
    initOrder: { order: "asc", target: "startHour" },
    departmentId,
    departmentIds: selectedDepartmentFilters,
    status: selectedStatusFilters,
    countFilter,
    clinic: clinic.clinic,
    skipInitialLoad: !filtersLoaded,
  });

  const allDepartments = useMemo(() => {
    return appointment.departments;
  }, [appointment.departments]);

  const filteredDepartments = useMemo(() => {
    if (selectedDepartmentFilters && selectedDepartmentFilters.length === 0) {
      return allDepartments;
    }
    return allDepartments.filter(
      (department: any) =>
        selectedDepartmentFilters &&
        selectedDepartmentFilters.includes(department.id)
    );
  }, [allDepartments, selectedDepartmentFilters]);

  const setDate = (d: any) => {
    setSearchParams(
      {
        ...searchParams,
        date: format(new Date(d), "yyyy-MM-dd"),
      },
      {
        replace: true,
      }
    );
    appointment.setDepartmentId(null);
    appointment.setPage(1);
  };

  const setDepartmentId = (departmentId: null | number) => {
    setSearchParams(
      {
        ...searchParams,
        date: format(new Date(date), "yyyy-MM-dd"),
        departmentId: String(departmentId),
      },
      {
        replace: true,
      }
    );
    appointment.setDepartmentId(departmentId);
  };

  useEffect(() => {
    if (
      departmentId === null &&
      selectedDepartmentFilters &&
      selectedDepartmentFilters.length > 0
    ) {
      appointment.updateDepartmentIds(selectedDepartmentFilters);
    }
  }, [selectedDepartmentFilters, departmentId]);

  const handleFilterPopup = async () => {
    await imperativeModal.open((close) => <FilterPopup onClose={close} />);
  };

  const appointments = useMemo<AppointmentType[]>(() => {
    return appointment.appointments;
  }, [appointment.appointments]);

  const stats = useMemo(() => {
    return appointment.count;
  }, [appointment.count]);

  return (
    <AppointmentContext.Provider
      value={{
        appointment,
        appointments,
        departments: filteredDepartments,
        allDepartments,
        stats,
        date,
        setDate,
        departmentId,
        setDepartmentId,
        countFilter,
        setCountFilter,
        openFilterPopup: handleFilterPopup,
        reload: appointment.reload,
        onChangeStatus: appointment.onChangeStatus,
        updateOrder: appointment.updateOrder,
        updatePage: appointment.updatePage,
      }}
    >
      <NavPage title={<ControlHeader />}>
        <Wrapper>
          <Info />
          <List />
        </Wrapper>
      </NavPage>
    </AppointmentContext.Provider>
  );
});

export const useAppointmentContext = () => {
  return useContext(AppointmentContext);
};
