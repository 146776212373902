import { ComponentType } from "react";
import styled from "@emotion/styled";
import {
  Autocomplete as MuiAutocomplete,
  TextField as MuiTextField,
  autocompleteClasses,
  Popper as MuiPopper,
  textFieldClasses,
  AutocompleteProps,
} from "@mui/material";
import { Option } from "./ComboBox";

const fontSize = "12px";

interface TextFieldProps {
  isOpen?: boolean;
}

type CustomAutocompleteProps<T> = Omit<
  AutocompleteProps<T, boolean, boolean, boolean>,
  "onChange"
> & {
  onChange: (
    _event: React.SyntheticEvent,
    newValue: Option[],
    reason: string
  ) => void;
};

type RestrictedAutocompleteProps = CustomAutocompleteProps<Option> & {
  variant?: "default" | "search";
  isActive?: boolean;
};

export const TextField = styled(MuiTextField, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})<TextFieldProps>`
  &.${textFieldClasses.root} {
    width: 100%;
    height: 100%;
    padding-left: 0;

    & input {
      height: 29px;
      box-sizing: border-box;
      padding: 4px 8px;
      font-size: 12px;
      text-align: left;
      line-height: 1rem;
      margin-right: 20px;
    }

    & .MuiOutlinedInput-root {
      position: absolute;
      padding: 0 !important;
      height: ${({ isOpen }) => (!isOpen ? "29px" : "")};
      overflow: ${({ isOpen }) => (!isOpen ? "clip" : "")};
      background: ${({ isOpen }) => (isOpen ? "white" : "")};
    }

    & .MuiInputBase-root.Mui-focused {
      z-index: 2;
    }
  }
`;

export const Li = styled.li`
  min-height: 29px;
  font-size: ${fontSize};
  padding: 5px 10px !important;
`;

export const IconWrapper = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  svg,
  path {
    width: 16px;
    height: 16px;
    fill: ${theme.palette.grey["700"]};
  }
`
);

export const SelectLabel = styled.div(
  ({ theme }) => `
    width: 100%;
    cursor: default;
    font-size: 11px;
    color: ${theme ? theme.palette.bluegrey[600] : ""};
`
);

export const Total = styled.span`
  font-size: ${fontSize};
  color: #a1b1ca;
`;

export const AutoCompleteSelect = styled(
  MuiAutocomplete as ComponentType<RestrictedAutocompleteProps>,
  {
    shouldForwardProp: (prop) =>
      !["variant", "isActive"].includes(prop as string),
  }
)`
  &.${autocompleteClasses.root} {
    & .MuiOutlinedInput-notchedOutline {
      border: ${({ variant, isActive }: any) =>
        isActive
          ? "solid 1px #2C62F6 !important"
          : variant === "search"
          ? "none !important"
          : ""};
      border-radius: ${({ variant }) => variant === "search" && 0};
    }

    & .MuiOutlinedInput-root {
      padding: 0 !important;
    }

    & .MuiInputBase-root.Mui-disabled {
      background-color: #f1f1f1 !important;
      color: #273142 !important;
    }

    & .MuiInputBase-root.Mui-disabled input {
      background-color: #f1f1f1 !important;
      color: #bbbbbb !important;
    }
  }

  .${autocompleteClasses.input} {
    font-size: ${fontSize};
  }

  .${autocompleteClasses.noOptions} {
    font-size: ${fontSize};
    padding: 10px !important;
    min-height: 100px;
  }

  .${autocompleteClasses.endAdornment} {
    right: 8px;
    top: 17px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .${autocompleteClasses.clearIndicator} {
    margin-right: 0;
  }

  .${autocompleteClasses.paper} {
    box-shadow: none !important;
    border: 1px solid #dee2ec;
    border-radius: initial !important;
  }

  .${autocompleteClasses.option} {
    background: white;
    &.Mui-focused {
      background-color: #f1f1f1;
    }
  }
`;

export const Popper = styled(MuiPopper)`
  .${autocompleteClasses.paper} {
    box-shadow: none !important;
    border: 1px solid #dee2ec;
    border-radius: initial !important;
  }
  .${autocompleteClasses.option} {
    background: none !important;
    &.Mui-focused {
      background-color: #f1f1f1 !important;
    }
  }
  .${autocompleteClasses.noOptions} {
    font-size: ${fontSize};
    padding: 10px !important;
    min-height: 100px;
  }
`;
