import styled from "@emotion/styled";
import {
  TextField as MuiTextField,
  InputAdornment,
  styled as muiStyled,
  css,
} from "@mui/material";
import { ComponentType } from "react";

interface StyledInputProps {
  inputVariant?: "search" | "default";
  isActive?: boolean;
  hasBorder?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2px;
  width: 100%;
`;

export const StyledInput = styled(MuiTextField, {
  shouldForwardProp: (prop) =>
    !["type", "isActive", "hasBorder", "inputVariant"].includes(prop as string),
})<StyledInputProps>`
  background: white;
  border: 0;
  width: 100%;
  > * {
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: 29px;
  }
  input {
    font-size: 12px;
    height: 29px;
    text-overflow: ellipsis;
    ${({ inputVariant }) =>
      inputVariant !== "search"
        ? css`
            padding: 0 8px;
          `
        : css`
            padding: 0 4px 0 0;
          `}
  }

  .MuiOutlinedInput-notchedOutline {
    ${({ inputVariant }) =>
      inputVariant === "search" &&
      css`
        border: none;
        border-radius: 0;
      `}
    ${({ isActive, theme }) =>
      isActive &&
      css`
        border: solid 1px ${theme.palette.unoblue};
      `}

    ${({ hasBorder, theme }) =>
      hasBorder &&
      css`
        border: solid 1px ${theme.palette.bluegrey[600]};
      `}
  }
`;

export const EndAdornmentWrapper = muiStyled("div")(
  ({ theme, $disabled }: { theme: any; $disabled: boolean }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  color: ${theme.palette.grey["700"]};

  svg,path {
    fill:  ${theme.palette.grey["700"]};
  }
  
  background-color: ${$disabled ? theme.palette.bluegrey[200] : ""};
  `
) as ComponentType<any>;

export const InputStartAdornment = styled(InputAdornment)`
  &.MuiInputAdornment-outlined {
    & p {
      font-size: 12px;
      width: 64px;
      padding-left: 8px;
      word-break: keep-all;
      line-height: 1rem;
      color: #2d2d2d;
    }
  }
`;

export const InputEndAdornment = muiStyled(InputAdornment)(
  ({ theme }) => `
  &.MuiInputAdornment-outlined {
    margin-left: 0;
    margin-right: 4px;
  }
  color: ${theme.palette.grey["700"]};

  svg,path {
    fill:  ${theme.palette.grey["700"]};
  }
`
);
