import { useMemo } from "react";
import { IconButton } from "@mui/material";
import { format } from "date-fns";
import Icon from "components/Icon";
import { FileData } from "core/resources/filesApi";
import { ageText } from "utils/filters";
import { Box, Stack, Typography } from "./FileEditPageTitle.styled";

type TitleProps = {
  files: FileData[];
  file: FileData;
  totalCount: number;
  customer?: any;
  onNavigate: (value: any) => void;
  onChangeExpanded: () => void;
};

const FileEditPageTitle = ({
  files,
  file,
  totalCount,
  customer,
  onNavigate,
  onChangeExpanded,
}: TitleProps) => {
  const title = file?.name ?? "";

  const fileIndex = useMemo(() => {
    return file?.id && files.length
      ? files.findIndex((f) => f.id === file.id)
      : -1;
  }, [files, file?.id]);

  if (!files || !file)
    return (
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        {title}
      </Typography>
    );

  const handleClickTitle = () => {
    onChangeExpanded();
  };

  const handleClickPrev = (e: React.MouseEvent) => {
    e.stopPropagation();
    const prevFile = files[fileIndex - 1];
    if (prevFile) {
      onNavigate(prevFile);
    }
  };

  const handleClickNext = (e: React.MouseEvent) => {
    e.stopPropagation();
    const nextFile = files[fileIndex + 1];
    if (nextFile) {
      onNavigate(nextFile);
    }
  };

  return (
    <Stack flexDirection={"column"}>
      <Box className="title-wrapper">
        <IconButton
          disabled={totalCount === 0 || fileIndex === 0}
          onClick={handleClickPrev}
        >
          <Icon variant="arrow_left_fill" />
        </IconButton>
        <Stack
          flexDirection="row"
          justifyContent="center"
          gap="4px"
          onClick={handleClickTitle}
        >
          <Typography className="title" variant="h6">
            {title}
          </Typography>
          <Box className="file-count">
            ({fileIndex + 1}/{totalCount})
          </Box>
        </Stack>
        <IconButton
          disabled={totalCount === 1 || fileIndex + 1 === totalCount}
          onClick={handleClickNext}
        >
          <Icon variant="arrow_right_fill" />
        </IconButton>
      </Box>
      {customer && (
        <Box className="customer-info" onClick={handleClickTitle}>
          {`${customer?.name || "-"}(${customer.chartNo || "-"}/${
            customer.sex === "male" ? "M" : "F"
          }/${ageText(customer)}/${
            customer.birthday
              ? format(new Date(customer.birthday), "yyMMdd")
              : "-"
          }/${
            customer.type === "domestic"
              ? "내국인"
              : customer.type === "foreigner"
              ? "외국인"
              : "-"
          })`}
        </Box>
      )}
    </Stack>
  );
};

export default FileEditPageTitle;
