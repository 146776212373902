import {
  useState,
  useEffect,
  useMemo,
  useRef,
  ChangeEvent,
  MouseEvent,
} from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { AuthError, useAuth } from "providers/AuthProvider";
import { useSnackbarContext } from "~/SnackbarProvider_v2";
import defaultLogo from "assets/images/uno_logo.svg";
import { CheckboxLabel } from "components/Form/CheckboxLabel";
import {
  Wrapper,
  Body,
  Form,
  FieldWrapper,
  Input,
  WarningWrapper,
  WarningText,
  Box,
  Button,
  Divider,
} from "./LoginPage.styled";
import { Footer } from "./components/Footer";
import { checkEmailFormat } from "utils/filters";
import ConfirmCertNumber from "./components/ConfirmCertNumber";
import { AlertModal } from "modals/AlertModal";
import { ConfirmModal } from "~/components/Modal/ConfirmModal";
import { DefaultModal } from "components/Modal/DefaultModal";
import { useImperativeModal } from "ImperativeModalProvider";
import { useApi } from "providers/ApiProvider";

const getErrorDesc = (reason: string) => {
  switch (reason) {
    case AuthError.ACCESS_RESTRICTED:
      return "현재 접속한 IP에서는 서비스를 이용할 수 없습니다. 관리자에게 문의하세요.";
    case AuthError.UNAUTHORIZED:
      return "인증이 만료되었습니다. 다시 로그인하세요.";
    default:
      return "";
  }
};

const RESET_TRIGGER_VALUE = "00000000**";
const LAST_UPDATED_VERSION_KEY = "last_updated_version";

const resetServiceWorker = async () => {
  try {
    if ("serviceWorker" in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();

      for (const registration of registrations) {
        await registration.unregister();
      }

      if ("caches" in window) {
        const cacheNames = await caches.keys();
        await Promise.all(
          cacheNames.map((cacheName) => caches.delete(cacheName))
        );
      }

      localStorage.removeItem(LAST_UPDATED_VERSION_KEY);
      sessionStorage.setItem("app_resetting", "true");

      setTimeout(() => {
        window.location.href =
          window.location.href.split("?")[0] +
          "?refresh=" +
          new Date().getTime();
      }, 100);

      return true;
    }
    return false;
  } catch (error) {
    console.error("Service worker reset error:", error);
    return false;
  }
};

export function LoginPage() {
  const navigate = useNavigate();
  const auth = useAuth();
  const snackbar = useSnackbarContext();
  const [step, setStep] = useState(1);
  const { error } = auth;
  const imperativeModal = useImperativeModal();
  const { popupsApi } = useApi();
  const [autoLogin, setAutoLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [logo, setLogo] = useState<null | { image: { originalUrl: string } }>(
    null
  );
  const [openModal, setOpenModal] = useState(false);
  const [password, setPassword] = useState("");
  const [showAccessWarningText, setShowAccessWarningText] = useState(false);
  const [showResetButton, setShowResetButton] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const trimmedEmail = useMemo(() => email.trim(), [email]);
  const passwordRef = useRef();

  // 리셋 버튼 표시 조건 확인
  useEffect(() => {
    setShowResetButton(
      trimmedEmail === RESET_TRIGGER_VALUE && password === RESET_TRIGGER_VALUE
    );
  }, [trimmedEmail, password]);

  useEffect(() => {
    getLogo();
  }, []);

  useEffect(() => {
    if (!error) return;
    getErrorDesc(error) && snackbar.alert(getErrorDesc(error));
  }, [error, snackbar]);

  const validate = () => {
    if (!trimmedEmail) {
      throw new Error("아이디(이메일)를 입력해주세요.");
    }
    if (!password) {
      throw new Error("비밀번호를 입력해주세요.");
    }

    if (!checkEmailFormat(trimmedEmail) || password.length < 8) {
      throw new Error(
        "등록되지 않은 아이디거나 로그인 정보가 일치하지 않습니다. 고객센터로 문의해 주세요."
      );
    }
  };

  const getLogo = async () => {
    const response = await popupsApi.getPopupList();
    const payload = await response.data;
    if (payload.data) {
      const logo = payload.data.find(
        (popup: { type: "logo" }) => popup.type === "logo"
      );
      setLogo(logo || null);
    }
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleClickSignin = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleSubmit();
  };

  const handleResetServiceWorker = async () => {
    if (isResetting) return;

    setIsResetting(true);
    try {
      await imperativeModal.open((close) => (
        <ConfirmModal
          title="앱 초기화"
          body={
            <>
              앱 데이터를 초기화하시겠습니까?
              <br />
              초기화 후 앱이 재시작됩니다.
            </>
          }
          cancelText="취소"
          confirmText="초기화"
          onClose={() => {
            setIsResetting(false);
            close();
          }}
          onConfirm={() => {
            resetServiceWorker();
            close();
          }}
          open={true}
        />
      ));
    } catch (e) {
      setIsResetting(false);
      snackbar.alert("앱 초기화 중 오류가 발생했습니다.");
    }
  };

  useEffect(() => {
    const isAppResetting = sessionStorage.getItem("app_resetting");

    if (isAppResetting === "true") {
      sessionStorage.removeItem("app_resetting");
      snackbar.success("앱이 성공적으로 초기화되었습니다.");

      if (
        trimmedEmail === RESET_TRIGGER_VALUE &&
        password === RESET_TRIGGER_VALUE
      ) {
        setIsResetting(false);
      }
    }
  }, [snackbar, trimmedEmail, password]);

  async function handleSubmit() {
    if (
      trimmedEmail === RESET_TRIGGER_VALUE &&
      password === RESET_TRIGGER_VALUE
    ) {
      return;
    }

    try {
      validate();
    } catch (e: any) {
      snackbar.alert(e.message);
      return;
    }

    try {
      const response = await auth.signin(trimmedEmail, password, autoLogin);
      if (response === "certUser") {
        setStep(2);
      } else if (response === "manualUser") {
        navigate("/", { replace: true });
      }
    } catch (e: any) {
      if (e.code === 403 && e.name === "ACCESS_RESTRICTED") {
        return setShowAccessWarningText(true);
      } else if (e.code === 403 && e.name === "ACCESS_DENIED") {
        return await imperativeModal.open((close) => (
          <AlertModal onClose={close} bgClickCancel={true}>
            비밀번호 5회 오류로 로그인을 할 수 없습니다.
            <br />
            병원 관리자에게 비밀번호 초기화를 요청하세요.
          </AlertModal>
        ));
      } else if (e.code === 403 && e.name === "OWNER_ACCESS_DENIED") {
        return await imperativeModal.open((close) => (
          <AlertModal onClose={close} bgClickCancel={true}>
            비밀번호 5회 오류로 로그인을 할 수 없습니다.
            <br />
            고객센터에 비밀번호 초기화를 요청하세요.
          </AlertModal>
        ));
      } else if (e.code === 401) {
        return snackbar.alert(
          "등록되지 않은 아이디거나 로그인 정보가 일치하지 않습니다. 고객센터로 문의해 주세요."
        );
      }
      snackbar.alert(
        "일시적인 오류로 로그인할 수 없습니다. 고객센터로 문의해 주세요."
      );
    }
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Wrapper>
      {step === 1 ? (
        <Body className={`login-form-wrapper ${isMobile ? "mobile" : ""}`}>
          <Box className="logo-image-wrapper">
            <img
              draggable="false"
              src={logo && logo.image ? logo.image.originalUrl : defaultLogo}
              alt={`고객을 관리하는 가장 좋은 선택 "UNO CRM"`}
            />
          </Box>
          <Form>
            <FieldWrapper>
              <label htmlFor="email-input">이메일</label>
              <Input
                id="email-input"
                name="email"
                type="text"
                value={email}
                onChange={handleChangeEmail}
                placeholder="이메일을 입력해주세요"
              />
            </FieldWrapper>
            <FieldWrapper className="mt-24">
              <label htmlFor="password-input">비밀번호</label>
              <Input
                type="password"
                name="password"
                value={password}
                inputRef={passwordRef}
                onChange={handleChangePassword}
                placeholder="●●●●●●●●"
              />
            </FieldWrapper>
            <Box className="auto-login-option">
              <CheckboxLabel
                text="자동로그인"
                checked={autoLogin}
                onChange={setAutoLogin}
              />
            </Box>
            {showResetButton ? (
              <Button
                variant="contained"
                className="service-worker-reset-btn"
                onClick={handleResetServiceWorker}
                disabled={isResetting}
                style={{ marginBottom: "10px" }}
              >
                {isResetting ? "초기화 중..." : "앱 초기화"}
              </Button>
            ) : null}
            <Button
              variant="contained"
              className="login-btn"
              onClick={handleClickSignin}
            >
              로그인
            </Button>

            <Box className="company-info-wrapper">
              <Box
                component={"span"}
                className="company-name"
                onClick={handleOpenModal}
              >
                주식회사 케어랩스
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box component={"span"}>
                <a
                  target="_blank"
                  href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=2208836643"
                  rel="noreferrer"
                >
                  사업자정보확인
                </a>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box component={"span"}>
                <a
                  target="_blank"
                  href="https://www.carecrm.co.kr/privacy"
                  rel="noreferrer"
                >
                  개인정보처리방침
                </a>
              </Box>
            </Box>
            {showAccessWarningText && (
              <WarningWrapper>
                <WarningText>
                  현재 접속한 IP에서는 서비스를 이용할 수 없습니다.
                </WarningText>
                <WarningText>관리자에게 문의하세요.</WarningText>
              </WarningWrapper>
            )}
          </Form>
          <DefaultModal
            open={openModal}
            title=""
            body={<Footer />}
            onClose={handleCloseModal}
          />
        </Body>
      ) : (
        <ConfirmCertNumber email={trimmedEmail} autoLogin={autoLogin} />
      )}
    </Wrapper>
  );
}
