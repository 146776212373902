import { Button as MuiButton, Dialog, Paper } from "@mui/material";
import { useEffect, useRef, useState, useCallback } from "react";
import { ServiceWorkerConfig } from "serviceWorkerConfig";
import { FirebaseClient } from "firebaseClient";
import { extractSemanticVersions } from "utils/versionUtil";
import { Box, Stack } from "./Updater.styled";
import { Button } from "components/Button";

const logger = {
  log: (...args: any) => console.log(...args),
  warn: (...args: any) => console.warn(...args),
  error: (...args: any) => console.error(...args),
};

const firebaseClient = new FirebaseClient();
const originalUpdateWorker = ServiceWorkerConfig.onUpdate;
const LAST_UPDATED_VERSION_KEY = "last_updated_version";

type OptionalUpdateDialogProps = {
  nextVersion: string | null;
  onUpdate: () => void;
};

const OptionalUpdateDialog = ({ onUpdate }: OptionalUpdateDialogProps) => {
  const handleClickUpdate = (e: React.MouseEvent) => {
    e.preventDefault();
    onUpdate();
  };

  return (
    <Paper
      sx={{
        position: "fixed",
        zIndex: 1,
        bottom: 32,
        right: 32,
        border: "solid 1px #EB5757",
        width: 353,
        height: 84,
        display: "flex",
        alignItems: "center",
        p: 1,
        padding: "20px",
        fontSize: "12px",
        justifyContent: "space-between",
        boxShadow: "none",
      }}
    >
      <Box>
        <Box sx={{ fontSize: "15px", fontWeight: "bold" }}>🔔 알림</Box>
        <Box>
          새로운 버전이 출시되었습니다. <br />
          업데이트 후 이용해주시기 바랍니다.
        </Box>
      </Box>
      <MuiButton
        sx={{
          width: 78,
          height: 28,
          backgroundColor: "#EB5757",
          color: "white",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#EB5757",
          },
        }}
        onClick={handleClickUpdate}
      >
        업데이트
      </MuiButton>
    </Paper>
  );
};

const BlockingUpdateDialog = ({
  nextVersion,
  onUpdate,
}: OptionalUpdateDialogProps) => {
  const currentVersion = process.env.REACT_APP_VERSION || "";
  const isSameVersion = currentVersion === nextVersion;

  const handleClickUpdate = (e: React.MouseEvent) => {
    e.preventDefault();
    onUpdate();
  };

  return (
    <Dialog open={true}>
      <Stack className="dialog-wrapper" justifyContent="space-between">
        <Stack flexDirection="column" gap="10px">
          <Box className="title">
            <Box className="icon-box" />
            <span>
              {isSameVersion ? "앱 재설정 안내" : "필수 업데이트 안내"}
            </span>
          </Box>
          <Box className="contents">
            {isSameVersion ? (
              <>
                앱의 일부 기능이 개선되었습니다.
                <br />
                계속 사용하시려면 재설정이 필요합니다.
              </>
            ) : (
              <>
                더욱 향상된 기능을 제공합니다.
                <br />
                최신 버전({nextVersion})으로 업데이트해 주세요.
              </>
            )}
          </Box>
        </Stack>
        <Stack display="flex" flexDirection="row" justifyContent="flex-end">
          <Button onClick={handleClickUpdate} autoFocus={true}>
            {isSameVersion ? "재설정" : "업데이트"}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

const versionUtils = {
  // 두 버전 문자열 비교 (반환값: -1 = 이전 버전, 0 = 같은 버전, 1 = 더 높은 버전)
  compareVersions: (version1: string, version2: string) => {
    try {
      const v1 = extractSemanticVersions(version1);
      const v2 = extractSemanticVersions(version2);

      if (!v1 || !v2) return 0;

      if (v1.major > v2.major) return 1;
      if (v1.major < v2.major) return -1;

      if (v1.minor > v2.minor) return 1;
      if (v1.minor < v2.minor) return -1;

      if (v1.patch > v2.patch) return 1;
      if (v1.patch < v2.patch) return -1;

      return 0;
    } catch (error) {
      logger.error("Version comparison error:", error);
      return 0;
    }
  },

  getLastUpdatedVersion: () => {
    return localStorage.getItem(LAST_UPDATED_VERSION_KEY);
  },

  saveCurrentVersionAsUpdated: (version: string) => {
    if (version) {
      localStorage.setItem(LAST_UPDATED_VERSION_KEY, version);
      logger.log(`Saved version ${version} as last updated version`);
    }
  },

  hasMajorOrMinorUpdate: (
    lastUpdatedVersion: string | null,
    currentVersion: string
  ) => {
    if (!lastUpdatedVersion) return false;

    try {
      const current = extractSemanticVersions(currentVersion);
      const lastUpdated = extractSemanticVersions(lastUpdatedVersion);

      if (current && lastUpdated) {
        return (
          current.major > lastUpdated.major ||
          (current.major === lastUpdated.major &&
            current.minor > lastUpdated.minor)
        );
      }
      return false;
    } catch (error) {
      logger.error("Version comparison error:", error);
      return false;
    }
  },
};

export function Updater() {
  const [hasServiceWorkerUpdate, setHasServiceWorkerUpdate] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [nextVersion, setNextVersion] = useState(null);
  const [stableVersion, setStableVersion] = useState<string | null>(null);
  const reloadFunc = useRef<(() => void) | null>(null);
  const messageChannelRef = useRef<MessageChannel | null>(null);

  const shouldForceUpdate = useCallback(() => {
    const currentVersion = process.env.REACT_APP_VERSION || "";
    const lastUpdatedVersion = versionUtils.getLastUpdatedVersion();

    // 메이저나 마이너 버전이 기존 업데이트 버전보다 높은 경우 강제 업데이트
    if (
      lastUpdatedVersion &&
      versionUtils.hasMajorOrMinorUpdate(lastUpdatedVersion, currentVersion)
    ) {
      logger.log(
        "Force update due to major/minor version increase since last updated version"
      );
      return true;
    }

    // 현재 버전이 stable 버전보다 낮은 경우 강제 업데이트
    if (
      stableVersion &&
      versionUtils.compareVersions(currentVersion, stableVersion) < 0
    ) {
      logger.log(
        `Force update because current version ${currentVersion} is lower than stable version ${stableVersion}`
      );
      return true;
    }

    return false;
  }, [stableVersion]);

  const loadConfigValues = useCallback(async () => {
    try {
      const stableVer = await firebaseClient.getStableVersion();
      setStableVersion(stableVer);

      logger.log("Config loaded:", {
        stableVersion: stableVer,
        currentVersion: process.env.REACT_APP_VERSION,
        lastUpdatedVersion: versionUtils.getLastUpdatedVersion(),
      });
    } catch (error) {
      logger.error("Failed to load config values:", error);
    }
  }, []);

  const handleUpdate = useCallback(() => {
    if (reloadFunc.current) {
      logger.log("Executing update function");
      reloadFunc.current();
    } else {
      logger.warn("Update function is not available");
    }
  }, []);

  const cleanupMessageChannel = useCallback(() => {
    if (messageChannelRef.current) {
      messageChannelRef.current = null;
    }
  }, []);

  useEffect(() => {
    loadConfigValues();
    logger.log(
      "Last updated version on app start:",
      versionUtils.getLastUpdatedVersion()
    );

    ServiceWorkerConfig.onUpdate = (registration) => {
      logger.log("Service worker update detected");

      loadConfigValues();

      const waitingServiceWorker = registration.waiting;

      if (!waitingServiceWorker) {
        logger.warn("No waiting service worker found");
        return;
      }

      cleanupMessageChannel();

      const messageChannel = new MessageChannel();
      messageChannelRef.current = messageChannel;

      waitingServiceWorker.postMessage({ type: "VERSION" }, [
        messageChannel.port2,
      ]);

      messageChannel.port1.onmessage = (event) => {
        const newVersion = event.data.payload;
        setNextVersion(newVersion);
        logger.log("New version received from service worker:", newVersion);

        // 서비스워커 업데이트 감지 시 무조건 hasServiceWorkerUpdate를 true로 설정
        if (!isUpdating) {
          setHasServiceWorkerUpdate(true);
          logger.log(
            "Update notification enabled - new service worker version detected"
          );
        }
      };

      reloadFunc.current = () => {
        setIsUpdating(true);
        logger.log("Starting update process");
        setHasServiceWorkerUpdate(false);

        try {
          // 업데이트 시 항상 localStorage에 버전 저장
          nextVersion && versionUtils.saveCurrentVersionAsUpdated(nextVersion);
          originalUpdateWorker(registration);
        } catch (error) {
          logger.error("Error during update:", error);
          setIsUpdating(false);
          setHasServiceWorkerUpdate(true);
        } finally {
          cleanupMessageChannel();
        }
      };
    };

    // 핸들러 복원 및 리소스 정리
    return () => {
      ServiceWorkerConfig.onUpdate = originalUpdateWorker;
      cleanupMessageChannel();
    };
  }, [isUpdating]);

  if (isUpdating || !hasServiceWorkerUpdate) {
    return null;
  }

  return shouldForceUpdate() ? (
    <BlockingUpdateDialog nextVersion={nextVersion} onUpdate={handleUpdate} />
  ) : (
    <OptionalUpdateDialog nextVersion={nextVersion} onUpdate={handleUpdate} />
  );
}
