import styled from "@emotion/styled";
import Snackbar from "@mui/material/Snackbar";
import { color as colorTheme } from "~/shared/styles";

type SnackbarColorType = "unoblue" | "alert";

interface ColorTypeUtil {
  getColor(type: SnackbarColorType): string;
}

const ColorType: ColorTypeUtil = {
  getColor(type) {
    switch (type) {
      case "unoblue":
        return colorTheme.primary.unoblue;
      case "alert":
      default:
        return colorTheme.alert;
    }
  },
};

interface StyledSnackbarProps {
  color: SnackbarColorType;
}

export const SnackbarComponent = styled(Snackbar)<StyledSnackbarProps>`
  min-width: 430px;
  background-color: #ffffff;
  border: 1px solid ${({ color }) => ColorType.getColor(color)};
  border-radius: 4px;
  position: fixed !important;
  z-index: 9999;

  & .MuiSnackbarContent-root {
    min-width: 430px;
    background-color: #ffffff;
  }

  div {
    font-size: 12px;
    color: ${({ color }) => ColorType.getColor(color)};
    box-shadow: none;
  }
`;

export const Mark = styled.div<StyledSnackbarProps>`
  width: 15px;
  height: 15px;
  border: 1px solid ${({ color }) => ColorType.getColor(color)};
  text-align: center;
  border-radius: 16px;
  color: ${({ color }) => ColorType.getColor(color)};
  font-size: 11px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
`;

export const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

export const Message = styled.div`
  margin-left: 5px;
`;

export const RightSection = styled.div`
  display: flex;
  gap: 8px;
`;
