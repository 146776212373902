import styled from "@emotion/styled";
import { Button } from "components/Button";

export const ButtonWrapper = styled(Button)`
  padding: 0;
`;

export const Memos = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const Memo = styled.div`
  display: flex;
  column-gap: 12px;
`;

export const MoreMemoButton = styled.span`
  color: #bbbbbb;
  cursor: pointer;
  text-align: left;
`;

export const Label = styled.label`
  font-weight: 700;
  white-space: nowrap;
`;

export const Contents = styled.div`
  max-height: 8em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  * {
    margin: 0;
  }
`;

export const Divider = styled.div`
  margin-left: 60px;
  border-bottom: 1px solid #efeff4;
`;

export const CustomerName = styled.div`
  word-break: break-all;
`;
