import styled from "@emotion/styled";

export const IconWrapper = styled.div`
  svg {
    font-size: 11px;
  }
  margin-right: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;
