import { useState, useCallback, useRef } from "react";
import update from "immutability-helper";
import format from "date-fns/format";
import { CustomerType } from "type/customer";
import { Button } from "@mui/material";
import { useApi } from "providers/ApiProvider";
import { useImperativeModal } from "ImperativeModalProvider";
import { useSnackbarContext } from "~/SnackbarProvider_v2";
import { useSurvey } from "hooks/useSurvey";
import { AlertModal } from "modals/AlertModal";
import { SlideModal, Wrapper } from "components/Modal/SlideModal";
import Content from "components/SurveyContent";
import { TemplateType, TemplateContentType } from "type/survey";
import { Stack, Box, Typography } from "./SurveyPopupContainer.styled";
import ModalHeader from "./ModalHeader";

type SurveyPopupContainerProps = {
  onClose: (param?: any) => void;
  title?: string;
  data: TemplateType;
  mode?: "write" | "edit" | "read";
  templateId?: number;
  customer: CustomerType;
  surveyId?: number;
  callback?: () => void;
  status?: "temporary" | "complete";
};

type JsonArray = JsonValue[];
type JsonObject = {
  [key: string]: JsonValue;
};
type JsonValue = string | number | boolean | null | JsonArray | JsonObject;

function removeBackspace(obj: JsonValue): JsonValue {
  if (typeof obj === "string") {
    return obj.replace(/\b/g, "");
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => removeBackspace(item));
  }

  if (typeof obj === "object" && obj !== null) {
    const newObj: JsonObject = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        newObj[key] = removeBackspace(obj[key]);
      }
    }
    return newObj;
  }

  return obj;
}

const SurveyPopupContainer = (props: SurveyPopupContainerProps) => {
  const {
    onClose,
    data,
    mode = "write",
    templateId,
    customer,
    surveyId,
    callback,
    status,
  } = props;
  const parsedContent: TemplateContentType[] = JSON.parse(data.content);
  const [surveyResult, setSurveyResult] = useState(parsedContent);
  const [finished, setFinished] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const contentRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [date] = useState<string>(
    data?.registeredDate
      ? format(new Date(data.registeredDate), "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd")
  );
  const snackbar = useSnackbarContext();
  const api = useApi();
  const { surveyApi } = api;
  const { validateSurveyContent } = useSurvey(customer);
  const imperativeModal = useImperativeModal();

  const handleChangeResult = (
    template: TemplateContentType,
    key: "values" | "text",
    value: any
  ) => {
    const index = surveyResult.indexOf(template);
    const newValue = update(surveyResult, {
      [index]: {
        result: {
          [`${key}`]: { $set: value },
        },
      },
    });

    if (focusedIndex === index) {
      setFocusedIndex(null);
    }

    setSurveyResult(newValue);
  };

  const scrollToContent = (index: number) => {
    const element = contentRefs.current[index];
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      setFocusedIndex(index);
    }
  };

  const handleSubmit = useCallback(
    async (saveStatus: "temporary" | "complete") => {
      if (saveStatus === "complete") {
        const validationResult = validateSurveyContent(surveyResult || []);

        if (!validationResult.isValid) {
          await imperativeModal.open((close) => (
            <AlertModal onClose={close}>
              필수 입력항목인{" "}
              {validationResult.invalidIndexes
                .map((item) => item + 1)
                .join(", ")}
              번 항목이 입력되지 않았습니다.
            </AlertModal>
          ));
          const firstInvalidIndex = validationResult.invalidIndexes[0];
          scrollToContent(firstInvalidIndex);
          return;
        }
      }

      try {
        if (mode === "edit") {
          const cleaned = removeBackspace(surveyResult);
          const contents = JSON.stringify(cleaned);
          const params = {
            contents,
            registeredDate: date,
            status: saveStatus,
          };
          await surveyApi.updateSurvey(surveyId!, params);
          if (saveStatus === "temporary") {
            snackbar.success(`임시저장을 성공하였습니다.`);
            callback && callback();
            onClose();
          } else {
            snackbar.success(`문진 저장을 성공하였습니다.`);
            setFinished(true);
          }
        } else {
          const cleaned = removeBackspace(surveyResult);
          const contents = JSON.stringify(cleaned);
          const params = {
            contents,
            registeredDate: date,
            customerId: customer.id,
            status: saveStatus,
            templateId: templateId!,
          };
          await surveyApi.createSurvey(params);
          if (saveStatus === "temporary") {
            snackbar.success(`임시저장을 성공하였습니다.`);
            callback && callback();
            onClose();
          } else {
            snackbar.success(`문진 저장을 성공하였습니다.`);
            setFinished(true);
          }
        }
      } catch (error) {
        snackbar.alert(`문진 응답의 저장에 실패했습니다.`);
      }
    },
    [surveyResult, date]
  );

  const handleClose = () => {
    if (finished) {
      callback && callback();
    }
    onClose();
  };

  return (
    <SlideModal open={true}>
      <Wrapper>
        <ModalHeader onClose={handleClose} customer={customer} />
        <Box className="survey-popup-body">
          <Typography variant="h1" className="mb-20">
            {data.subject}
          </Typography>
          <Box className="survey-popup-body-content">
            {finished ? (
              <Box className="result-text">
                {data.resultText ||
                  "모든 문항 응답을 완료했으며, 정상적으로 제출되었습니다.\n감사합니다."}
              </Box>
            ) : (
              <Stack flexDirection={"column"}>
                <Box className="mb-20 description">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: data.description,
                    }}
                  />
                </Box>
                <Stack
                  flexDirection={"column"}
                  gap={"20px"}
                  className="ht-content-wrapper"
                >
                  {surveyResult.map((item, index) => {
                    return (
                      <Box
                        className={`content ${
                          focusedIndex === index ? "focused" : ""
                        }`}
                        key={`content-index-${index}`}
                        ref={(el) =>
                          (contentRefs.current[index] = el as HTMLDivElement)
                        }
                      >
                        <Typography variant="h3">
                          {`${index + 1}. ${item.title}`}
                          {item.required && <span className="required">*</span>}
                        </Typography>
                        <Content
                          data={item}
                          onChange={handleChangeResult}
                          mode={mode}
                        />
                      </Box>
                    );
                  })}
                </Stack>
              </Stack>
            )}
          </Box>
          {mode !== "read" && !finished && (
            <Box className={`action-btn-wrapper`}>
              <div className="width-adjust-wrapper">
                {status === "complete" ? (
                  <Button variant="outlined" onClick={onClose}>
                    취소
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => handleSubmit("temporary")}
                  >
                    임시저장
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={() => handleSubmit("complete")}
                >
                  저장
                </Button>
              </div>
            </Box>
          )}
        </Box>
      </Wrapper>
    </SlideModal>
  );
};

export default SurveyPopupContainer;
