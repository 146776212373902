import { styled as Muistyled } from "@mui/material";
import styled from "@emotion/styled";
import { hexToRgbaString } from "utils/colorUtil";

export const Wrapper = styled.div`
  padding: 22px 20px;
  height: calc(100% - 40px);
  overflow: auto;
  background: #ffffff;
`;

export const EmptyText = Muistyled("div")`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DescriptionText = Muistyled("div")`
  font-family: Noto Sans KR;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  white-space: nowrap;
`;

export const MoreButton = Muistyled("div")`
  cursor: pointer;
  padding: 10px 0;
  text-align: center;
  background: ${hexToRgbaString("#ffffff", 0.2)};
`;
