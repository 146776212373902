import { useUser } from "providers/UserProvider";
import { ReactComponent as UnocrmLogo } from "assets/icons/ic-unocrm-logo.svg";
import { Box } from "./TopSection.styled";

export const TopSection = () => {
  const { user } = useUser();

  return (
    <Box className="top-wrapper">
      <Box className="background-image-wrapper">
        <Box className="title-wrapper">
          <div>{user.organization_top.name}</div>
          <UnocrmLogo />
        </Box>
        <Box className="description-wrapper">
          <p className="description font-size-5vw">
            반갑습니다 ;) <br />
          </p>
          <p className="description font-size-3vw">
            전화번호로 접수를 시작합니다.
          </p>
        </Box>
      </Box>
    </Box>
  );
};
