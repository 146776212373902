import { useState, useCallback, useEffect, useMemo } from "react";
import { useSnackbarContext } from "~/SnackbarProvider_v2";
import { LabelWrapper, Label } from "components/Form/Label";
import { RegistrationTypeButton } from "pages/AppointmentPage/form/RegistrationTypeButton";
import { DateInput } from "components/Form/DateInput";
import { AssistSelect } from "components/Form/select/AssistSelect";
import { CounselorSelect } from "components/Form/select/CounselorSelect";
import { DoctorSelect } from "components/Form/select/DoctorSelect";
import { StartHourSelect } from "components/Form/select/TimeSelect";
import { SurgerySelect } from "components/Form/select/SurgerySelect";
import Icon from "components/Icon";
import { format, differenceInMinutes, addMinutes } from "date-fns";
import DepartmentSelect from "./DepartmentSelect";
import CreatedSelect from "./CreatedSelect";
import AcquisitionChannelSelect from "./AcquisitionChannelSelect";
import CloseIcon from "@mui/icons-material/Close";
import { useApi } from "providers/ApiProvider";
import { useUser } from "providers/UserProvider";
import {
  Button,
  Wrapper,
  MuiTextField,
  QuillTextField,
  Boilerplate,
  Box,
  IconButton,
} from "./RegistrationForm.styled";
import { AddRemainingSurgeryButton } from "./AddRemainingSurgeryButton";
import { RegistrationType } from "type/registrationType";
import { TimeDurationField } from "components/TimeDurationField/TimeDurationField";
import { minutesToTime, parseTime, toMinutes } from "utils/timeUtil";

type FormProps = {
  registration?: any;
  customer?: any;
  onSaveCallback: () => void;
};

export function RegistrationForm({
  registration,
  customer,
  onSaveCallback,
}: FormProps) {
  const { clinicsApi, filesApi, registrationApi } = useApi();
  const snackbar = useSnackbarContext();
  const { user } = useUser();
  const [date, setDate] = useState(
    format(new Date(registration?.date ?? new Date()), "yyyy-MM-dd")
  );
  const [category, setCategory] = useState(
    registration?.category ?? RegistrationType.consulting
  );
  const [startHour, setStartHour] = useState(
    registration
      ? { value: registration?.startHour }
      : { value: user.clinic.workStart }
  );
  let defaultTime = minutesToTime(user.clinic.appointmentTimeUnit);
  if (registration) {
    const durationMinutes = differenceInMinutes(
      new Date(registration.endAt),
      new Date(registration.startAt)
    );
    defaultTime = minutesToTime(durationMinutes);
  }
  const [time, setTime] = useState(defaultTime);
  const [acquisitionChannel, setAcquisitionChannel] = useState(
    registration?.acquisitionChannel
  );
  const [assist, setAssist] = useState(registration?.assist);
  const [counselor, setCounselor] = useState(
    registration?.counselor ?? customer.counselor
  );
  const [doctor, setDoctor] = useState(registration?.doctor ?? customer.doctor);
  const [department, setDepartment] = useState(
    registration?.department ?? null
  );
  const [surgery, setSurgery] = useState(
    registration?.treatmentItems
      ? Array.isArray(registration?.treatmentItems) &&
        registration?.treatmentItems.length === 0
        ? [{}]
        : registration?.treatmentItems
      : [{}]
  );
  const [memo, setMemo] = useState(registration?.memo ?? "");
  const [creator, setCreator] = useState(
    registration?.creator || {
      id: user.id,
      name: user.name,
      status: user.status,
    }
  );
  const [loading, setLoading] = useState(false);
  const [memoBoilerplateList, setMemoBoilerplateList]: any = useState([]);
  const [clinicConfig, setClinicConfig] = useState<any>([]);

  const useRepeatAcquistion = useMemo(() => {
    const find = clinicConfig.find(
      (item: { codeName: string }) => item.codeName === "useRepeat"
    );
    return find?.codeValue ? JSON.parse(find?.codeValue || "false") : false;
  }, [clinicConfig]);

  const savedAcquisitionChannelId =
    useRepeatAcquistion && customer.acquisitionChannels.length === 1
      ? customer.acquisitionChannels[0]?.id
      : null;

  useEffect(() => {
    if (savedAcquisitionChannelId) {
      setAcquisitionChannel(
        registration?.id
          ? registration?.acquisitionChannel || null
          : savedAcquisitionChannelId
      );
    }
  }, [savedAcquisitionChannelId]);

  const loadClinicConfig = useCallback(async () => {
    const res = await clinicsApi.getConfigs();
    const payload = await res.data;
    setClinicConfig(payload?.data);
  }, [clinicsApi]);

  useEffect(() => {
    loadClinicConfig();
  }, [loadClinicConfig]);

  const loadMemoBoilerPlate = useCallback(async () => {
    const res = await filesApi.getMemoBoilerPlates("registration");
    const payload = await res.data;

    setMemoBoilerplateList(payload?.data);
  }, [filesApi]);

  useEffect(() => {
    loadMemoBoilerPlate();
  }, [loadMemoBoilerPlate]);

  const validate = useCallback(() => {
    if (!(date && startHour && time && department && creator)) {
      throw new Error("필수항목을 모두 입력해주세요.");
    }
    if (time.hours === 0 && time.minutes === 0) {
      throw new Error("예상 소요시간을 확인해주세요.");
    }
    if (
      toMinutes(parseTime(startHour.value)) + toMinutes(time) >
      toMinutes(parseTime(user.clinic.workEnd))
    ) {
      throw new Error("진료시간을 초과하였습니다.");
    }
  }, [date, department, startHour, time, user.clinic.workEnd, creator]);

  const handleResetDate = () => {
    setDate("");
  };

  const onSave = useCallback(async () => {
    setLoading(true);
    try {
      validate();
    } catch (e: any) {
      setLoading(false);
      snackbar.alert(e.message);
      return;
    }

    try {
      const startAt = new Date(`${date} ${startHour.value}`);
      const endAt = addMinutes(startAt, toMinutes(time));
      const data: any = {
        acquisitionChannelId:
          acquisitionChannel instanceof Object
            ? acquisitionChannel?.id
            : acquisitionChannel,
        category: category,
        assistId: assist?.id,
        counselorId: counselor?.id,
        createdBy: creator?.id,
        customerId: customer.id,
        departmentId: department?.id,
        doctorId: doctor?.id,
        memo: memo ? memo.replace(/(?:\r\n|\r|\n)/g, "<br />") : undefined,
        startAt: format(startAt, "yyyy-MM-dd'T'HH:mm"),
        endAt: format(endAt, "yyyy-MM-dd'T'HH:mm"),
        treatmentItemIds: surgery.map((v: any) => v?.id).filter((v: any) => v),
      };

      if (registration?.id) {
        await registrationApi.editRegistration(registration?.id, data);
        snackbar.success("접수를 변경했습니다.");
      } else {
        await registrationApi.createRegistration(data);
        snackbar.success("접수를 생성했습니다.");
      }
      onSaveCallback();
    } catch (e) {
      snackbar.alert("알 수 없는 에러가 발생했습니다.");
      throw e;
    } finally {
      setLoading(false);
    }
  }, [
    validate,
    date,
    startHour?.value,
    time,
    acquisitionChannel,
    category,
    assist?.id,
    counselor?.id,
    creator?.id,
    customer.id,
    department?.id,
    doctor?.id,
    memo,
    surgery,
    registration?.id,
    onSaveCallback,
    registrationApi,
  ]);

  const sessionDurationOptions = useMemo(() => {
    const clinic = user.clinic;
    if (!clinic) return [];

    const start = parseTime(clinic.workStart);
    const end = parseTime(clinic.workEnd);
    const interval = clinic.appointmentTimeUnit;

    const totalMinutes =
      end.hours * 60 + end.minutes - (start.hours * 60 + start.minutes);

    const hours = Math.floor(totalMinutes / 60);

    const times = (function () {
      const candidates = [];
      for (let i = 0; i <= hours; i++) {
        if (i === 0) {
          switch (interval) {
            case 10:
              [10, 20, 30, 40, 50].forEach((min) =>
                candidates.push({ hours: i, minutes: min })
              );
              break;
            case 30:
              candidates.push({ hours: i, minutes: 30 });
              break;
            case 60:
            default:
              break;
          }
        } else {
          [0, 30].forEach((min) => candidates.push({ hours: i, minutes: min }));
        }
      }
      return candidates;
    })();

    return times.filter((t) => t.hours * 60 + t.minutes < totalMinutes);
  }, [user]);

  return (
    <Wrapper>
      <Label text="접수종류" isRequire>
        <RegistrationTypeButton value={category} onChange={setCategory} />
      </Label>
      <LabelWrapper column={3}>
        <Label text="일자" isRequire>
          <>
            <DateInput
              value={date}
              onChange={(v) => setDate(format(new Date(v), "yyyy-MM-dd"))}
              InputComponent={MuiTextField}
              inputFormat="YYYY-MM-DD"
              mask="____-__-__"
              placeholder="일자를 선택하세요."
              editInput
              disabled={registration?.id ? true : false}
            />
            <Box component={"span"} className="calendar-icon-wrapper">
              <Icon variant="calendar" />
            </Box>
            {date && !registration?.id && (
              <IconButton onClick={handleResetDate}>
                <CloseIcon sx={{ fontSize: 11 }} />
              </IconButton>
            )}
          </>
        </Label>
        <Label text="방문시간" isRequire>
          <StartHourSelect
            value={startHour?.value ?? ""}
            onChange={(v) => setStartHour(v)}
            date={date}
            department={department}
          />
        </Label>
        <Label text="예상 소요시간" isRequire>
          <TimeDurationField
            sx={{
              width: "100%",
            }}
            value={time}
            onChange={setTime}
            options={sessionDurationOptions}
          />
        </Label>
      </LabelWrapper>
      <LabelWrapper column={3}>
        <Label text="접수부서" isRequire>
          <DepartmentSelect
            value={department}
            onChange={(v: any) => {
              setDepartment(v);
            }}
          />
        </Label>
        <Label text="내원경로">
          <AcquisitionChannelSelect
            value={acquisitionChannel || ""}
            onChange={(v: any) => {
              setAcquisitionChannel(v);
            }}
          />
        </Label>
        <Label text="작성자" isRequire>
          <CreatedSelect
            value={creator}
            onChange={(v: any) => {
              setCreator(v);
            }}
          />
        </Label>
      </LabelWrapper>
      <LabelWrapper column={3}>
        <Label text="의사">
          <DoctorSelect value={doctor} onChange={setDoctor} />
        </Label>
        <Label text="상담사">
          <CounselorSelect value={counselor} onChange={setCounselor} />
        </Label>
        <Label text="어시스트">
          <AssistSelect value={assist} onChange={setAssist} />
        </Label>
      </LabelWrapper>
      {category === RegistrationType.surgery && (
        <AddRemainingSurgeryButton
          customerId={customer.id}
          onAddSurgeries={(list: any) => {
            const surgeries = [
              ...surgery.filter((item: any) => Object.keys(item).length > 0),
              ...list.map((v: any) => v.treatmentItem),
            ];
            setSurgery(surgeries);
          }}
        />
      )}
      <SurgerySelect value={surgery} onChange={setSurgery} />
      <LabelWrapper column={1}>
        <Label text="접수 메모">
          <>
            {memoBoilerplateList?.length > 0 && (
              <Boilerplate>
                <p>자주 쓰는 상용구</p>
                {memoBoilerplateList.slice(0, 5).map((v: any, i: number) => (
                  <button
                    key={i}
                    onClick={() =>
                      setMemo((memo === "<p><br></p>" ? "" : memo) + v.contents)
                    }
                  >
                    {v.title}
                  </button>
                ))}
              </Boilerplate>
            )}
            <QuillTextField
              value={memo ?? ""}
              onChange={(v: any) => setMemo(v)}
              placeholder="메모를 입력하세요."
            />
          </>
        </Label>
      </LabelWrapper>
      <Button
        styled="fill"
        color="primary"
        onClick={onSave}
        sx={{
          position: "sticky",
          bottom: "0",
          marginTop: "auto",
        }}
        disabled={loading}
      >
        {registration?.id ? "수정완료" : "등록"}
      </Button>
    </Wrapper>
  );
}
