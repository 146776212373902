import { ApiResource } from "./apiResource";
import {
  ApiEmptyResponse,
  ApiDataResponse,
  PaginationResponse,
} from "../apiClient";

type TemplateListItem = {
  createdAt: string;
  editable: boolean;
  expirationDay: number;
  id: number;
  questionCount: number;
  title: string;
};

type TemplateItem = {
  contents: string;
  createdAt: string;
  editable: boolean;
  expirationDay: number;
  id: number;
  questionCount: number;
  resultInfo: string;
  title: string;
  topInfo: string;
  registeredDate?: string;
  status: "temporary" | "complete";
};

type CreateTemplateBodyParams = {
  contents?: string;
  expirationDay: number;
  resultInfo: string;
  title: string;
  topInfo?: string;
};

type SurveyResultsParams = {
  registeredDateStart: string;
  registeredDateEnd: string;
  page: number;
  limit: number;
  orderBy?: string;
  titleIds?: (number | null)[];
  status?: string;
};

type SurveyResultListItem = {
  completeCount: number;
  id: number;
  temporaryCount: number;
  title: string;
  totalCount: number;
};

export type SurveyListParams = {
  page?: number;
  limit?: number;
  orderBy?: string;
  customerId: number;
};

type SurveyListItem = {
  createdAt: string;
  creatorName: string;
  id: number;
  status: string;
  title: string;
};

type Status = "complete" | "temporary";

type CodeSurveyRequestParams = {
  contents: string;
  registeredDate: string;
  status: Status;
};

type CodeSurveyResponseData = {
  contents: string;
  id: number;
  registeredDate: string;
  resultInfo: string;
  status: Status;
  title: string;
  topInfo: string;
  isExpired: boolean;
};

type CreateSurveyRequestParams = {
  contents: string;
  customerId: number;
  registeredDate: string;
  status: Status;
  templateId: number;
};

type UpdateSurveyRequestParams = {
  contents: string;
  registeredDate: string;
  status: Status;
};

export class SurveyApi extends ApiResource {
  async list(params: SurveyListParams) {
    return this.client.get<PaginationResponse<SurveyListItem[]>>(
      `/questionnaires`,
      {
        params,
      }
    );
  }

  async delete(id: number) {
    return this.client.delete<ApiEmptyResponse>(`/questionnaire/${id}`);
  }

  async deleteBatch(ids: number[]) {
    return this.client.delete<ApiEmptyResponse>(`/batch/questionnaires`, {
      data: { ids },
    });
  }

  async templateList() {
    return this.client.get<PaginationResponse<TemplateListItem[]>>(
      `/questionnaire/templates`
    );
  }

  async templateDetail(id: number) {
    return this.client.get<ApiDataResponse<TemplateItem>>(
      `/questionnaire/template/${id}`
    );
  }

  async getSurvey(id: number) {
    return this.client.get<ApiDataResponse<TemplateItem>>(
      `/questionnaire/${id}`
    );
  }

  async createSurvey(params: CreateSurveyRequestParams) {
    return this.client.post(`/questionnaires`, params);
  }

  async updateSurvey(id: number, params: UpdateSurveyRequestParams) {
    return this.client.put(`/questionnaire/${id}`, params);
  }

  async getSurveyByCode(key?: string) {
    if (key) {
      return this.client.get<ApiDataResponse<CodeSurveyResponseData>>(
        `/questionnaire/code/${key}`
      );
    }
  }

  async updateSurveyByCode(key: string, params: CodeSurveyRequestParams) {
    return this.client.put<ApiDataResponse<CodeSurveyResponseData>>(
      `/questionnaire/code/${key}`,
      params
    );
  }

  async getSurveyResults(params: SurveyResultsParams) {
    return this.client.get<PaginationResponse<SurveyResultListItem[]>>(
      `/questionnaires/results`,
      { params }
    );
  }

  async createTemplate(params: CreateTemplateBodyParams) {
    return this.client.post(`/questionnaire/templates`, params);
  }

  async updateTemplate(id: number, params: CreateTemplateBodyParams) {
    return this.client.put(`/questionnaire/template/${id}`, params);
  }

  async deleteTemplate(id: number) {
    return this.client.delete<ApiEmptyResponse>(
      `/questionnaire/template/${id}`
    );
  }
}
