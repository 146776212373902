import { useMemo } from "react";
import { observer } from "mobx-react";
import {
  useAppointmentContext,
  AppointmentContextType,
} from "pages/AppointmentPage/AppointmentPage";
import { AntTabs, Box, AntTab, IconButton } from "./FilterTab.styled";
import Icon from "~/components/Icon";
import { useAppointmentsStore } from "~/hooks/useAppointmentsStore";

const FilterTab = () => {
  const { setDepartmentId, departmentId, openFilterPopup, departments } =
    useAppointmentContext() as AppointmentContextType;
  const { selectedStatusFilters, selectedDepartmentFilters } =
    useAppointmentsStore();

  const filtered = useMemo(() => {
    return (
      selectedStatusFilters.length > 0 ||
      (selectedDepartmentFilters && selectedDepartmentFilters.length > 0)
    );
  }, [selectedStatusFilters, selectedDepartmentFilters]);

  const handleChange = (e: any, v: any) => {
    setDepartmentId(v);
  };

  if (!departments || departments.length === 0) {
    return null;
  }

  return (
    <Box className="category-tab-wrapper">
      <IconButton
        className={`${filtered ? "active" : ""}`}
        onClick={openFilterPopup}
      >
        <Icon variant={`filter${filtered ? "_on" : ""}`} />
      </IconButton>
      <AntTabs
        className="tab-wrapper"
        value={JSON.parse(departmentId as string) || null}
        onChange={handleChange}
        sx={{ borderColor: "#efeff4", bgcolor: "#fff" }}
        TabIndicatorProps={{
          children: <span className="MuiTabs-indicatorSpan" />,
        }}
        variant="scrollable"
        scrollButtons={false}
      >
        <AntTab label="전체" value={null} key={"null"} />
        {departments.map((v: any) => (
          <AntTab key={v.id} label={v.name} value={v.id} />
        ))}
      </AntTabs>
    </Box>
  );
};

export default observer(FilterTab);
