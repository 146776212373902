import { ApiResource } from "./apiResource";
import { ApiDataResponse } from "../apiClient";

export class TicketsApi extends ApiResource {
  async getTickets(params: any) {
    return this.client.get<ApiDataResponse<any>>("/tickets", {
      params,
    });
  }
}
