import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Wrapper = styled.div`
  font-size: 12px;
  & > div {
    width: 100%;
  }
`;

export const Row = styled.div`
  overflow: scroll;
  padding: 0 !important;
  display: table !important;
`;

export const Column = styled.div`
  text-align: center;
  display: table-cell;
  vertical-align: middle;
`;

export const ColumnValue = styled.div<{ width: number }>`
  margin: 5px;
  ${({ width }) =>
    css`
      width: ${width}px;
    `}
`;
