import { useState } from "react";
import { Button } from "components/Button";
import { ConfirmModal } from "components/Modal/ConfirmModal";
import { useAuth } from "providers/AuthProvider";

export function LogoutButton() {
  const { signout } = useAuth();
  const [openAlert, setOpenAlert] = useState(false);

  return (
    <>
      <Button
        styled="outline"
        color="mix"
        onClick={() => setOpenAlert(true)}
        style={{
          width: "104px",
          height: "24px",
          background: "rgba(237, 239, 241, 0.2) !important",
          padding: "0 8px",
        }}
      >
        로그아웃
      </Button>
      <ConfirmModal
        open={openAlert}
        body="로그아웃 하시겠습니까?"
        onClose={() => setOpenAlert(false)}
        onConfirm={signout}
      />
    </>
  );
}
