import { ApiClient, ApiException } from "../apiClient";
import axios, { AxiosInstance, AxiosError, AxiosResponse } from "axios";
import * as Sentry from "@sentry/browser";
import { v4 as uuidv4 } from "uuid";

declare module "axios" {
  export interface InternalAxiosRequestConfig {
    metadata?: {
      requestId: string;
      [key: string]: any;
    };
  }
}

interface HeaderOptions {
  contentType?: string;
  refresh?: boolean;
  [key: string]: any;
}

export abstract class ApiResource {
  protected endpoint: string;
  protected client: AxiosInstance;

  constructor(protected apiClient: ApiClient) {
    this.endpoint = apiClient.endpoint!;

    this.client = axios.create({
      baseURL: this.endpoint,
      timeout: 300000,
    });

    this.client.interceptors.request.use((config) => {
      const requestId = uuidv4();

      Sentry.addBreadcrumb({
        type: "HTTP Request",
        category: "fetch.start",
        message: `[${requestId}] ${config.url}`,
        level: Sentry.Severity.Info,
      });

      config.metadata = { requestId };

      config.headers = {
        ...this.buildHeaders(),
        ...config.headers,
      };

      return config;
    });

    this.client.interceptors.response.use(
      (response) => {
        const requestId = response.config.metadata?.requestId;

        Sentry.addBreadcrumb({
          type: "HTTP Request",
          category: "fetch.success",
          message: `[${requestId}] ${response.config.url} ${response.status}`,
          level: Sentry.Severity.Info,
        });

        return this.handleResponse(response);
      },
      async (error: AxiosError) => {
        const requestId = error.config?.metadata?.requestId;

        Sentry.addBreadcrumb({
          type: "HTTP Request",
          category: "fetch.failure",
          message: `[${requestId}] ${error.config?.url} ${error.message}`,
          level: Sentry.Severity.Info,
        });

        return this.handleError(error);
      }
    );

    this.bindMethods();
  }

  private bindMethods(): void {
    for (const prop of Object.getOwnPropertyNames(
      Object.getPrototypeOf(this)
    )) {
      if (prop === "constructor" || typeof (this as any)[prop] !== "function") {
        continue;
      }

      (this as any)[prop] = (this as any)[prop].bind(this);
      (this as any)[prop].apiKey = `${this.constructor.name}.${prop}`;
    }
  }

  protected buildHeaders(userOptions: HeaderOptions = {}) {
    return this.apiClient.buildHeaders(userOptions);
  }

  protected dispatchApiEvent(eventName: string, payload?: any) {
    this.apiClient._dispatch(eventName, payload);
  }

  protected handleResourceEvent(
    eventName: string,
    handler: (payload: any) => void
  ) {
    return this.apiClient.on(eventName, handler);
  }

  protected async handleResponse(response: AxiosResponse) {
    return response;
  }

  protected async handleError(error: AxiosError) {
    if (error.response) {
      const { status, data, config } = error.response;
      const errorData = data as any;

      if (status === 401 && config) {
        try {
          // 토큰 갱신 시도 후에 재요청
          const { accessToken } =
            await this.apiClient.userApi.refreshAccessToken();
          const newConfig: any = { ...config };
          newConfig.headers = {
            ...newConfig.headers,
            Authorization: `Bearer ${accessToken}`,
          };

          return await this.client.request(newConfig);
        } catch (refreshError) {
          // 토큰 갱신 실패
          this.dispatchApiEvent(ApiClient.Event.UNAUTHORIZED);
          throw refreshError;
        }
      }

      // 403 에러 처리
      if (status === 403) {
        switch (errorData?.name) {
          case "ACCESS_DENIED":
            this.dispatchApiEvent(ApiClient.Event.ACCESS_DENIED);
            break;
          case "OWNER_ACCESS_DENIED":
            this.dispatchApiEvent(ApiClient.Event.OWNER_ACCESS_DENIED);
            break;
          case "ACCESS_RESTRICTED":
            this.dispatchApiEvent(ApiClient.Event.ACCESS_RESTRICTED);
            break;
        }
      }

      throw new ApiException(
        status,
        errorData?.name || errorData?.message,
        errorData?.description || errorData?.message
      );
    }

    throw error;
  }

  protected arrayToQueryParam(items?: any[]) {
    return items?.map((v) => String(v)).join(",");
  }
}
