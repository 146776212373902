import { useState } from "react";
import { Navigate } from "react-router-dom";
import { useSnackbarContext } from "~/SnackbarProvider_v2";
import { useUser } from "providers/UserProvider";
import { useAuth } from "providers/AuthProvider";
import { useApi } from "providers/ApiProvider";
import { Button, Box } from "@mui/material";
import styled from "@emotion/styled";
import { validatePassword } from "utils/validationUtils";

const Input = styled.input({
  width: "100%",
  height: 34,
  padding: "8px 20px",
  borderColor: "#d7e3f1",
  fontSize: 12,
  minHeight: 10,
  borderRadius: 4,
  backgroundColor: "#f3f8ff",
  color: "#000",
  border: "1px solid #dee2ec",

  "&::placeholder": {
    color: "#a1b1ca !important",
  },
});

export const ChangePasswordPage = () => {
  const sb = useSnackbarContext();
  const { signout }: any = useAuth();
  const { user, refresh } = useUser();
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const { userApi } = useApi();

  const validate = () => {
    const errorText = validatePassword(newPassword);
    if (errorText) {
      throw new Error(errorText);
    }

    if (newPassword !== passwordConfirm) {
      throw new Error("새 비밀번호와 비밀번호 확인이 일치하지 않습니다.");
    }
  };

  const onClickChange = async () => {
    try {
      validate();
    } catch (e: any) {
      sb.alert(e.message);
      return;
    }

    try {
      await userApi.changePassword(newPassword);
      refresh();
    } catch (e: any) {
      sb.alert(e.description);
    }
  };

  const onClickCancel = async () => {
    signout();
  };

  if (user.passwordChanged) {
    return <Navigate to="/" replace />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          minWidth: "340px",
          color: "#2D2D2D",
          fontWeight: 500,
        }}
      >
        <div className="head flex-row flex-between items-start m-b-16">
          <div className="title">
            임시 비밀번호를 변경해야 계속할 수 있습니다.
          </div>
        </div>
        <div className="inputs">
          <div className="form-control">
            <label>새 비밀번호</label>
            <Input
              type="password"
              placeholder="8~20자 영문자, 숫자, 특수기호 조합하여 입력하세요"
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="form-control">
            <label>비밀번호 확인</label>
            <Input
              type="password"
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </div>
        </div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          <Button
            sx={{
              py: 1,
            }}
            color="info"
            variant="contained"
            onClick={onClickCancel}
          >
            취소
          </Button>
          <Button
            sx={{
              py: 1,
            }}
            variant="contained"
            onClick={onClickChange}
          >
            변경
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
