import { useState } from "react";
import { List } from "components/List/List";
import { ConfirmModal } from "components/Modal/ConfirmModal";
import { CreateRegistrationModal } from "modals/CreateRegistrationModal";
import { RegistrationType } from "type/registrationType";
import { AppointmentStatus } from "type/appointmentStatus";
import { useApi } from "providers/ApiProvider";
import { useSnackbarContext } from "~/SnackbarProvider_v2";
import { useUser } from "providers/UserProvider";
import { Button, Box } from "./Detail.styled";
import { useImperativeModal } from "ImperativeModalProvider";
import { ImmediateSmsConfirmModal } from "modals/ImmediateSmsConfirmModal";
import { format } from "date-fns";
import { estimatedServiceMinutesToString } from "utils/timeUtil";

interface APIError {
  code: number;
  message: string;
}

const infoData = (data: any) =>
  data &&
  [
    [{ label: "접수종류", value: RegistrationType.getName(data.category) }],
    [{ label: "접수부서", value: data.department?.category.name }],
    [{ label: "일자", value: data.date }],
    [{ label: "방문시간", value: format(new Date(data.startAt), "HH시 mm분") }],
    [
      {
        label: "예상 소요시간",
        value: estimatedServiceMinutesToString(data),
      },
    ],
    [{ label: "내원경로", value: data.acquisitionChannel?.name }],
    [{ label: "의사", value: data.doctor?.name }],
    [{ label: "상담사", value: data.counselor?.name }],
    [{ label: "어시스트", value: data.assist?.name }],
    [{ label: "작성자", value: data.creator?.name }],
    [
      {
        label: "시/수술",
        value: data.treatmentItems.map((v: any) => (
          <>
            {v.category.name}-{v.name}
            <br />
          </>
        )),
      },
    ],
    [
      {
        label: "예약메모",
        value: (
          <Box
            dangerouslySetInnerHTML={{
              __html: data?.appointment?.isDeleted
                ? "-"
                : data?.appointment?.memo ?? "-",
            }}
            sx={{
              "*": {
                margin: 0,
              },
            }}
          />
        ),
      },
    ],
    [
      {
        label: "접수메모",
        value: (
          <Box
            dangerouslySetInnerHTML={{
              __html: data?.memo || "-",
            }}
            sx={{
              "*": {
                margin: 0,
              },
            }}
          />
        ),
      },
    ],
  ]
    .map((item) => {
      if (!data.appointment || data?.appointment?.isDeleted) {
        return item.filter((v) => v.label !== "예약메모");
      }
      return item;
    })
    .filter((item) => item.length > 0);

type DetailProps = {
  data: any;
  onClose: () => void;
  reload: () => void;
  edit: any;
  profileUrl?: string;
};

export function RegistrationDetail({
  data,
  onClose,
  reload,
  edit,
  profileUrl,
}: DetailProps) {
  const { registrationApi, smsApi } = useApi();
  const snackbar = useSnackbarContext();
  const imperativeModal = useImperativeModal();
  const { hasPermission } = useUser();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const openEditRegistrationModal = () => {
    setOpenEditModal(true);
  };
  const closeEditRegistrationModal = () => {
    setOpenEditModal(false);
  };

  const onDelete = async () => {
    try {
      await registrationApi.deleteRegistration(data?.id);
      snackbar.success("삭제되었습니다.");
      onClose();
      reload();
    } catch {
      snackbar.alert("삭제에 실패했습니다.");
    }
  };

  const onChangeRevertAppointment = async (params?: any) => {
    try {
      await registrationApi.revertRegistration(data.id, params);
      snackbar.success("예약으로 변경되었습니다.");
      onClose();
      reload();
    } catch (error) {
      const e = error as APIError;
      if (e.code === 500 && e.message === "Out of working hours") {
        return snackbar.alert("진료시간을 초과했습니다.");
      }
      return snackbar.alert(e.message);
    }
  };

  const checkImmediateSms = async (smsRules: any) => {
    const confirmed = await imperativeModal.open((close) => (
      <ImmediateSmsConfirmModal
        smsRules={smsRules}
        onConfirm={() => close(true)}
        onCancel={() => close(false)}
      />
    ));
    if (confirmed) {
      onChangeRevertAppointment({
        sendSms: smsRules.map((v: any) => v.id),
      });
    } else {
      onChangeRevertAppointment({
        sendSms: smsRules
          .filter((f: any) => f.smsScheduleType !== "immediate")
          .map((v: any) => v.id),
      });
    }
  };

  const onConfirmRevertAppointment = async () => {
    const res = await smsApi.getSmsNotifications();
    const smsRulesJson = await res.data;

    const smsRules = smsRulesJson.data.filter(
      (f: any) =>
        f.smsScheduleType === "immediate" &&
        f.departments.some((v: any) => v.id === data.department.id)
    );

    if (smsRules.length > 0) {
      checkImmediateSms(smsRules);
    } else {
      onChangeRevertAppointment();
    }
  };

  const onClickChangeToAppointment = async () => {
    if (
      data.status === AppointmentStatus.leave ||
      data.status.includes("DONE")
    ) {
      snackbar.alert("이미 완료된 건입니다. 상태를 확인하세요.");
      return;
    }
    const resp = await registrationApi.getRegistration(data.id);
    const detail = await resp.data;
    if (detail.data.charts.length) {
      snackbar.alert(
        "연결된 등록차트가 있어 취소가 불가능합니다. (연결해제 후 가능)"
      );
    } else {
      if (detail.data.appointment === null) {
        snackbar.alert("당일 접수는 예약으로 변경할 수 없습니다.");
      } else {
        if (
          detail.data.appointment.department.id !== data.department.id ||
          detail.data.appointment.startAt !== data.startAt
        ) {
          await imperativeModal.open((close) => (
            <ConfirmModal
              body={
                <>
                  접수 정보가 변경되어 새로운 예약으로 생성됩니다. <br />
                  변경하시겠습니까?
                </>
              }
              confirmText="확인"
              onClose={() => close()}
              onConfirm={() => {
                onConfirmRevertAppointment();
                close();
              }}
              open={true}
            />
          ));
        } else {
          onChangeRevertAppointment();
        }
      }
    }
  };

  if (!data) return <></>;
  return (
    <Box
      sx={{
        height: "100%",
        paddingBottom: "80px",
      }}
    >
      <List data={infoData(data)} />
      <Box className={`action-btn-wrapper registration`}>
        {edit && (
          <>
            {hasPermission("REGISTRATION_DELETE") && (
              <Button
                styled="fill"
                disabled={data.status === AppointmentStatus.registered}
                color="grey"
                onClick={() => setOpenAlert(true)}
                size="l"
              >
                삭제
              </Button>
            )}
            <Button
              styled="outline"
              color="deepGrey"
              size="l"
              onClick={() => onClickChangeToAppointment()}
            >
              예약전환
            </Button>
            <Button
              styled="fill"
              color="primary"
              size="l"
              onClick={() => {
                openEditRegistrationModal();
              }}
            >
              수정
            </Button>
          </>
        )}
        <CreateRegistrationModal
          open={openEditModal}
          onClose={closeEditRegistrationModal}
          onSaveCallback={reload}
          customer={data.customer}
          registration={data}
          profileUrl={profileUrl}
        />
        <ConfirmModal
          title="삭제"
          body="내역을 삭제하시겠습니까?"
          confirmText="삭제"
          onClose={() => setOpenAlert(false)}
          onConfirm={onDelete}
          open={openAlert}
        />
      </Box>
    </Box>
  );
}
