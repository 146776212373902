import { ReactNode } from "react";
import { Button, SxProps, Theme } from "@mui/material";

interface DialButtonProps {
  text: ReactNode;
  onClick: () => void;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  className?: string;
}

export const DialButton = ({
  text,
  onClick,
  sx,
  disabled,
  className,
}: DialButtonProps) => {
  return (
    <Button
      className={className}
      onClick={onClick}
      variant="outlined"
      disabled={disabled}
      sx={{
        borderColor: "#F1F1F1 !important",
        color: "black",
        fontSize: "4vw",
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1) !important",
        ...sx,
      }}
    >
      {text}
    </Button>
  );
};
