import { ApiResource } from "./apiResource";
import { ApiDataResponse } from "../apiClient";

export class DepartmentsApi extends ApiResource {
  async getDepartments() {
    return this.client.get<ApiDataResponse<any>>("/departments/categories", {
      params: {
        limit: 999,
        hasChild: true,
        visible: true,
      },
    });
  }
}
