import { DriveView, DriveViewProvider } from "components/DriveView/DriveView";
import { useApi } from "providers/ApiProvider";
import { useUser } from "providers/UserProvider";
import { useCallback, useEffect, useState } from "react";
import { NavPage } from "./Nav/NavPage";
import { useNavigate } from "react-router-dom";

export function PenchartPage() {
  const [directoryId, setDirectoryId] = useState(null);
  const { clinicsApi } = useApi();
  const nav = useNavigate();
  const { user } = useUser();

  const loadDrive = useCallback(async (clinicId: string) => {
    const res = await clinicsApi.getClinicDrive(clinicId);
    const payload = await res.data;
    setDirectoryId(payload.data.root.id);
  }, []);

  useEffect(() => {
    loadDrive(user.clinic.id);
  }, [loadDrive, user]);

  const onEnterDirectory = (directoryId: string) => {
    nav({
      pathname: `/directories/${directoryId}`,
      search: "penchartDrive=true",
    });
  };

  const onEnterFile = ({
    fileId,
    orderBy,
    searchKeyword,
  }: {
    fileId: string;
    orderBy: string;
    searchKeyword: string;
  }) => {
    nav(
      `/files/${fileId}/edit?orderBy=${orderBy}&searchKeyword=${searchKeyword}`
    );
  };

  return (
    <NavPage title="차트샘플함">
      {directoryId && (
        <DriveViewProvider directoryId={directoryId} penchartDrive={true}>
          <DriveView
            onEnterDirectory={onEnterDirectory}
            onEnterFile={onEnterFile}
          />
        </DriveViewProvider>
      )}
    </NavPage>
  );
}
