import { Button as _Button } from "~/components/Button";
import styled from "@emotion/styled";
import {
  TableRow as MuiTableRow,
  styled as muiStyled,
  TableCell as MuiTableCell,
} from "@mui/material";

export const Button = styled(_Button)(
  () => `
  height: 25px !important;
`
);

export const TableRow = muiStyled(MuiTableRow)(
  ({ theme }) => `
  
  th {
    color: ${theme.palette.grey["700"]};
    min-height: 42px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    padding: 12px 16px;
    box-sizing: border-box;
  }

  &:hover {
    td {
      background: ${theme.palette.bluegrey["100"]};
      cursor:pointer;
    }
  }
`
);

export const TableCell = muiStyled(MuiTableCell)(
  ({ theme }) => `
  color: ${theme.palette.grey["700"]};
  min-height: 42px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  padding: 12px 16px;
  box-sizing: border-box;
  border-color: ${theme.palette.bluegrey["100"]};
  word-break: break-all;
`
);
