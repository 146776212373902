import {
  styled,
  Box as MuiBox,
  Stack as MuiStack,
  Typography as MuiTypography,
  typographyClasses,
} from "@mui/material";

export const Box = styled(MuiBox)(
  ({ theme }) => `
  &.image-editor-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .coating {
      width: 100%;
      height: 100%;
      background: red;
      opacity:0;
      position: absolute;
      z-index: 10000;
    }
  }

  &.title-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-grow: 1;
  }

  &.customer-info {
    font-size: 13px;
    font-weight: 400;
    line-height: 18.82px;
    text-align: center;
    color: ${theme.palette.grey["700"]};
  }

  &.file-count {
    font-size: 15px;
  }
`
);

export const Stack = styled(MuiStack)(() => ``);

export const Typography = styled(MuiTypography)(
  ({ theme }) => `
  &.${typographyClasses.h6} {
    max-width: 510px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    white-space: nowrap;
    color: ${theme.palette.grey["700"]};
    font-size: 15px;
    font-weight: 700;
    line-height: 21.72px;
  }
`
);
