import { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import Icon from "~/components/Icon";

const Thumbnail = ({
  thumbnailUrl,
  broken,
}: {
  thumbnailUrl?: string | null;
  broken?: boolean;
}) => {
  const [isImageError, setIsImageError] = useState(false);

  useEffect(() => {
    if (!thumbnailUrl) return;
    const img = new Image();
    img.src = thumbnailUrl;
    img.onload = () => setIsImageError(false);
    img.onerror = () => setIsImageError(true);
  }, [thumbnailUrl]);

  if (broken || isImageError)
    return (
      <Box className="thumbnail-icon-wrapper">
        <Icon variant="errorFile" />
      </Box>
    );
  if (!thumbnailUrl)
    return (
      <Box className="thumbnail-icon-wrapper">
        <CircularProgress />
      </Box>
    );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background: `url(${thumbnailUrl})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#f1f1f1",
      }}
    />
  );
};

export default Thumbnail;
