import {
  Tabs,
  Tab,
  styled,
  Box as MuiBox,
  IconButton as MuiIconButton,
} from "@mui/material";

import { css } from "@emotion/react";

export const AntTabs = styled(Tabs)(
  ({ theme }) => css`
    border-bottom: 1px solid #e8e8e8;
    position: sticky;
    top: 0;
    z-index: 2;

    & .MuiTabs-indicator {
      display: flex;
      justify-content: center;
      background-color: transparent;
      height: 3px;
    }

    & .MuiTabs-indicatorSpan {
      background-color: ${theme.palette.primary.main};
      max-width: 40px;
      width: 100%;
    }
  `
);

export const AntTab = styled((props: any) => <Tab disableRipple {...props} />)(
  () => css`
    color: #adaebc;
    font-size: 14px;
    font-weight: normal;
    min-width: auto;
    padding: 0 20px;

    &.Mui-selected {
      font-weight: bold;
    }
  `
);

export const Box = styled(MuiBox)(
  ({ theme }) => `
  &.category-tab-wrapper {
    
    background: #ffffff;
    display: flex;
    position: relative;
    align-items: center;

    &::before {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: ${theme.palette.grey["200"]};
    }

    .tab-wrapper {

    }
  
  }
`
);

export const IconButton = styled(MuiIconButton)(
  ({ theme }) => `
  width: 30px;
  height: 30px;
  border: 1px solid #DEE2EC;
  border-radius: 3px;
  margin: 0 12px 0 20px;

  &.active {
    background: #2C62F61A;
    border-color: ${theme.palette.primary["main"]};
  }
`
);
