import { useContext, useEffect, useState } from "react";
import { Table, TableBody, TableHead } from "@mui/material";
import { AppointmentContext } from "pages/AppointmentPage/AppointmentPage";
import { CreateAppointmentModal } from "modals/CreateAppointmentModal";
import { CustomerInfoModal } from "modals/CustomerInfoModal";
import { EmptyView } from "components/EmptyView";
import { useNavigate } from "react-router-dom";
import { useApi } from "providers/ApiProvider";
import { phoneNumberFormatHyphen } from "utils/phoneNumber";
import { Button, TableRow, TableCell } from "./List.styled";
import { useClinic } from "providers/ClinicProvider";
import { maskName, maskBirthday, maskPhoneNumber } from "utils/maskString";
import { ageText } from "utils/index";
import { format } from "date-fns";
import { useUser } from "providers/UserProvider";

type CustomerRowProps = {
  customer: any;
  onClickRow: (value: any) => void;
};

const CustomerRow = ({ customer, onClickRow }: CustomerRowProps) => {
  const nav = useNavigate();
  const { customersApi } = useApi();
  const { isMasking } = useClinic();
  const { hasPermission } = useUser();

  const onClickPenchart = async (e: any) => {
    e.stopPropagation();
    const res = await customersApi.getCustomerDrive(customer.id);
    const payload = await res.data;
    const directoryId = payload.data.root.id;
    nav(
      `/directories/${directoryId}?customerId=${customer.id}&name=${
        customer.name
      }(${customer.sex === "male" ? "M" : "F"}/${ageText(customer)}/${
        customer.birthday ? format(new Date(customer.birthday), "yyMMdd") : "-"
      }) 님의 펜차트&customerId=${customer.id}`
    );
  };

  const onClick = () => {
    onClickRow(customer);
  };

  return (
    <TableRow key={customer.id} onClick={onClick}>
      <TableCell align="center">
        {isMasking ? maskName(customer.name) : customer.name}
      </TableCell>
      <TableCell align="center">
        {customer.sex === "female" ? "여성" : "남성"}
      </TableCell>
      <TableCell align="center">
        {isMasking ? maskBirthday(customer.birthday) : customer.birthday}
      </TableCell>
      <TableCell align="center">
        {isMasking
          ? maskPhoneNumber(customer.phoneNumber)
          : phoneNumberFormatHyphen(customer.phoneNumber)}
      </TableCell>
      {hasPermission("PENCHART_CUSTOMER") && (
        <TableCell align="center">
          <Button
            styled="outline"
            color="primary"
            size="xxs"
            onClick={onClickPenchart}
            className="penchart-btn"
          >
            차트보기
          </Button>
        </TableCell>
      )}
    </TableRow>
  );
};

type ListProps = {
  data: any;
  onClose: () => void;
  onCustomerDelete?: () => void;
  type: "appointment" | "customer";
};

export function List({ data, onClose, onCustomerDelete, type }: ListProps) {
  const { hasPermission } = useUser();
  const [target, setTarget] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { reload }: any = useContext(AppointmentContext);

  useEffect(() => {
    return () => {
      setTarget(null);
      setOpenModal(false);
    };
  }, []);

  useEffect(() => {
    if (!openModal && target !== null) {
      setTarget(null);
    }
  }, [openModal]);

  const onClickRow = (customer: any) => {
    setOpenModal(true);
    setTarget(customer);
  };

  return (
    <>
      {Boolean(data?.length) && (
        <Table
          stickyHeader
          sx={{
            background: "#fff",
            borderBottom: "1px solid #efeff4",
            "& th": {
              padding: "13px 0",
              color: "#282828",
              fontWeight: 700,
              borderBottom: "1px solid #F9FBFF",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" width="20%">
                고객명
              </TableCell>
              <TableCell align="center" width="10%">
                성별
              </TableCell>
              <TableCell align="center" width="15%">
                생년월일
              </TableCell>
              <TableCell align="center" width="15%">
                전화번호
              </TableCell>
              {hasPermission("PENCHART_CUSTOMER") && (
                <TableCell align="center" width="10%">
                  펜차트
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: any) => (
              <CustomerRow
                key={row.id}
                customer={row}
                onClickRow={onClickRow}
              />
            ))}
          </TableBody>
        </Table>
      )}
      {!data?.length && <EmptyView text="찾으시는 검색 결과가 없습니다." />}
      {openModal &&
        target !== null &&
        (type === "appointment" ? (
          <CreateAppointmentModal
            onClose={() => {
              setOpenModal(false);
            }}
            onSaveCallback={onClose}
            customer={target}
            open={openModal}
          />
        ) : type === "customer" ? (
          <CustomerInfoModal
            open={openModal}
            reload={reload}
            onCustomerDelete={() => {
              onCustomerDelete && onCustomerDelete();
              setOpenModal(false);
            }}
            onClose={() => {
              setOpenModal(false);
            }}
            customerData={target}
          />
        ) : (
          ""
        ))}
    </>
  );
}
