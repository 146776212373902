import { useCallback, useMemo } from "react";
import { useApi } from "providers/ApiProvider";
import { useSnackbarContext } from "~/SnackbarProvider_v2";
import { getMaxDimesionSize } from "utils/file";
import {
  bytesToSize,
  getImageDimensions,
  getFileExtensionFromBlob,
} from "~/utils/fileUtil";

const { width, height } = getMaxDimesionSize();

export const useImageEditorBoilerplate = () => {
  const snackbar = useSnackbarContext();
  const { filesApi, imageApi } = useApi();

  const onLoadBoilerplates = useCallback(async () => {
    const res = await filesApi.getPenchartBoilerplates();
    const payload = await res.data;
    return payload.data;
  }, []);

  const onDeleteBoilerplate = useCallback(async (id: string) => {
    await filesApi.deletePenchartBoilerplate(id);
    snackbar.success("상용구를 삭제했습니다.");
  }, []);

  const onSaveBoilerplate = useCallback(async (contents: string) => {
    await filesApi.createPenchartBoilerplate(contents);
    snackbar.success("문구를 상용구로 저장했습니다.");
  }, []);

  const handleLoadBoilerplateBookmarkImage = useCallback(async () => {
    const res = await filesApi.getBookmarksImageList();
    const payload = await res.data;
    return payload.data.map(
      (item: {
        id: number;
        image: { originalUrl: string; thumbnailUrl: string }[];
      }) => {
        return { id: item.id, contents: item.image };
      }
    );
  }, []);

  const handleDeleteBoilerplateBookmarkImage = useCallback(
    async (id: string) => {
      await filesApi.deleteBookmarkImage(id);
      snackbar.success("자주쓰는 이미지를 삭제했습니다.");
    },
    []
  );

  const handleSaveBoilerplateBookmarkImage = useCallback(async (file: Blob) => {
    const dimensions: any = await getImageDimensions(file as File);
    const { size } = await bytesToSize(file.size, "MB");
    if (dimensions?.width > width || dimensions?.height > height || size > 50) {
      return snackbar.alert(
        <>
          이미지 파일 크기가 50MB를 초과하거나,
          <br />
          가로 또는 세로길이가 4096px을 초과하는 경우에는 불러올 수 없습니다.
        </>
      );
    }
    const getPresignedData = async (fileExtension: string) => {
      const response = await filesApi.createBookmarkImage(fileExtension);
      return await response.data;
    };
    const uploadFile = async (file: Blob) => {
      const fileExtensionType = getFileExtensionFromBlob(file);
      const {
        data: { presignedUrl, token },
      } = await getPresignedData(fileExtensionType || "png");
      await imageApi.uploadBookmarkImageFile(presignedUrl, file);
      await filesApi.uploadBookmarkImage(token);
    };
    try {
      await uploadFile(file);
      snackbar.success("자주쓰는 이미지를 저장했습니다.");
    } catch (error) {
      snackbar.alert("에러가 발생해 이미지가 저장되지 않았습니다.");
    }
  }, []);

  const boilerplate = useMemo(
    () => [
      {
        onLoadBoilerplate: onLoadBoilerplates,
        onDeleteBoilerplate: onDeleteBoilerplate,
        onSaveBoilerplate: onSaveBoilerplate,
      },
      {
        onLoadBoilerplate: handleLoadBoilerplateBookmarkImage,
        onDeleteBoilerplate: handleDeleteBoilerplateBookmarkImage,
        onSaveBoilerplate: handleSaveBoilerplateBookmarkImage,
      },
    ],
    []
  );

  return { boilerplate };
};
