import { useEffect, useCallback, useState } from "react";
import { Box, Avatar, IconButton } from "./ModalHeader.styled";
import { CustomerType } from "type/customer";
import { useApi } from "providers/ApiProvider";
import { getCustomerName } from "utils/getCustomerName";
import Icon from "components/Icon";

type ModalHeaderProps = {
  onClose: () => void;
  customer: CustomerType;
};

const ModalHeader = (props: ModalHeaderProps) => {
  const { onClose, customer } = props;
  const [profileUrl, setProfileImage] = useState("");
  const { filesApi } = useApi();

  useEffect(() => {
    customer?.profileImageId && loadProfileImage(customer.profileImageId);
  }, [customer]);

  const loadProfileImage = useCallback(
    async (profileImageId: number) => {
      const res = await filesApi.getImage(profileImageId);
      const payload = await res.data;
      if (payload?.data && payload.data.thumbnailUrl) {
        setProfileImage(payload.data.thumbnailUrl);
      }
    },
    [customer]
  );

  return (
    <Box className="header">
      <IconButton
        onClick={() => {
          onClose();
        }}
      >
        <Icon variant="back_arrow" />
      </IconButton>
      <Box className="customer-name">
        <Avatar src={profileUrl} className={`${customer.sex}`} />
        <b>{getCustomerName(customer)}</b>
      </Box>
    </Box>
  );
};

export default ModalHeader;
