import { ApiResource } from "./apiResource";
import {
  ApiEmptyResponse,
  ApiDataResponse,
  PaginationResponse,
} from "../apiClient";

export interface FileData {
  createdAt: string;
  creator: {
    id: number;
    name: string;
  };
  favoritedAt: string;
  id: number;
  image: {
    id: number;
    originalUrl?: string;
    thumbnailUrl?: string;
    thumbnailStatus?: "CREATING" | "FAILED" | "SUCCESS";
  };
  memo: string;
  modifier: {
    id: number;
    name: string;
  };
  name: string;
  parent: {
    id: number;
    name: string;
  };
  updatedAt: string;
}

export class FilesApi extends ApiResource {
  async getDirectories(params: { directoryId: string; orderBy: string }) {
    const { directoryId, orderBy } = params;
    return this.client.get<ApiDataResponse<any>>(`/directories`, {
      params: {
        parentId: directoryId,
        orderBy,
      },
    });
  }

  async getDirectory(directoryId: string) {
    return this.client.get<ApiDataResponse<any>>(`/directories/${directoryId}`);
  }

  async deleteDirectory(id: string) {
    return this.client.delete<ApiEmptyResponse>(`/directories/${id}`);
  }

  async copyDirectory(srcId: number, parentId: number) {
    return this.client.post<ApiDataResponse<any>>(`/directories/copy`, {
      srcId,
      destId: parentId,
    });
  }

  async updateDirectory(id: string, payload: any) {
    return this.client.put<ApiDataResponse<any>>(`/directories/${id}`, payload);
  }

  async getFiles(params: {
    parentId?: number;
    directoryId?: string;
    page?: number;
    limit?: number;
    orderBy?: string;
    name?: string;
  }) {
    return this.client.get<PaginationResponse<FileData[]>>(`/files`, {
      params,
    });
  }

  async getFile(fileId: number | string) {
    return this.client.get<ApiDataResponse<FileData>>(`/files/${fileId}`);
  }

  async updateFile(id: number | string, payload: any) {
    return this.client.put<ApiDataResponse<any>>(`/files/${id}`, payload);
  }

  async deleteFile(id: string) {
    return this.client.delete<ApiEmptyResponse>(`/files/${id}`);
  }

  async createFile(payload: any) {
    return this.client.post<ApiDataResponse<any>>(`/files`, payload);
  }

  async copyFile(srcId: number, parentId: number) {
    return this.client.post<ApiDataResponse<any>>(`/files/copy`, {
      srcId,
      destId: parentId,
    });
  }

  async getImage(imageId: number | string) {
    return this.client.get<ApiDataResponse<any>>(`/images/${imageId}`);
  }

  async uploadImages(token: any) {
    return this.client.post<ApiDataResponse<any>>(`/images`, { token });
  }

  async createImage(extension: string) {
    const payload: any = {};
    if (extension !== "") {
      payload.extension = extension;
    }
    return this.client.post<ApiDataResponse<any>>(`/images/uploads`, payload);
  }

  async getBookmarksImageList() {
    return this.client.get<ApiDataResponse<any>>(`/files/boilerplate/images`);
  }

  async uploadBookmarkImage(token: any) {
    return this.client.post<ApiDataResponse<any>>(`/files/boilerplate/images`, {
      token,
    });
  }

  async createBookmarkImage(extension: string) {
    const payload: any = {};
    if (extension !== "") {
      payload.extension = extension;
    }
    return this.client.post<ApiDataResponse<any>>(
      `/files/boilerplate/images/upload`,
      payload
    );
  }

  async deleteBookmarkImage(id: string) {
    return this.client.delete<ApiEmptyResponse>(
      `/files/boilerplate/images/${id}`
    );
  }

  async getMemoBoilerPlates(category: string) {
    return this.client.get<ApiDataResponse<any>>(`/boilerplate_memos`, {
      params: {
        limit: 5,
        orderBy: "order asc",
        category: category,
      },
    });
  }

  async getPenchartBoilerplates() {
    return this.client.get<ApiDataResponse<any>>(`/files/boilerplatemessages`, {
      params: {
        limit: 300,
      },
    });
  }

  async createPenchartBoilerplate(message: string) {
    return this.client.post<ApiDataResponse<any>>(
      `/files/boilerplatemessages`,
      {
        contents: message,
      }
    );
  }

  async deletePenchartBoilerplate(id: string) {
    return this.client.delete<ApiEmptyResponse>(
      `/files/boilerplatemessages/${id}`
    );
  }
}
