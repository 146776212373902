import { useCallback, useMemo, useState, useEffect } from "react";
import { useApi } from "providers/ApiProvider";
import { getOptions } from "~/hooks/useFormOptions";
import { unusedCode } from "~/utils/unusedCodeUtil";

export const useCounselors = (counselor: any) => {
  const [counselors, setCounselors] = useState([]);
  const { userApi } = useApi();
  const counselorCallApi = useCallback(async () => {
    const params = { duty: "상담사", userStatus: "active", limit: 300 };
    const resp = await userApi.getUserDuty(params);
    if (!resp) return;
    const result = await resp.data;
    setCounselors(result.data.map((v: any) => ({ id: v.id, label: v.name })));
  }, []);

  useEffect(() => {
    counselorCallApi();
  }, [counselorCallApi]);

  const counselorOptions = useMemo(() => {
    const data = counselors ?? [];
    if (!counselor || !counselor?.id) return data;

    const extraOption = { ...counselor };
    if (
      (counselor.status === "inactive" || counselor.visible === false) &&
      !data.find((f: any) => f.id === counselor.id)
    ) {
      extraOption.visible = false;
    } else if (
      (counselor.status === "deleted" ||
        counselor?.isDeleted === true ||
        counselor?.deletedAt !== null) &&
      !data.find((f: any) => f.id === counselor.id)
    ) {
      extraOption.isDeleted = true;
    }

    if (data.find((f: any) => f.id === counselor.id)) {
      return data.map((v: any) => ({
        ...v,
        label: v.name ?? v.label,
      }));
    } else {
      return getOptions(data, extraOption).map((v: any) => ({
        ...v,
        label: v.name ?? v.label,
      }));
    }
  }, [counselors, counselor]);

  return { counselors, counselorOptions };
};

export const useDoctors = (doctor: any) => {
  const [doctors, setDoctors] = useState([]);
  const { userApi } = useApi();
  const doctorCallApi = useCallback(async () => {
    const params = { duty: "의사", userStatus: "active", limit: 300 };
    const resp = await userApi.getUserDuty(params);
    if (!resp) return;
    const result = await resp.data;
    setDoctors(result.data.map((v: any) => ({ id: v.id, label: v.name })));
  }, []);

  useEffect(() => {
    doctorCallApi();
  }, [doctorCallApi]);

  const doctorOptions = useMemo(() => {
    const data = doctors ?? [];
    if (!doctor || !doctor?.id) return data;

    const extraOption = { ...doctor };
    if (
      (doctor.status === "inactive" || doctor.visible === false) &&
      !data.find((f: any) => f.id === doctor.id)
    ) {
      extraOption.visible = false;
    } else if (
      (doctor.status === "deleted" ||
        doctor?.isDeleted === true ||
        doctor?.deletedAt !== null) &&
      !data.find((f: any) => f.id === doctor.id)
    ) {
      extraOption.isDeleted = true;
    }

    if (data.find((f: any) => f.id === doctor.id)) {
      return data.map((v: any) => ({
        ...v,
        label: v.name ?? v.label,
      }));
    } else {
      return getOptions(data, extraOption).map((v) => ({
        ...v,
        label: v.name ?? v.label,
      }));
    }
  }, [doctors, doctor]);

  return { doctors, doctorOptions };
};

export const useFavorites = (favorites: any, setFavorites: any) => {
  const [favoriteOptions, setFavoriteOptions]: any = useState([]);
  const { treatmentsApi } = useApi();
  const treatmentItemCategoriesCallApi = useCallback(async () => {
    const resp = await treatmentsApi.getTreatmentItems();
    if (!resp) return;
    const result = await resp.data;
    const options = [...result.data];
    if (favorites) {
      const unusedFavorites = [
        ...favorites
          .filter((f: any) => !f.visible && !f.isDeleted)
          .map((v: any) => ({
            ...v,
            name: unusedCode.unusedCodeValue + v.name,
          })),
        ...favorites
          .filter((f: any) => f.isDeleted)
          .map((v: any) => ({
            ...v,
            name: unusedCode.deletedCodeValue + v.name,
          })),
      ];
      options.unshift(...unusedFavorites);
    }

    setFavoriteOptions(
      options.map((v: any) => {
        return { label: v.name, id: v.id };
      })
    );
    if (favorites && favorites.length > 0) {
      setFavorites([
        ...favorites.map((v: any) => {
          return { label: v.name, id: v.id };
        }),
      ]);
    }
  }, [treatmentsApi]);

  useEffect(() => {
    treatmentItemCategoriesCallApi();
  }, [treatmentItemCategoriesCallApi]);

  return { favoriteOptions };
};

export const useLevels = (level: any) => {
  const { customersApi } = useApi();
  const [levels, setLevels] = useState([]);

  const loadLevels = useCallback(async () => {
    const res = await customersApi.getLevels();
    const payload = await res.data;
    setLevels(payload.data.map((v: any) => ({ id: v.id, label: v.name })));
  }, [customersApi]);

  useEffect(() => {
    loadLevels();
  }, [loadLevels]);

  const levelOptions = useMemo(() => {
    if (!levels) return [];
    const data = levels ?? [];
    if (!level || !level?.id) return data;

    const extraOption = { ...level };
    if (
      (level.status === "inactive" || level.visible === false) &&
      !data.find((f: any) => f.id === level.id)
    ) {
      extraOption.visible = false;
    } else if (
      (level.status === "deleted" ||
        level?.isDeleted === true ||
        level?.deletedAt !== null) &&
      !data.find((f: any) => f.id === level.id)
    ) {
      extraOption.isDeleted = true;
    }

    if (data.find((f: any) => f.id === level.id)) {
      return data.map((v: any) => ({
        ...v,
        label: v.name ?? v.label,
      }));
    } else {
      return getOptions(data, extraOption).map((v) => ({
        ...v,
        label: v.name ?? v.label,
      }));
    }
  }, [levels, level]);

  return { levelOptions };
};

export const useAcquisition = (
  acquisitionChannels: any,
  setAcquisitionChannel: any
) => {
  const [acquisitionChannelOptions, setAcquisitionChannelOptions]: any =
    useState([]);
  const { customersApi } = useApi();
  const acquisitionChannelsCallApi = useCallback(async () => {
    const resp = await customersApi.getAcquisitionChannels();
    if (!resp) return;
    const result = await resp.data;
    const options = [...result.data];
    if (acquisitionChannels) {
      const unusedAcquisitionChannel = [
        ...acquisitionChannels
          .filter((f: any) => !f.visible && !f.isDeleted)
          .map((v: any) => ({
            ...v,
            name: unusedCode.unusedCodeValue + v.name,
          })),
        ...acquisitionChannels
          .filter((f: any) => f.isDeleted)
          .map((v: any) => ({
            ...v,
            name: unusedCode.deletedCodeValue + v.name,
          })),
      ];
      options.unshift(...unusedAcquisitionChannel);
    }
    setAcquisitionChannelOptions(
      options.map((v) => {
        return { label: v.name, id: v.id };
      })
    );
    if (acquisitionChannels && acquisitionChannels.length > 0) {
      setAcquisitionChannel([
        ...acquisitionChannels.map((v: any) => {
          return { label: v.name, id: v.id };
        }),
      ]);
    }
  }, [customersApi]);

  useEffect(() => {
    acquisitionChannelsCallApi();
  }, [acquisitionChannelsCallApi]);

  return { acquisitionChannelOptions };
};

export const useComplaint = (complaint: any) => {
  const { customersApi } = useApi();
  const [complaintList, setComplaintList] = useState([]);

  const loadComplaint = useCallback(async () => {
    const res = await customersApi.getComplaints();
    const payload = await res.data;
    setComplaintList(
      payload.data.map((v: any) => ({ id: v.id, label: v.content }))
    );
  }, [customersApi]);

  useEffect(() => {
    loadComplaint();
  }, [loadComplaint]);

  const complaintOptions = useMemo(() => {
    if (!complaintList) return [];

    const data = complaintList ?? [];
    if (!complaint || !complaint?.id) return data;

    const value = Object.assign(complaint, {
      name: complaint.content,
    });

    const extraOption = { ...value };
    if (
      (value.status === "inactive" || value.visible === false) &&
      !data.find((f: any) => f.id === value.id)
    ) {
      extraOption.visible = false;
    } else if (
      (value.status === "deleted" ||
        value?.isDeleted === true ||
        value?.deletedAt !== null) &&
      !data.find((f: any) => f.id === value.id)
    ) {
      extraOption.isDeleted = true;
    }

    if (data.find((f: any) => f.id === complaint.id)) {
      return data.map((v: any) => ({
        ...v,
        label: v.name ?? v.label,
      }));
    } else {
      return getOptions(data, extraOption).map((v) => ({
        ...v,
        label: v.name ?? v.label,
      }));
    }
  }, [complaintList, complaint]);

  return { complaintOptions };
};

export const useRegion = (region: any) => {
  const { customersApi } = useApi();
  const [regionList, setRegionList] = useState([]);

  const loadRegion = useCallback(async () => {
    const res = await customersApi.getRegions();
    const payload = await res.data;
    setRegionList(payload.data.map((v: any) => ({ id: v.id, label: v.name })));
  }, [customersApi]);

  useEffect(() => {
    loadRegion();
  }, [loadRegion]);

  const regionOptions = useMemo(() => {
    if (!regionList) return [];

    const data = regionList ?? [];
    if (!region || !region?.id) return data;

    const extraOption = { ...region };
    if (
      (region.status === "inactive" || region.visible === false) &&
      !data.find((f: any) => f.id === region.id)
    ) {
      extraOption.visible = false;
    } else if (
      (region.status === "deleted" ||
        region?.isDeleted === true ||
        region?.deletedAt !== null) &&
      !data.find((f: any) => f.id === region.id)
    ) {
      extraOption.isDeleted = true;
    }

    if (data.find((f: any) => f.id === region.id)) {
      return data.map((v: any) => ({
        ...v,
        label: v.name ?? v.label,
      }));
    } else {
      return getOptions(data, extraOption).map((v) => ({
        ...v,
        label: v.name ?? v.label,
      }));
    }
  }, [regionList, region]);

  return { regionOptions };
};

export const useJob = (job: any) => {
  const { customersApi } = useApi();
  const [jobList, setJobList] = useState([]);

  const loadRegion = useCallback(async () => {
    const res = await customersApi.getJobs();
    const payload = await res.data;
    setJobList(payload.data.map((v: any) => ({ id: v.id, label: v.name })));
  }, [customersApi]);

  useEffect(() => {
    loadRegion();
  }, [loadRegion]);

  const jobOptions = useMemo(() => {
    if (!jobList) return [];
    const data = jobList ?? [];
    if (!job || !job?.id) return data;

    const extraOption = { ...job };
    if (
      (job.status === "inactive" || job.visible === false) &&
      !data.find((f: any) => f.id === job.id)
    ) {
      extraOption.visible = false;
    } else if (
      (job.status === "deleted" ||
        job?.isDeleted === true ||
        job?.deletedAt !== null) &&
      !data.find((f: any) => f.id === job.id)
    ) {
      extraOption.isDeleted = true;
    }

    if (data.find((f: any) => f.id === job.id)) {
      return data.map((v: any) => ({
        ...v,
        label: v.name ?? v.label,
      }));
    } else {
      return getOptions(data, extraOption).map((v) => ({
        ...v,
        label: v.name ?? v.label,
      }));
    }
  }, [jobList, job]);

  return { jobOptions };
};
